export const USERS_GET_LIST_REQUEST = 'USERS_GET_LIST_REQUEST';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_FAILURE = 'USERS_GET_LIST_FAILURE';

export const USERS_GET_ONE_REQUEST = 'USERS_GET_ONE_REQUEST';
export const USERS_GET_ONE_SUCCESS = 'USERS_GET_ONE_SUCCESS';
export const USERS_GET_ONE_FAILURE = 'USERS_GET_ONE_FAILURE';

export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE';

export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

export const REMOVE_USER_FROM_STORE = 'REMOVE_USER_FROM_STORE';
