import {
  CLEAR_SELECTED_PEOPLE,
  PEOPLE_GET_SUCCESS,
  PEOPLE_GETALL_SUCCESS,
  PEOPLE_REMOVE_SUCCESS,
  PEOPLE_UPDATE_SUCCESS,
  REMOVE_PEOPLE_FROM_STORE,
  SELECT_PEOPLE_FOR_AGENCY,
  SELECT_PEOPLE_FOR_DIVISION,
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import { Person } from '../../shared/interfaces/people.interface';
import PeopleService from '../../services/people.service';
import { handleActionResult } from '../action-helpers';

const peopleService = new PeopleService();

const getPeople = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(people) {
    return {
      type: PEOPLE_GETALL_SUCCESS,
      people,
    };
  }

  const result = await peopleService.getPeople();
  return handleActionResult(result, () => dispatch(success(result)));
};

const removePeople = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success() {
    return {
      type: PEOPLE_REMOVE_SUCCESS,
    };
  }

  const result = await peopleService.removePeople(id);
  return handleActionResult(result, () => {
    dispatch(success());
    dispatch(getPeople());
  });
};

const createPeople = (formData: Person): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(people) {
    return {
      type: PEOPLE_REMOVE_SUCCESS,
      people,
    };
  }

  const result = await peopleService.createPeople(formData);
  return handleActionResult(result, () => {
    dispatch(success(result));
    dispatch(getPeople());
  });
};

const updatePeople = (id: number, formData: Person): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch,
) => {
  function success(people) {
    return {
      type: PEOPLE_UPDATE_SUCCESS,
      people,
    };
  }

  const result = await peopleService.updatePeople(id, formData);
  return handleActionResult(result, () => {
    dispatch(success(result));
    dispatch(getPerson(id));
  });
};

const getPerson = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(people) {
    return {
      type: PEOPLE_GET_SUCCESS,
      people,
    };
  }

  const result = await peopleService.getPerson(id);
  return handleActionResult(result, () => dispatch(success(result)));
};

const removePeopleFromStore = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: REMOVE_PEOPLE_FROM_STORE });
};

const setSelectedPeopleForAgency = (data): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: SELECT_PEOPLE_FOR_AGENCY,
    data,
  });
};

const setSelectedPeopleForDivision = (data): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: SELECT_PEOPLE_FOR_DIVISION,
    data,
  });
};

const clearSelectedPeople = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_PEOPLE,
  });
};

export {
  getPeople,
  removePeople,
  createPeople,
  updatePeople,
  getPerson,
  removePeopleFromStore,
  setSelectedPeopleForAgency,
  setSelectedPeopleForDivision,
  clearSelectedPeople,
};
