import React, { FC } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { FormTextareaFieldProps } from './textarea-field.interface';
import { usePermissions } from '../../../contexts/permissions.context';

export const TextareaField: FC<FormTextareaFieldProps> = ({
  name,
  placeholder,
  className,
  validate,
  forDivision = false,
  wrapperClass,
  disabled,
  currentYearFrozen = false,
}: FormTextareaFieldProps) => {
  const matrix = usePermissions();
  const currentEdit = currentYearFrozen && typeof matrix.permissions.freezeEdit === 'boolean'
    ? matrix.permissions.freezeEdit : matrix.permissions.edit
  const isReadOnly = currentEdit === false;
  const editable = isReadOnly ? 'form__field_readonly' : '';

  return (
    <div className={classNames("field-wrapper", "textarea-shadowInset-padding5-marginLeft5", wrapperClass)} >
      <Field
        readOnly={isReadOnly}
        name={name}
        className={classNames('form__field form__field_textarea', editable, className)}
        component={'textarea'}
        placeholder={placeholder}
        validate={validate}
        disabled={disabled}
      />
    </div >
  );
};
