import React, { FC } from "react";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { EntityPanel } from "../../panels/entity-panel/entity-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { PeopleListProps } from "./people-list.interface";
import { vocabulary } from "../../../vocabulary/german";
import { usePermissions } from '../../../contexts/permissions.context';

export const PeopleList: FC<PeopleListProps> = ({
  people,
  panelClick,
  createClick,
  isSelectable,
}: PeopleListProps) => {
  const matrix = usePermissions();

  const createPersonBtn = (): JSX.Element | undefined => {
    if (matrix.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewPeople}
          onClick={createClick}
        />
      )
    }
  }


  return people && people.rows && people.rows.length > 0 ? (
    <div className={"entities-list"}>
      {people.rows.map((people, index) => (
        <EntityPanel
          isSelectable={isSelectable}
          onClick={() => panelClick(people)}
          key={index}
          title={people.name}
          id={people.id}
        />
      ))}
      {createPersonBtn()}
    </div>
  ) : (
    <>
      <NotFoundMessage message={` Nicht gefunden: Personen konnten nicht gefunden werden.`} />
      {createPersonBtn()}
    </>
  );
};
