import {
  POLICIES_GETALL_REQUEST,
  POLICIES_GETALL_FAILURE,
  POLICIES_GETALL_SUCCESS,
  POLICY_GET_FAILURE,
  POLICY_GET_REQUEST,
  POLICY_GET_SUCCESS,
  POLICY_UPDATE_FAILURE,
  POLICY_UPDATE_REQUEST,
  POLICY_UPDATE_SUCCESS,
  REMOVE_POLICY_FROM_STORE
} from './types';


const defaultState = {
  isGetPending: false,
  isUpdatePending: false,

  isLoadingPolicies: false
}

export function policies(state = defaultState, action) {
  switch (action.type) {
    case POLICIES_GETALL_REQUEST:
      return {
        isLoadingPolicies: true
      }
    case POLICIES_GETALL_SUCCESS: {
      return {
        ...action.policies,
        isLoadingPolicies: false
      };
    }
    case POLICIES_GETALL_FAILURE: {
      return {
        error: action.error,
        isLoadingPolicies: false
      };
    }
    case POLICY_GET_REQUEST:
      return {
        ...state,
        isGetPending: true
      };
    case POLICY_GET_SUCCESS: {
      return {
        ...state,
        current: action.policy,
        isGetPending: false
      };
    }
    case POLICY_GET_FAILURE: {
      return {
        error: action.error,
        isGetPending: false
      };
    }
    case REMOVE_POLICY_FROM_STORE: {
      return {
        ...state,
        current: {},
      };
    }
    case POLICY_UPDATE_REQUEST: {
      return {
        ...state,
        isUpdatePending: true
      }
    }
    case POLICY_UPDATE_SUCCESS:
    case POLICY_UPDATE_FAILURE: {
      return {
        ...state,
        isUpdatePending: false
      }
    }
    default:
      return state;
  }
}
