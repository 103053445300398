import { ASSIGN_DIVISION_MODAL, ASSIGN_PEOPLE_MODAL, MODAL_AGENCY } from '../../../shared/constants/modals';
import React, { PureComponent } from 'react';
import { Modal } from '../../template/modals/modal';
import { modalService } from '../../../services/modals.service';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../store/modals/actions';
import { AgencyModalState } from './agency-modal.interface';
import AgencyForm from '../../forms/agency-form/agency-form';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from '../../template/modals/confirmation-modal';
import { NotFoundMessage } from '../../template/not-found-message/not-found-message';
import { clearSelectedDivisions, updateDivision, getDivisions } from '../../../store/divisions/actions';
import { clearSelectedPeople } from '../../../store/people/actions';
import {
  createAgency,
  removeAgency,
  removeAgencyFromStore,
  setCurrentAgency,
  updateAgency,
  getAgencies,
  getAgenciesWithDivisions,
} from '../../../store/agencies/actions';
import { Spinner } from '../../template/spinner/spinner';
import { AppState } from '../../../store';
import { setFormDirty } from '../../../store/formIsDirty';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_AGENCY);
  const { isDirty } = state.formIsDirty;
  return {
    agencies: state.agencies,
    people: state.people,
    divisions: state.divisions,
    isGetPending: state.agencies.isGetAgencyPending,
    isUpdatePending: state.agencies.isUpdatePending,
    isUpdateOrCreatePending: state.divisions.isUpdateOrCreatePending,
    isDirty,
    ...modal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      openModal,
      updateDivision,
      clearSelectedDivisions,
      clearSelectedPeople,
      removeAgencyFromStore,
      setCurrentAgency,
      createAgency,
      updateAgency,
      removeAgency,
      getAgencies,
      getAgenciesWithDivisions,
      getDivisions,
      setFormDirty,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
class AgencyModal extends PureComponent<PropsFromRedux, AgencyModalState> {
  static contextType = PermissionsContext;

  state = {
    isUpdateAllDivisionsPending: false,
    permissions: this.context.permissions
  };

  componentDidUpdate(prevProps: Readonly<PropsFromRedux>, prevState: Readonly<AgencyModalState>, snapshot?: any) {
    if (prevProps.isGetPending && !this.props.isGetPending) {
      this.props.clearSelectedDivisions!();
      this.props.clearSelectedPeople!();
      this.props.setFormDirty(false);
    }
  }

  get isPending() {
    return !!this.props.isGetPending || !!this.props.isUpdatePending || this.state.isUpdateAllDivisionsPending;
  };

  setUpdateAllDivisionsPending = (isPending: boolean) => {
    this.setState({ isUpdateAllDivisionsPending: isPending });
  }

  public handleClose = (): void => {
    if (this.isPending) return;

    this.props.removeAgencyFromStore!();
    this.props.clearSelectedDivisions!();
    this.props.clearSelectedPeople!();
    this.props.closeModal!(MODAL_AGENCY, null);
  };

  public openAssignPeopleModal = (): void => {
    this.props.openModal!(ASSIGN_PEOPLE_MODAL, 'agency');
  };

  public saveToCurrentAgency = (values): void => {
    this.props.setCurrentAgency!(values);
  };

  public openAssignDivisionModal = (): void => {
    this.props.openModal!(ASSIGN_DIVISION_MODAL);
  };

  public showConfirmationModal = (id): void => {
    confirmAlert({
      customUI: ({ onClose }): JSX.Element => {
        return <ConfirmationModal onClose={onClose} onOk={() => this.handleDelete(id)} />;
      },
    });
  };

  public updateDivisions = async (agencyId, divisions): Promise<void> => {
    if (!divisions || this.state.isUpdateAllDivisionsPending) return;

    this.setState({ isUpdateAllDivisionsPending: true })

    const updatesList = divisions.map(division => {/* eslint-disable-line */

      if (division.toUnclaim || division.needToAdd) {
        const formData = {
          AgencyId: division.toUnclaim ? null : agencyId,
        };

        return this.props.updateDivision!(division.id, formData)
      }

    });

    await Promise.all(updatesList).finally(() => this.setUpdateAllDivisionsPending(false));
  };

  public handleSubmit = async (agencyData, combinedDivisions): Promise<void> => {
    const { people } = this.props;

    if (people!.selected && people!.selected.forAgency) {
      agencyData.PeopleId = people!.selected.forAgency.peopleId;
    }

    const formData = {
      title: agencyData.title,
      description: agencyData.description,
      PeopleId: agencyData.PeopleId,
    };

    await this.updateDivisions(agencyData.id, combinedDivisions);

    if (agencyData.id) {
      // when we UPDATE the Agency behavior
      this.props.updateAgency!(agencyData.id, formData)
      return;
    }

    // when we CREATE the Agency behavior
    this.props.createAgency!(formData)
    this.props.closeModal!(MODAL_AGENCY, null);
  };

  public handleDelete = (id): void => {
    this.props.removeAgency!(id);
    this.props.removeAgencyFromStore!();
    this.props.clearSelectedDivisions!();
    this.props.clearSelectedPeople!();
    this.props.closeModal!(MODAL_AGENCY);
  };

  public render(): JSX.Element {
    const { modal, people, agencies, divisions, isDirty } = this.props;

    return people && agencies && divisions ? (
      <React.Fragment>
        {modal.isOpen && (
          <Modal close={this.handleClose} formIsDirty={isDirty}>
            <>
              <AgencyForm
                people={people}
                agencies={agencies}
                divisions={divisions}
                onSubmit={this.handleSubmit}
                onDelete={this.showConfirmationModal}
                saveToCurrentAgency={this.saveToCurrentAgency}
                openAssignPeopleModal={this.openAssignPeopleModal}
                openAssignDivisionModal={this.openAssignDivisionModal}
              />
              { this.isPending && <Spinner isFullSize />}
            </>
          </Modal>
        )}
      </React.Fragment>
    ) : (
      <NotFoundMessage message={'Here is a problem with react store. '} />
    );
  }
}

export default connector(AgencyModal);
