import React, { FC } from 'react';
import { GrayPanel } from '../../../../panels/gray-panel/gray-panel';
import { Container } from '../../../../template/container/container';
import { vocabulary } from '../../../../../vocabulary/german';

export const AgencyReport: FC<any> = ({ entityData }) => (
  <div className={'short-report-policy'}>
    <div className="d-flex flex-row short-report-row">
      <div className="d-flex flex-column w-100">
        <GrayPanel text={'Beschrieb'} />
        <Container>
          <p className="short-report__description short-report__description_cut">{entityData.description}</p>
        </Container>
      </div>
    </div>

    {/* In the future there could be design changes. That's why code is repeating*/}
    {entityData.priorities &&
      entityData.priorities.map((priority) => (
        <div className="d-flex flex-row short-report-row" key={priority.id}>
          <div className="d-flex flex-column w-100 min-w-40">
            <GrayPanel text={priority.title} />
            <Container>
              <p className={'short-report-policy__text more-line-height'}>{priority.description}</p>
            </Container>
          </div>
          <div className="d-flex flex-column w-100 min-w-60">
            <GrayPanel text={vocabulary.labels.midTermGoal} />
            <Container>
              {priority.MidtermGoals &&
                priority.MidtermGoals.map((mtg) => (
                  <div className={'short-report-policy-mtg more-line-height'} key={mtg.id}>
                    <div className="report-mid-term-goals-info">
                      <p className="report-mid-term-goals-info__title">{mtg.title}</p>
                      <p className="report-mid-term-goals-info__description">{mtg.description}</p>
                    </div>
                  </div>
                ))}
            </Container>
          </div>
        </div>
      ))}
  </div>
);