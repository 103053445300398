import React from 'react';
import * as RRD from 'react-router-dom';
import PERMISSIONS_MATRIX from './roles-permissions-matrix/index';

import * as Types from './roles-permissions-matrix/types';


export type Role = 'admin' | 'manager' | 'viewer';

export type Permissions = {
  createNew: boolean | 'empty';
  edit: boolean | 'empty';
  remove: boolean | 'empty';
  view: boolean | 'empty';
  addFieldsInside: boolean | 'empty';
  removeFieldsInside: boolean | 'empty';
  assign: boolean | 'empty';
  printReport: boolean | 'empty';

  inviteNewUsers: boolean | 'empty';
  dragFields: boolean | 'empty';
  freezeEdit?: boolean | 'empty';
  freezeCreateNew?: boolean | 'empty';
  freezeRemove?: boolean | 'empty';
  freezeAssign?: boolean | 'empty';
  freezeAddFieldsInside?: boolean | 'empty';
  freezeRemoveFieldsInside?: boolean | 'empty';
}


export type PermissionsObject = {
  page: 'Politikbereiche' | 'Ambitionen' | 'Personen' | 'Abteilungen' | 'Bereiche/Diensteinheiten' | 'Reports' | 'Jahre' | 'Benutzer' | 'Mein Profil' | 'Home Page' | string;
  pathname: '/policy-areas' | '/priorities' | '/people' | '/agencies' | '/divisions' | '/reports' | '/profile' | '/years' | '/users' | '/' | string;
  permissions: Permissions;
  role?: Role;
}

export const PermissionsContext = React.createContext<PermissionsObject | null>(null)

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext)
  if (!context) throw new Error('usePermissions used outside of PermissionsContext')
  return context;
}


const DEFAULT_PERMISSIONS = {
  page: '',
  pathname: '',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: false,
    addFieldsInside: false,
    removeFieldsInside: false,
    assign: false,
    printReport: false,

    inviteNewUsers: false,
    dragFields: false,
    freezeYears: false,
    freezeYearsGoals: false
  }
}

export const usePermissionsMatrix = () => {
  const role = localStorage.getItem('role') as Role;
  const location = RRD.useLocation();

  const by = (pathname) => (i) => i.pathname === pathname;
  const matrix = { role, ...DEFAULT_PERMISSIONS } as PermissionsObject;
  if (role) Object.assign(matrix, (PERMISSIONS_MATRIX[role].find(by(location.pathname)) as Types.Page))
  return matrix
}

export const PermissionsProvider = (({ ...rest }): any => {
  const value = usePermissionsMatrix()
  return (
    <PermissionsContext.Provider value={value}>
      {rest.children}
    </PermissionsContext.Provider>
  )
})