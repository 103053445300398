import * as types from './types'
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import PoliciesService from '../../services/policies.service';
import { handleActionResult } from '../action-helpers';
import { closeModal } from '../modals/actions';
import { MODAL_POLICY } from '../../shared/constants/modals';

const policiesService = new PoliciesService();

const getPoliciesSuccess = (policies) => ({ type: types.POLICIES_GETALL_SUCCESS, policies })
const getPoliciesRequest = () => ({ type: types.POLICIES_GETALL_REQUEST });
const getPoliciesFailure = () => ({ type: types.POLICIES_GETALL_FAILURE });
const getPolicies = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(getPoliciesRequest());

  const successCB = (ok) => () => dispatch(getPoliciesSuccess(ok))
  const failureCB = () => dispatch(getPoliciesFailure())

  const result = await policiesService.getPolicies();
  handleActionResult(result, successCB(result), failureCB);
};



const getPolicySuccess = (policy) => ({ type: types.POLICY_GET_SUCCESS, policy });
const getPolicyRequest = () => ({ type: types.POLICY_GET_REQUEST });
const getPolicyFailure = () => ({ type: types.POLICY_GET_FAILURE });
const getPolicy = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(getPolicyRequest());

  const successCB = (ok) => () => dispatch(getPolicySuccess(ok))
  const failureCB = () => dispatch(getPolicyFailure())

  const result = await policiesService.getPolicy(id);
  handleActionResult(result, successCB(result), failureCB);
};


const createPolicySuccess = (policy) => ({ type: types.POLICY_POST_SUCCESS, policy })
const createPolicyRequest = () => ({ type: types.POLICY_POST_REQUEST })
const createPolicyFailure = () => ({ type: types.POLICY_POST_FAILURE })
const createPolicy = (formData): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(createPolicyRequest())

  const failureCB = () => dispatch(createPolicyFailure())
  const successCB = (policy) => () => {
    dispatch(createPolicySuccess(policy));
    dispatch(getPolicies());
    dispatch(closeModal(MODAL_POLICY));
  }

  const result = await policiesService.createPolicy(formData);
  handleActionResult(result, successCB(result), failureCB);
};


const removePolicySuccess = () => ({ type: types.POLICY_REMOVE_SUCCESS })
const removePolicyRequest = () => ({ type: types.POLICY_REMOVE_REQUEST })
const removePolicyFailure = () => ({ type: types.POLICY_REMOVE_FAILURE })
const removePolicy = (id): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(removePolicyRequest())
  const failureCB = () => dispatch(removePolicyFailure())
  const successCB = () => {
    dispatch(removePolicySuccess())
    dispatch(getPolicies());
  }

  const result = await policiesService.removePolicy(id);
  handleActionResult(result, successCB, failureCB);
};

const updatePolicyRequest = () => ({ type: types.POLICY_UPDATE_REQUEST });
const updatePolicySuccess = () => ({ type: types.POLICY_UPDATE_SUCCESS });
const updatePolicyFailure = () => ({ type: types.POLICY_UPDATE_FAILURE });
const updatePolicy = (id, formData): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(updatePolicyRequest());

  const failureCB = () => dispatch(updatePolicyFailure());
  const successCB = (policies) => () => {
    dispatch(getPoliciesSuccess(policies))
    dispatch(updatePolicySuccess())
  }

  const result = await policiesService.updatePolicy(id, formData);
  const policies = await policiesService.getPolicies();
  const data = { ...policies, current: result }
  handleActionResult(data, successCB(data), failureCB);
};

const removePolicyFromStore = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: types.REMOVE_POLICY_FROM_STORE });
};

export {
  getPolicies,
  getPolicy,
  createPolicy,
  removePolicy,
  updatePolicy,
  removePolicyFromStore,
};
