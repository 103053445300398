import React, { Component } from 'react';
import { Modal } from '../../template/modals/modal';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_USER } from '../../../shared/constants/modals';
import { modalService } from '../../../services/modals.service';
import UserInvitationForm from '../../forms/user-invitation-form/user-invitation-form';
import { closeModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { UserModalState } from './user-modal.interface';
import { removeUserFromStore, getUsers } from '../../../store/users/actions';


const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_USER);
  const { page } = typeof modal.modal !== 'undefined' &&  modal.modal.data !== null ? modal.modal.data : 0;
  const { isDirty } = state.formIsDirty;
  return { ...modal, page, isDirty};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      removeUserFromStore,
      getUsers,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class UserModal extends Component<PropsFromRedux, UserModalState> {
  handleClose = () => {
    const { page } = this.props;
    this.props.closeModal!(MODAL_USER);
    this.props.removeUserFromStore!();
    this.props.getUsers(page);
  };

  render() {
    const { modal, isDirty, page } = this.props;
    return (
      modal.isOpen && (
        <Modal formIsDirty={isDirty} close={this.handleClose}>
          <UserInvitationForm page={page} />
        </Modal>
      )
    );
  }
}

export default connector(UserModal);
