import omit from 'lodash.omit';

import * as at from './constants';

import { Year, YearsState } from './types';
import * as utils from '../../shared/utils';

const initialState: YearsState = {
  yearsById: {},
  yearsIds: [],
  isCreateYearPending: false,
  isUpdateYearsPending: false,
  isGetYearsPending: true,
  totalCount: 0,
  isRemovePending: false,
  currentYearId: utils.getCurrentYearIdFromLS(),
  currentYearName: utils.getCurrentYearFromLS(),
  currentYearFrozen: utils.getCurrentYearFrozenFromLS()
}

export function years(state= initialState, action): YearsState {
  switch (action.type) {
    case at.CREATE_YEAR_REQUEST:
      return {
        ...state,
        isCreateYearPending: true
      }
    case at.CREATE_YEAR_SUCCESS: {
      const { newYear } = action.payload;

      return {
        ...state,
        yearsById: {
          ...state.yearsById,
          [ newYear.id ]: newYear
        },
        yearsIds: [ ...state.yearsIds, newYear.id ],
        isCreateYearPending: false
      };
    }
    case at.CREATE_YEAR_FAILURE: {
      return {
        ...state,
        isCreateYearPending: false
      }
    }
    case at.GET_YEARS_REQUEST: {
      return {
        ...state,
        isGetYearsPending: true
      }
    }
    case at.GET_YEARS_SUCCESS: {
      const { rows: yearsList, count } = action.payload;

      let yearsById: { [id: string]: Year } = {};
      let yearsIds: number[] = [];

      for (let year of yearsList) {
        yearsById[year.id] = year;
        yearsIds = [...yearsIds, year.id];
      }

      return {
        ...state,
        yearsIds,
        yearsById,
        totalCount: count,
        isGetYearsPending: false
      };
    }
    case at.GET_YEARS_FAILURE: {
      return {
        ...state,
        isGetYearsPending: false
      }
    }
    case at.UPDATE_YEAR_REQUEST: {
      return {
        ...state,
        isUpdateYearsPending: true
      }
    }
    case at.UPDATE_YEAR_SUCCESS: {
      const { payload } = action;
      const isUpdateCurrentYear = state.currentYearId === payload.id;
      if (isUpdateCurrentYear) {
        localStorage.setItem('currentYearFrozen', String(!payload.enabled));
        localStorage.setItem('currentYearGoalFrozen', String(!payload.frozenYearGoals));
      }

      return {
        ...state,
        isUpdateYearsPending: false,
        yearsById: {
          ...state.yearsById,
          [ payload.id ]: payload
        }
      }
    }
    case at.UPDATE_YEAR_FAILURE: {
      return {
        ...state,
        isUpdateYearsPending: false
      }
    }
    case at.REMOVE_YEAR_REQUEST: {
      return {
        ...state,
        isRemovePending: true
      }
    }
    case at.REMOVE_YEAR_SUCCESS: {
      const { id } = action.payload;
      const isRemoveCurrentYear = state.currentYearId === id;

      return {
        ...state,
        yearsById: omit(state.yearsById, id),
        yearsIds: state.yearsIds.filter((yearId) => yearId !== id),
        currentYearId: isRemoveCurrentYear ? null : state.currentYearId,
        currentYearName: isRemoveCurrentYear ? null : state.currentYearName,
        isRemovePending: false
      }
    }
    case at.REMOVE_YEAR_FAILURE: {
      return {
        ...state,
        isRemovePending: false
      }
    }
    case at.SET_CURRENT_YEAR_ID: {
      const { id, name, isFrozen, isYearGoalFrozen } = action.payload;
      localStorage.setItem('currentYearId', id);
      localStorage.setItem('currentYear', name);
      localStorage.setItem('currentYearFrozen', isFrozen);
      localStorage.setItem('currentYearGoalFrozen', isYearGoalFrozen);

      return {
        ...state,
        currentYearId: id,
        currentYearName: name,
        currentYearFrozen: isFrozen
      }
    }
    case at.CLEAR_CURRENT_YEAR_DATA: {
      return {
        ...state,
        currentYearName: null,
        currentYearId: null
      }
    }
    default: return state;
  }
}
