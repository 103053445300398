import {
  USERS_GET_LIST_SUCCESS,
  USERS_GET_LIST_FAILURE,
  USERS_GET_ONE_SUCCESS,
  USERS_GET_ONE_FAILURE,
  REMOVE_USER_FROM_STORE,
} from './types';

const initialState: any = {};

export function users(state = initialState, action) {
  switch (action.type) {
    case USERS_GET_LIST_SUCCESS:
      return {
        ...action.users,
      };
    case USERS_GET_LIST_FAILURE: {
      return {
        error: action.error,
      };
    }
    case USERS_GET_ONE_SUCCESS: {
      return {
        ...state,
        current: action.user,
      };
    }
    case USERS_GET_ONE_FAILURE: {
      return {
        error: action.error,
      };
    }
    case REMOVE_USER_FROM_STORE: {
      return {
        ...state,
        current: {},
      };
    }
    default:
      return state;
  }
}
