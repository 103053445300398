import { ApiService } from './api.service';

export default class AgenciesService extends ApiService {
  public getAgencies = async () => {
    return await this.get(`/agencies`);
  };

  public getAgenciesWithDivisions = async () => {
    return await this.get(`/agencies?withDivisions=true`);
  };

  public getAgency = async (id) => {
    return await this.get(`/agencies/${id}`);
  };

  public removeAgency = async (id) => {
    return await this.remove(`/agencies/${id}`);
  };

  public createAgency = async (formData) => {
    const requestBody = {
      title: formData.title,
      description: formData.description,
      PeopleId: formData.PeopleId,
    };

    return await this.post('/agencies', requestBody);
  };

  public updateAgency = async (id, formData) => {
    const requestBody = {
      title: formData.title,
      description: formData.description,
      PeopleId: formData.PeopleId,
      order: formData.order
    };

    return await this.put(`/agencies/${id}`, requestBody);
  };


  public reorderAgencyDivisions = async(params) => {
    const {agencyId, divisionId, orderInAgencyDivisionsList} = params;
    const requestBody = {divisionId, orderInAgencyDivisionsList}
    return await this.put(`/agencies/${agencyId}/reorder-divisions`, requestBody);
  }
}