import React, { Component } from "react";
import { DivisionFormState } from "./division-form.interface";
import { AppState } from "../../../store";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Form, Formik } from "formik";
import { Button } from "../../buttons/button/button";
import { GrayPanel } from "../../panels/gray-panel/gray-panel";
import DivisionFormActivities from "./division-form-activities/division-form-activities";
import { Division } from "../../../shared/interfaces/divisions.interface";
import {
  ASSIGN_PEOPLE_MODAL,
  MODAL_DIVISION,
} from "../../../shared/constants/modals";
import { closeModal, openModal } from "../../../store/modals/actions";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmationModal } from "../../template/modals/confirmation-modal";
import { CreateButton } from "../../buttons/create-button/create-button";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { FixedHeader } from "../../template/fixed-header/fixed-header";
import { FormBody } from "../../template/form-body/form-body";
import { Container } from "../../template/container/container";
import { DivisionFormStg } from "./division-form-stg/division-form-stg";
import {
  createDivision,
  removeDivision,
  removeDivisionFromStore,
  setCurrentDivision,
  updateDivision,
  getDivisions,
} from "../../../store/divisions/actions";
import { clearSelectedPeople, getPeople } from "../../../store/people/actions";
import {
  getAgencies,
  getAgency,
  getAgenciesWithDivisions,
} from "../../../store/agencies/actions";
import { InputField } from "../../fields/input-field/input-field";
import { TextareaField } from "../../fields/textarea-field/textarea-field";
import { vocabulary } from "../../../vocabulary/german";
import CheckIfFormIsDirty from "shared/utils/checkIfFormIsDirty";
import { Spinner } from "../../template/spinner/spinner";
import ReportService from "../../../services/reports.service";
import events from '../../../shared/constants/events'
import { PermissionsContext, Role } from '../../../contexts/permissions.context';
import { FormHeader } from '../../template/form-header/FormHeader';
import * as utils from '../../../shared/utils';

const mapStateToProps = (state: AppState) => ({
  divisions: state.divisions,
  people: state.people,
  agencies: state.agencies,
  yearId: state.years.currentYearId,
  yearName: state.years.currentYearName,
  isPending: state.divisions.isUpdateOrCreatePending,
  isGetAgencyPending: state.agencies.isGetAgencyPending,
  currentYearFrozen: state.years.currentYearFrozen,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removeDivisionFromStore,
      createDivision,
      clearSelectedPeople,
      updateDivision,
      removeDivision,
      openModal,
      closeModal,
      getPeople,
      getAgencies,
      getAgency,
      setCurrentDivision,
      getDivisions,
      getAgenciesWithDivisions,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class DivisionForm extends Component<PropsFromRedux, DivisionFormState> {
  static contextType = PermissionsContext
  role = localStorage.getItem('role') as Role;
  currentYearFrozen = Boolean(localStorage.getItem('currentYearFrozen') === 'true');

  state = {
    locked: false,
    permissions: this.context.permissions
  }

  unlock = (): void => { this.setState({ locked: false }); }

  componentDidMount(): void {
    this.props.getPeople!();
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
    // this.props.getAgencies!(); // TODO: that's rewrite list of agencies on divisions page, and they disappear. need to think about it
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  onSubmit = (id: number | null, values) => {
    if (this.props.isPending) return;

    if (id) {
      // when we UPDATE the Divisions behavior
      this.props.updateDivision!(id, values);
    } else {
      // when we CREATE the Divisions behavior
      this.props.createDivision!(values);
    }
  };

  showConfirmationModal = (id: number) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() => this.handleDelete(id)}
          />
        );
      },
    });
  };

  handleDelete = (id: number) => {
    if (id) {
      this.props.removeDivision!(id);
      this.props.removeDivisionFromStore!();
      this.props.getDivisions!();
      this.props.getAgenciesWithDivisions!();
      this.props.closeModal!(MODAL_DIVISION);
    }
  };

  handleAssignPeople = (e, values) => {
    e.preventDefault();
    this.props.setCurrentDivision!(values);
    this.props.openModal!(ASSIGN_PEOPLE_MODAL, "division");
  };

  getPeopleName = (id: number) => {
    const { people } = this.props;
    if (people && people.rows) {
      const person = people.rows.find((person) => person.id === id);
      if (person) return person.name;
    }
    return "";
  };

  getAgencyName = (id: number) => {
    const { agencies } = this.props;

    if (agencies && agencies.rows) {
      const agency = agencies.rows.find((agency) => agency.id === id);

      if (agency) {
        return agency.title;
      }
    }

    return "";
  };

  renderAssignedAgency = (agencyId: number | null): JSX.Element => {
    return agencyId ? (
      <p className={"division-assigned__text"}>
        Zugewiesene Abteilung: <span>{this.getAgencyName(agencyId)}</span>
      </p>
    ) : (
      <p className={"division-assigned__text"}>Keiner Abteilung zugewiesen</p>
    );
  };

  renderAssignedPerson = (peopleId: number | null): JSX.Element => {
    return peopleId ? (
      <p className={"division-assigned__text"}>
        Zugewiesene Person: <span>{this.getPeopleName(peopleId)}</span>
      </p>
    ) : (
      <p className={"division-assigned__text"}>Keiner Person zugewiesen </p>
    );
  };

  downloadDivisionReport = (divisionId, type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadDivisionReport(divisionId, type);
  };

  renderReportButton = (id, type): JSX.Element | undefined => {
    if (this.state.permissions.printReport === true) {
      return (
        <Button
          text={type === 'pdf' ? vocabulary.buttons.reports.downloadReportPdf : vocabulary.buttons.reports.downloadReportDocx }
          onClick={() => this.downloadDivisionReport(id, type)}
        />
      )
    }
  }

  renderDeleteButton = (id): JSX.Element | undefined => {
    if (this.state.permissions.remove === true && !this.props.currentYearFrozen) {
      return (
        <Button
          className={"delete-button"}
          text={vocabulary.buttons.delete}
          onClick={() => this.showConfirmationModal(id)}
        />
      )
    }
  };


  renderSaveFormButton = (): JSX.Element | undefined => {
    if (this.state.permissions.edit === true && !this.props.currentYearFrozen) {
      return <Button type={"submit"} text={vocabulary.buttons.save} />
    }
  }

  render() {
    const {
      divisions,
      people,
      agencies,
      yearId,
      yearName,
      isPending,
    } = this.props;
    const {permissions} = this.state;
    const shouldChangeAssign = this.currentYearFrozen
      ? permissions.freezeAssign === true : permissions.assign === true;

    const initialValues: Division = {
      id: divisions!.current.id ? divisions!.current.id : null,
      title: divisions!.current.title ? divisions!.current.title : "",
      missions: divisions!.current.missions ? divisions!.current.missions : "",
      environments: divisions!.current.environments
        ? divisions!.current.environments
        : "",
      PeopleId: divisions!.current.PeopleId
        ? divisions!.current.PeopleId
        : null,
      AgencyId: divisions!.current.AgencyId
        ? divisions!.current.AgencyId
        : null,
      Activities: divisions!.current.Activities
        ? divisions!.current.Activities
        : [],
      ShortTermGoals: divisions!.current.ShortTermGoals
        ? divisions!.current.ShortTermGoals
        : [],
    };

    if (people!.selected && people!.selected.forDivision) {
      initialValues.PeopleId = people!.selected.forDivision
        ? people!.selected.forDivision.peopleId
        : null;
    }

    // by default consist from one Division key
    const isEmptyAgency = Object.keys(agencies!.current).length <= 1;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          this.onSubmit(initialValues.id, values);
        }}
      >
        {({ values, errors, touched, dirty }) => (
          <Form>
            <CheckIfFormIsDirty dirty={dirty} />
            <FixedHeader>
              <FormHeader
                renderSave={this.renderSaveFormButton()}
                renderDelete={this.renderDeleteButton(initialValues.id)}
                renderReport={this.renderReportButton(initialValues.id, 'pdf')}
                renderDocxReport={this.renderReportButton(initialValues.id, 'docx')}
              />

              <Container>
                <div className="field-wrapper">
                  <InputField
                    name="title"
                    className="form__field_big bordered"
                    validate={utils.validateField}
                    placeholder={
                      vocabulary.forms.division.placeholders.divisionTitle
                    }
                    forDivision
                    currentYearFrozen={this.currentYearFrozen}
                  />
                  {errors.title && touched.title && <p className={'field-error'}>{errors.title}</p>}
                </div>
              </Container>
            </FixedHeader>

            <FormBody>
              <GrayPanel text={vocabulary.forms.division.description} first />
              <Container>
                <div className={"division-assigned"}>
                  {this.renderAssignedAgency(initialValues.AgencyId)}
                  {this.renderAssignedPerson(initialValues.PeopleId)}
                  {
                    (shouldChangeAssign) && (
                      <CreateButton
                        text={
                          initialValues.PeopleId
                            ? vocabulary.buttons.reassignPerson
                            : vocabulary.buttons.assignPeople
                        }
                        onClick={(e) => this.handleAssignPeople(e, values)}
                      />
                    )
                  }
                </div>
              </Container>
              <div className="division-wrapper">
                <div className={"form-panel-gray"}>
                  <Container>
                    <div className={"row"}>
                      <div className={"col col_half"}>
                        <p className={"form-panel-gray__text"}>
                          {vocabulary.labels.mission}
                        </p>
                      </div>
                      <div className={"col col_half"}>
                        <p className={"form-panel-gray__text"}>
                          {vocabulary.labels.environment}
                        </p>
                      </div>
                    </div>
                  </Container>
                </div>
                <Container>
                  <div className={"row"}>
                    <div className={"col col_half"}>
                      <TextareaField
                        name={"missions"}
                        placeholder={
                          vocabulary.forms.division.placeholders.mission
                        }
                        currentYearFrozen={this.currentYearFrozen}
                        forDivision
                      />
                    </div>
                    <div className={"col col_half"}>
                      <TextareaField
                        name={"environments"}
                        placeholder={
                          vocabulary.forms.division.placeholders.environment
                        }
                        currentYearFrozen={this.currentYearFrozen}
                        forDivision
                      />
                    </div>
                  </div>
                </Container>

                <DivisionFormActivities
                  activities={values.Activities}
                  values={values}
                  yearId={yearId}
                  yearName={yearName}
                  currentYearFrozen={this.currentYearFrozen}
                />

                <div className={"form-panel-gray"}>
                  <Container>
                    <div className={"row"}>
                      <div className={"division-col division-col_title"}>
                        <p className={"form-panel-gray__text"}>
                          {vocabulary.labels.midTermGoal}
                        </p>
                      </div>
                      <div className={"division-col division-col_title"}>
                        <div className={"division-title-wrapper"}>
                          <p className={"form-panel-gray__text_50"}>
                            {vocabulary.labels.shortTermGoal}
                          </p>
                          <p className={"form-panel-gray__text_50"}>
                            {vocabulary.labels.outcome}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                {agencies &&
                  initialValues.AgencyId &&
                  !isEmptyAgency &&
                  agencies.current.MidtermGoals &&
                  agencies.current.MidtermGoals.length > 0 ? (
                  agencies.current.MidtermGoals.map(
                    (midTermGoal, midTermGoalIndex) => (
                      <div
                        className={
                          midTermGoalIndex > 0
                            ? "container container_division"
                            : "container container_division"
                        }
                        key={midTermGoalIndex}
                      >
                        <div className={"row"}>
                          <div className={"division-col division-col_row"}>
                            <div className={"division-mtg"}>
                              <p className={"division-mtg__text"}>
                                {midTermGoal.title}
                              </p>
                            </div>
                            <div className={"division-mtg"}>
                              <p className={"division-mtg__text"}>
                                {midTermGoal.description}
                              </p>
                            </div>
                          </div>
                          <div className={"division-col"}>
                            <DivisionFormStg
                              values={values}
                              midTermGoal={midTermGoal}
                              yearId={yearId}
                              currentYearFrozen={this.currentYearFrozen}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <Container>
                    {initialValues.AgencyId ? (
                      <NotFoundMessage
                        message={
                          "Nicht gefunden: Legislaturziele konnte nicht gefunden werden. "
                        }
                      />
                    ) : (
                      <NotFoundMessage
                        message={"Kein(e) agency zugewiesen. "}
                      />
                    )}
                  </Container>
                )}
                {isPending && <Spinner isFullSize />}
              </div>
            </FormBody>
            {this.state.locked && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(DivisionForm);
