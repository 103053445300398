import React, { FC } from 'react';
import { ConfirmationModalProps } from './modals.interface';

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  onClose,
  onOk,
  text,
  disabled = false
}: ConfirmationModalProps) => {
  const role = localStorage.getItem('role');

  if (role === 'admin' || role === 'manager') {
    return (
      <div className={'react-confirm-alert-body'}>
        <div className={'react-confirm-alert-body-top'} />
        <div className={'react-confirm-alert-body-bottom'}>
          <p className={'react-confirm-alert-body__title'}>
            {text ? text : `Möchten Sie dieses Objekt wirklich entfernen?`}
          </p>

          <div className={'react-confirm-alert-button-group'}>
            <button onClick={onClose} disabled={disabled}>Nein</button>
            <button
              onClick={() => {
                onOk();
                onClose();
              }}
              disabled={disabled}
            >
              Ja
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    onOk();
    onClose();
    return null;
  }
};
