import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { PageHeader } from '../../template/page-header/page-header';
import AgenciesList from '../../lists/agencies-list/agencies-list';
import { Spinner } from "../../template/spinner/spinner";
import { MODAL_AGENCY } from '../../../shared/constants/modals';
import { AgenciesPageState } from './agencies-page.interface';
import { Agency } from '../../../shared/interfaces/agencies.interface';
import { AppState } from '../../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { AgenciesActionTypes } from '../../../store/agencies/types';
import { openModal } from '../../../store/modals/actions';
import { Container } from '../../template/container/container';
import { getAgencies, getAgency } from '../../../store/agencies/actions';
import { getPeople } from '../../../store/people/actions';
import { vocabulary } from "../../../vocabulary/german";

import ReportService from "../../../services/reports.service";
import events from '../../../shared/constants/events'
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { CreateButton } from "../../buttons/create-button/create-button";
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ({
  agencies: state.agencies,
  isGetAgencyPending: state.agencies.isGetAgencyPending,
  isUpdatePending: state.agencies.isGetAgencyPending,
  isLoadingAgenciesList: state.agencies.isLoadingAgenciesList
});
const mapDispatchToProps = (dispatch: Dispatch<AgenciesActionTypes>) =>
  bindActionCreators(
    {
      getAgencies,
      getAgency,
      getPeople,
      openModal,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AgenciesPage extends React.Component<PropsFromRedux, AgenciesPageState> {

  static contextType = PermissionsContext;
  state = { locked: false, permissions: this.context.permissions };
  unlock = (): void => { this.setState({ locked: false }); }

  componentDidMount(): void {
    this.props.getAgencies!();
    this.props.getPeople!();
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  handleCreate = () => {
    this.props.openModal!(MODAL_AGENCY);
  };

  handleSelect = (item: Agency) => {
    if (item && item.id) {
      this.props.getAgency!(item.id);
      this.props.openModal!(MODAL_AGENCY);
    }
  };

  downloadAgenciesReport = (type) => {
    if (this.state.permissions.printReport === true) {
      this.setState({ locked: true });
      const reportsService = new ReportService();
      reportsService.downloadAgenciesReport(type);
    }
  };

  createAgencyButton = (): JSX.Element | undefined => {
    if (this.state.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewAgency}
          onClick={this.handleCreate}
        />
      )
    }
  }

  render() {

    const agencies = this.props.agencies

    if (this.props.isLoadingAgenciesList) return <Spinner />;

    if (agencies && agencies.rows && agencies.rows.length !== 0) {
      return (
        <React.Fragment>
          <div className={'page-content'}>
            <PageHeader
              title={vocabulary.labels.agencies}
              btnText={vocabulary.buttons.reports.downloadReportPdf}
              downloadReport={this.downloadAgenciesReport}
              btnTextDocx={vocabulary.buttons.reports.downloadReportDocx}
            />
            <Container>
              <AgenciesList agencies={agencies} onSelect={this.handleSelect} />
              {this.createAgencyButton()}
            </Container>
          </div>
          {this.state.locked && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
        </React.Fragment>
      )
    }

    return (
      <>
        <NotFoundMessage message={`Nicht gefunden: Abteilungen konnten nicht gefunden werden.`} />
        {this.createAgencyButton()}
      </>
    );
  }
}

export default connector(AgenciesPage);
