export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const CHECK_USER_LOGGED = 'CHECK_USER_LOGGED';
export const LOGOUT = 'LOGOUT';
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE'

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
  user: {};
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  user: {};
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  error: {};
}

export interface UpdateRequestAction {
  type: typeof UPDATE_PROFILE_REQUEST;
  status: Boolean;
}

export interface UpdateSuccessAction {
  type: typeof UPDATE_PROFILE_SUCCESS;
  status: Boolean;
}

export interface UpdateFailureAction {
  type: typeof UPDATE_PROFILE_FAILURE;
  status: Boolean;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}
export interface AuthenticationState {
  loggedIn: boolean, 
  user: any | null, 
  updateProfileSuccess: boolean,
  updateProfileFailure: boolean,
  updateProfileRequest: boolean 
}