export const handleActionResult = (result, callBackOnSuccess, failureCallback = (error: any) => {}) => {
  if (result) {
    if (!result.error) {
      callBackOnSuccess();
      return result;
    } else {
      failureCallback(result.error);
      throw new Error('We got the problem while getting server response!');
    }
  }
};
