import React from 'react';
import { InputField } from '../../fields/input-field/input-field';
import { LoginFormProps } from './reset-password-form.interface';
import { Form, Formik } from 'formik';
import * as utils from '../../../shared/utils';
import { Button } from '../../buttons/button/button';
import BaarLogoTransparentPNG from '../../../assets/images/baar_logo_transparent.png'

export const ResetPasswordForm = (props: LoginFormProps) => {
  const { error, success, onSubmit } = props;
  const initialValues = { email: '' };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, touched }) => {

        const renderFail = () => {
          return (
            <>
              <div className="form-group">
                <label className="form-label">Email</label>
                <InputField name="email" validate={utils.validateField} isAuth/>
                {errors.email && touched.email && <p className={'field-error'}>{errors.email}</p>}
              </div>
              <div className={'login-form-footer'}>
                <div />
                <Button text="Reset password" type="submit" className="button_login" />
              </div>
              { error &&
                <div className="form-group">
                  <h3 style={{ color: 'red' }} className="color-red">Something went wrong!</h3>
                </div>
              }
            </>
          )
        }

        const renderSuccess = () => {
          return (
            <>
              <div className="success-message">Your password has been changed successfully!</div>
              <div className="success-message">Please check your  email.</div>
            </>
          )
        }

        return (
          <Form className="form login-form">
            <img className="baar-logo" src={BaarLogoTransparentPNG} alt="logo" />
            { success ? renderSuccess() : renderFail()}
          </Form>
        )
      }}
    </Formik>
  );
};

