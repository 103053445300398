import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import UserService from '../../../services/user.service';
import { Spinner } from "../../template/spinner/spinner";


const userService = new UserService();

 const UpdatePasswordPage = () => {

  const { token } = useParams();
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);


  useEffect(() => {

    setStatusLoaded(true)
    if(token){
      userService.updatePassword(token).then((data) => {
      }).catch((err) => {
        setErrorMessage(true)
      }).finally(
        () => setStatusLoaded(false)
      );
     }
   }, [token])

  return(
    <div className="login-form">
      {statusLoaded ? <Spinner/> : (
        <>
          <img className="baar-logo" src={process.env.PUBLIC_URL + '/baar_logo_transparent.png'} alt="logo"/>
          <div className="form-group">
            {
              !errorMessage ?
                <>
                  <h3 style={{ textAlign: 'center' }} >We have sent a new password to your email.</h3>
                  <h3 style={{ textAlign: 'center' }} >Please check it.</h3>
                </> :
                <h3 style={{  textAlign: 'center', color: 'red' }} className="color-red">Something went wrong!</h3>
            }
          </div>
        </>
      )}

    </div>
  )
}



export default UpdatePasswordPage
