import React, { Component } from "react";
import { Form, Formik } from "formik";
import orderBy from "lodash.orderby";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Button } from "../../buttons/button/button";
import { GrayPanel } from "../../panels/gray-panel/gray-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { AgencyFormProps, AgencyFormState } from "./agency-form.interface";
import { AssignedDivisionsList } from "../../lists/assigned-divisions-list/assigned-divisions-list";
import { Agency } from "../../../shared/interfaces/agencies.interface";
import { FixedHeader } from "../../template/fixed-header/fixed-header";
import { FormBody } from "../../template/form-body/form-body";
import { Container } from "../../template/container/container";
import { InputField } from "../../fields/input-field/input-field";
import { TextareaField } from "../../fields/textarea-field/textarea-field";
import { vocabulary } from "../../../vocabulary/german";
import { setFormDirty } from "store/formIsDirty";
import { clearAgencyIdFromDivision } from "../../../store/agencies/actions";
import { clearAgencyIdFromSelectedDivision } from "../../../store/divisions/actions";
import ReportService from "services/reports.service";
import events from '../../../shared/constants/events'
import { Spinner } from 'components/template/spinner/spinner';
import CheckIfFormIsDirty from "shared/utils/checkIfFormIsDirty";
import * as utils from "../../../shared/utils";
import { PermissionsContext } from '../../../contexts/permissions.context';
import { FormHeader } from '../../template/form-header/FormHeader';
import { AppState } from 'store';

export class AgencyForm extends Component<AgencyFormProps, AgencyFormState> {
  static contextType = PermissionsContext;

  state = {
    locked: false,
    combinedDivisions: [],
    permissions: this.context.permissions
  }

  unlock = (): void => { this.setState({ locked: false }); }

  componentDidMount(): void {
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  componentDidUpdate(
    prevProps: Readonly<AgencyFormProps>,
    prevState: Readonly<AgencyFormState>,
    snapshot?: any
  ): void {
    if (this.props.people.selected && this.props.people.selected.forAgency) {
      // if we select person or change it, we set form to dirty, to prevent unsaved data
      this.props.setFormDirty(true);
    }
    if (this.combinedDivisions.some((division) => division.AgencyId === null)) {
      // if we delete division or add new, we set form to dirty, to prevent unsaved data
      this.props.setFormDirty(true);
    }
  }

  get selectedDivisions() {
    const { divisions } = this.props;
    const selectedDivisions: any = [];

    if (divisions.assigned.selectedDivisions) {
      for (const divisionId of divisions.assigned.selectedDivisions) {
        const division = divisions.rows.find(
          (division) => division.id === divisionId
        );

        if (division) {
          selectedDivisions.push({ ...division, needToAdd: true });
        }
      }
    }

    return selectedDivisions;
  }

  get defaultDivisions() {
    const { agencies } = this.props;
    if (
      agencies &&
      agencies.current.Divisions &&
      agencies.current.Divisions.length > 0
    ) {
      return agencies.current.Divisions;
    } else {
      return [];
    }
  }

  get combinedDivisions() {
    return orderBy(
      [...this.defaultDivisions, ...this.selectedDivisions],
      "id",
      "asc"
    );
  }

  getPeopleName = (peopleId): string => {
    const { people } = this.props;

    if (people.rows) {
      const person = people.rows.find((item) => item.id === peopleId);

      if (person) {
        return person.name;
      }
    }

    return "";
  };

  getCreateButtonText = (): string => {
    const { agencies } = this.props;

    return agencies && agencies.current && agencies.current.PeopleId
      ? vocabulary.buttons.reassignPerson
      : vocabulary.buttons.assignPeople;
  };

  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.edit === true && !this.props.currentYearFrozen) {
      return <Button type="submit" text={vocabulary.buttons.save} />
    }
  };

  renderDeleteButton = (id): JSX.Element | undefined => {
    const { onDelete } = this.props;
    if (this.state.permissions.remove === true && !this.props.currentYearFrozen) {
      return (
        <Button
          className={"delete-button"}
          text={vocabulary.buttons.delete}
          onClick={() => onDelete(id)}
        />
      )
    }
  };

  renderReportButton = (id, type): JSX.Element | undefined => {
    if (this.state.permissions.printReport === true)
      return (
        <Button
          text={type === 'pdf' ? vocabulary.buttons.reports.downloadReportPdf : vocabulary.buttons.reports.downloadReportDocx }
          onClick={() => this.downloadAgencyReport(id, type)}
        />
      );
  };

  renderResponsiblePerson = (): JSX.Element => {
    const { people, agencies } = this.props;

    if (people.selected && people.selected.forAgency) {
      return (
        <p className={"agencies__person"}>
          {`${vocabulary.forms.agency.responsiblePerson}`}:
          <span>{this.getPeopleName(people.selected.forAgency.peopleId)}</span>
        </p>
      );
    }

    if (agencies.current && agencies.current.PeopleId) {
      return (
        <p className={"agencies__person"}>
          {`${vocabulary.forms.agency.responsiblePerson}`}:{" "}
          <span>{this.getPeopleName(agencies.current.PeopleId)}</span>
        </p>
      );
    }

    return <p className={"agencies__person"}>Kein(e) person zugewiesen.</p>;
  };

  handleDelete = (id: number) => {
    const isDefaultDivisionId = this.defaultDivisions.some(
      (division) => division.id === id
    );
    const isSelectedDivisionId = this.selectedDivisions.some(
      (division) => division.id === id
    );

    if (isDefaultDivisionId) {
      this.props.clearAgencyIdFromDivision(id);
    } else if (isSelectedDivisionId) {
      this.props.clearAgencyIdFromSelectedDivision(id);
    }
  };

  handleCreate = (values): void => {
    const { saveToCurrentAgency, openAssignPeopleModal } = this.props;
    saveToCurrentAgency(values);
    openAssignPeopleModal();
  };

  downloadAgencyReport = (agencyId, type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadAgencyReport(agencyId, type);
  };

  render() {
    const { agencies, people, onSubmit, openAssignDivisionModal } = this.props;

    const initialValues: Agency = {
      id: agencies.current.id ? agencies.current.id : null,
      title: agencies.current.title ? agencies.current.title : "",
      description: agencies.current.description
        ? agencies.current.description
        : "",
      MidtermGoals: agencies.current.MidtermGoals
        ? agencies.current.MidtermGoals
        : [],
      PeopleId:
        people.selected && people.selected.forAgency
          ? people.selected.forAgency.peopleId
          : agencies.current.PeopleId,
      Divisions: agencies.current.Divisions ? agencies.current.Divisions : [],
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values, this.combinedDivisions)}
      >
        {({ errors, touched, values, dirty }) => (
          <Form>
            <CheckIfFormIsDirty dirty={dirty} />
            <FixedHeader>
              <FormHeader
                renderSave={this.renderSaveButton()}
                renderDelete={this.renderDeleteButton(initialValues.id)}
                renderReport={this.renderReportButton(initialValues.id, 'pdf')}
                renderDocxReport={this.renderReportButton(initialValues.id, 'docx')}
              />
              <Container>
                <div className="field-wrapper">
                  <InputField
                    name="title"
                    className="form__field_big bordered"
                    validate={utils.validateField}
                    placeholder={vocabulary.forms.agency.placeholders.newAgency}
                  />
                  {errors.title && touched.title && <p className={'field-error'}>{errors.title}</p>}
                </div>
              </Container>
            </FixedHeader>

            <FormBody>
              <GrayPanel text={vocabulary.forms.agency.description} first />
              <Container>
                <div className="field-wrapper percent-52">
                  <TextareaField
                    name={"description"}
                    placeholder={
                      vocabulary.forms.agency.placeholders.newAgencyDescr
                    }
                    validate={utils.validateField}
                  />
                  {errors.description && touched.description && (
                    <p className={"field-error"}>{errors.description}</p>
                  )}
                </div>
              </Container>

              <GrayPanel text={vocabulary.forms.agency.responsiblePerson} />
              <div className="container">
                <div className={"agencies-footer"}>
                  {this.renderResponsiblePerson()}
                  {
                    (this.state.permissions.assign === true) && (
                      <CreateButton
                        text={this.getCreateButtonText()}
                        onClick={() => this.handleCreate(values)}
                      />
                    )
                  }
                </div>
              </div>
              {values.id && (
                <>
                  <GrayPanel
                    text={`${vocabulary.buttons.assignDivisions}, neu von unten`}
                  />
                  <div className="container">
                    <AssignedDivisionsList
                      divisions={this.combinedDivisions}
                      onDelete={this.handleDelete}
                      newSelectedIds={
                        this.props.divisions.assigned.selectedDivisions || []
                      }
                    />
                    {
                      (this.state.permissions.assign === true) && (
                        <CreateButton
                          text={vocabulary.buttons.assignDivisions}
                          onClick={openAssignDivisionModal}
                        />
                      )
                    }
                  </div>
                </>
              )}
            </FormBody>
            {this.state.locked && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentYearFrozen: state.years.currentYearFrozen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFormDirty,
      clearAgencyIdFromDivision,
      clearAgencyIdFromSelectedDivision,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AgencyForm);
