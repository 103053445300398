import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { closeModal } from '../../../store/modals/actions';
import { Button } from '../../buttons/button/button';
import { FormHeader } from '../../template/form-header/FormHeader';
import { FixedHeader } from '../../template/fixed-header/fixed-header';
import { FormBody } from '../../template/form-body/form-body'
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { ConfigFullormState } from './config-full-form.interface';
import { Person } from '../../../shared/interfaces/people.interface';
import { Container } from '../../template/container/container';
import { CheckboxField } from '../../fields/checkbox-field/checkbox-field';
import { vocabulary } from '../../../vocabulary/german';
import CheckIfFormIsDirty from 'shared/utils/checkIfFormIsDirty';
import { PermissionsContext } from 'contexts';
import { modalService } from '../../../services/modals.service';
import { MODAL_CONFIG_FULL } from '../../../shared/constants/modals';
import CONFIG_FORM from '../../../shared/constants/configForm';
import { ConfigReportElement } from '../../../shared/interfaces/reports.interface';
import ReportService from '../../../services/reports.service';
import { Spinner } from '../../template/spinner/spinner'
import events from '../../../shared/constants/events'

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_CONFIG_FULL);
  const isPending = state.policies.isUpdatePending || state.policies.isGetPending;

  return {
    policies: state.policies,
    modals: state.modals,
    agencies: state.agencies,
    isPending,
    ...modal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class ConfigFullForm extends Component<PropsFromRedux, ConfigFullormState> {

  static contextType = PermissionsContext;
  state = { 
    ...CONFIG_FORM,
    permissions: this.context.permissions,
    loadingReport: false,
    loading: true,
  };

  private reportsService = new ReportService();

  downloadConfigReport(type) {
    const { reports, headerCheckbox } = this.state;
    const [policiesCheck, agenciesCheck] = headerCheckbox;

    let dataToReport = {} as ConfigReportElement;
    Object.keys(reports).forEach(key => {
      dataToReport[key] = {
        elements: {},
        subElements: {},
      };
      reports[key].subElements.forEach(item => {
        const curentData = dataToReport[key].subElements;
        curentData[item.id] = item.checked
      });
      reports[key].elements.forEach(item => {
        const curentData = dataToReport[key].elements;
        curentData[item.id] = item.checked
      });
    });

    dataToReport.isShowAgencies = agenciesCheck.checked;
    dataToReport.isShowPolicies = policiesCheck.checked;

    this.setState({ loadingReport: true });
    this.reportsService.downloadFullReport(type, dataToReport);
  }

  renderReportButton = (type): JSX.Element | undefined => {
    if (this.state.permissions.printReport === true) {
      return (
        <Button
          text={type === 'pdf' ? vocabulary.buttons.reports.downloadReportPdf : vocabulary.buttons.reports.downloadReportDocx }
          onClick={() => this.downloadConfigReport(type)}
        />
      )
    }
  }

  unlock = (): void => { this.setState({ loadingReport: false }); }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  componentDidMount() {
    document.body.addEventListener(events.UNLOCK.name, this.unlock);

    const { agencies, policies, divisions } = this.state.reports;
    this.reportsService.getConfigReport().then((data) => {
      let checkedData = {agencies, policies} as ConfigReportElement;
      if (data) {
        Object.keys(data).forEach(key => {
          const curData = data[key];
          checkedData[key].elements = curData.map(item => ({...item, checked: true}));
        })  
      }
      this.setState({
        reports: {...checkedData, divisions},
        loading: false,
      });
    });
  }

  onChangeElement(block, type, currentIndex?) {
    const curBlock = this.state.reports[block];
    const stgIndex = 1;

    let newState = {
      ...this.state,
      reports: {
        ...this.state.reports,
        [block]: {
          ...curBlock,
          [type]: curBlock[type].map((item, index) => {
            if (type === 'subElements' && currentIndex < stgIndex + 1 && index > currentIndex) {
              return ({...item, checked: false})  
            }
            return ({...item, checked: currentIndex === index ? !item.checked : item.checked})
          })
        } 
      }
    };

    newState.headerCheckbox = [...this.state.headerCheckbox].map(header => {
      if (header.id === block) {
        return {...header, checked: newState.reports[block].elements.some(report => report.checked)}
      }
      return header
    })
    
    this.setState(newState);
  }

  onChangeHeaderCheckbox(event, id) {
    const { checked } = event.target;
    let newHeaders = [...this.state.headerCheckbox].map((header) => {
      const curHeader = {...header};
      if (header.id === id) {
        curHeader.checked = checked;
        return curHeader;
      }
      curHeader.checked = !checked && curHeader.checked;
      return curHeader;
    });

    const newReports = {
      ...this.state.reports,
      [id]: { 
        ...this.state.reports[id],
        elements: this.state.reports[id].elements.map((item) => ({
          ...item,
          checked: item.checked || checked
        })),
        subElements: this.state.reports[id].subElements.map((item) => ({
          ...item,
          checked: item.checked && checked
        }))
      },
      divisions: {
        ...this.state.reports.divisions,
        subElements: id === 'agencies' 
        ? this.state.reports.divisions.subElements.map((item) => ({
            ...item,
            checked: item.checked && checked
          }))
        : this.state.reports.divisions.subElements,
      } 
    };
    const newState = {...this.state, headerCheckbox: newHeaders, reports: newReports};

    this.setState(newState);
  }

  render() {
    const { loadingReport, reports, headerCheckbox, loading } = this.state
    const [policiesCheck, agenciesCheck, divisionsCheck] = headerCheckbox;
    const initialValues: Person = { id: null, name: '' };
    const isShowReportButon = policiesCheck.checked || agenciesCheck.checked;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={()=> console.log('submit form')}
      >
        {({ errors, touched, dirty }) => (
          <Form>
            <CheckIfFormIsDirty dirty={dirty}></CheckIfFormIsDirty>
            <FixedHeader>
              {isShowReportButon && 
                <FormHeader
                  renderReport={this.renderReportButton('pdf')}
                  renderDocxReport={this.renderReportButton('docx')}
                />
              }
            </FixedHeader>
            <FormBody>
              <Container className="full-report__content">
                <div className={`full-report__block ${agenciesCheck.checked ? 'full-report__block--disabled' : ''}`}>
                  <CheckboxField
                    key={policiesCheck.id}
                    id={policiesCheck.id}
                    isHeader
                    text={policiesCheck.title}
                    disabled={agenciesCheck.checked}
                    checked={policiesCheck.checked}
                    onChangeCheckbox={(e) => this.onChangeHeaderCheckbox(e, policiesCheck.id)}
                  />
                  {policiesCheck.checked && (
                    <div className="content">
                      {reports.policies.elements.map((policy, index) => (
                        <CheckboxField
                          key={`policies-elem-${policy.id}`}
                          id={`policies-elem-${policy.id}`}
                          text={policy.title}
                          checked={policy.checked}
                          onChangeCheckbox={() => this.onChangeElement('policies', 'elements', index)}
                        />
                      ))}
                      <hr />
                      {reports.policies.subElements.map((policy, index, allPolicies) => (index === 0
                        ? <CheckboxField
                            key={`policies-${policy.id}`}
                            id={`policies-${policy.id}`}
                            text={policy.title}
                            checked={policy.checked}
                            onChangeCheckbox={() => this.onChangeElement('policies', 'subElements', index)}
                          />
                        : (allPolicies[index-1].checked || allPolicies[1].checked) && 
                          <CheckboxField
                            key={`policies-${policy.id}`}
                            id={`policies-${policy.id}`}
                            text={policy.title}
                            checked={policy.checked}
                            onChangeCheckbox={() => this.onChangeElement('policies', 'subElements', index)}
                          />
                      ))}
                    </div>
                  )}
                </div>
                <div className={`full-report__block ${policiesCheck.checked ? 'full-report__block--disabled' : ''}`}>
                  <CheckboxField
                      key={agenciesCheck.id}
                      id={agenciesCheck.id}
                      isHeader
                      text={agenciesCheck.title}
                      checked={agenciesCheck.checked}
                      disabled={policiesCheck.checked}
                      onChangeCheckbox={(e) => this.onChangeHeaderCheckbox(e, agenciesCheck.id)}
                    />
                  {agenciesCheck.checked && (
                    <>
                      <div className="content">
                        {reports.agencies.elements.map((agency, index) => (
                          <CheckboxField
                            key={`agencies-elem-${agency.id}`}
                            id={`agencies-elem-${agency.id}`}
                            text={agency.title}
                            checked={agency.checked}
                            onChangeCheckbox={() => this.onChangeElement('agencies', 'elements', index)}
                          />
                        ))}
                        <hr />
                        {reports.agencies.subElements.map((agency, index, allAgencies) => (index === 0
                          ? <CheckboxField
                              key={`agencies-${agency.id}`}
                              id={`agencies-${agency.id}`}
                              text={agency.title}
                              checked={agency.checked}
                              onChangeCheckbox={() => this.onChangeElement('agencies', 'subElements', index)}
                            />
                          : allAgencies[index-1].checked && 
                            <CheckboxField
                              key={`agencies-${agency.id}`}
                              id={`agencies-${agency.id}`}
                              text={agency.title}
                              checked={agency.checked}
                              onChangeCheckbox={() => this.onChangeElement('agencies', 'subElements', index)}
                            />
                        ))}
                      </div>
                      <div className="full-report__divisions">
                        <div className="form__checkbox-field_header">
                          <span className="form__checkbox-text_header">
                            {divisionsCheck.title}
                          </span>
                        </div>
                        <div className="content">
                          {reports.divisions.subElements.map((division, index, allDivisions) =>(index === 0
                          ? <CheckboxField
                            key={`${vocabulary.labels.divisions}-${division.id}`}
                            id={`${vocabulary.labels.divisions}-${division.id}`}
                            text={division.title}
                            checked={division.checked}
                            onChangeCheckbox={() => this.onChangeElement('divisions', 'subElements', index)}
                          />
                          : (allDivisions[index-1].checked || allDivisions[1].checked)&& 
                            <CheckboxField
                              key={`${vocabulary.labels.divisions}-${division.id}`}
                              id={`${vocabulary.labels.divisions}-${division.id}`}
                              text={division.title}
                              checked={division.checked}
                              onChangeCheckbox={() => this.onChangeElement('divisions', 'subElements', index)}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Container>
              </FormBody>
              {loadingReport && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
              {loading && <Spinner isFullSize />}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(ConfigFullForm);
