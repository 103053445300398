import React from 'react';
import { EntityPanel } from '../../panels/entity-panel/entity-panel';
import { AssignedDivisionsListProps } from './assigned-divisions-list.interface';
import { usePermissions } from 'contexts';

export const AssignedDivisionsList: React.FC<AssignedDivisionsListProps> = (props: AssignedDivisionsListProps) => {
  const { divisions, onDelete, newSelectedIds } = props
  const matrix = usePermissions();
  const isDeletable = matrix.permissions.removeFieldsInside === true;

  if (!divisions) return null;
  return (
    <div className='entities-list entities-list_no-margin'>
      {divisions.map((item, index) => (
        !item.toUnclaim && (
          <EntityPanel
            key={index}
            title={item.title}
            id={item.id}
            onDelete={() => onDelete(item.id)}
            isDeletable={isDeletable}
            className={newSelectedIds.includes(item.id || 0) ? 'division__selected-item' : ''}
          />
        )
      ))}
    </div>
  );
}

