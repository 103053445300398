import React, { Component } from 'react';
import { Modal } from '../../template/modals/modal';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_CONFIG_FULL } from '../../../shared/constants/modals';
import { modalService } from '../../../services/modals.service';
import ConfigFullForm from '../../forms/config-full-form/config-full-form';
import { closeModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { ConfigReportState } from './config-report-modal.interface';
import { removePeopleFromStore, getPeople } from '../../../store/people/actions';


const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_CONFIG_FULL);
  const { isDirty } = state.formIsDirty;
  return { ...modal, isDirty };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      removePeopleFromStore,
      getPeople,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class ConfigReportModal extends Component<PropsFromRedux, ConfigReportState> {
  handleClose = () => {
    this.props.closeModal!(MODAL_CONFIG_FULL);
  };

  render() {
    const { modal, isDirty } = this.props;
    return (
      modal.isOpen && (
        <Modal formIsDirty={isDirty} close={this.handleClose}>
          <ConfigFullForm />
        </Modal>
      )
    );
  }
}

export default connector(ConfigReportModal);
