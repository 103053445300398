import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from '../../template/modals/confirmation-modal';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_PEOPLE } from '../../../shared/constants/modals';
import { closeModal } from '../../../store/modals/actions';
import { Button } from '../../buttons/button/button';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { FormHeader } from '../../template/form-header/FormHeader';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { PeopleFormState } from './people-form.interface';
import { Person } from '../../../shared/interfaces/people.interface';
import { Container } from '../../template/container/container';
import { createPeople, removePeople, removePeopleFromStore, updatePeople } from '../../../store/people/actions';
import { InputField } from '../../fields/input-field/input-field';
import * as utils from '../../../shared/utils';
import { vocabulary } from '../../../vocabulary/german';
import CheckIfFormIsDirty from 'shared/utils/checkIfFormIsDirty';
import { PermissionsContext } from 'contexts';


const mapStateToProps = (state: AppState) => ({ 
  people: state.people,
  currentYearFrozen: state.years.currentYearFrozen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removePeople,
      createPeople,
      updatePeople,
      removePeopleFromStore,
      closeModal,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PeopleForm extends Component<PropsFromRedux, PeopleFormState> {

  static contextType = PermissionsContext;
  state = { permissions: this.context.permissions }

  handleSave = (id: number | null, person: Person) => {
    if (id) {
      // when we UPDATE the People behavior
      this.props.updatePeople!(id, person);
    } else {
      // when we CREATE the People behavior
      this.props.createPeople!(person);
      this.props.closeModal!(MODAL_PEOPLE);
    }
  };

  showConfirmationModal = (id: number | null) => {
    if (id) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <ConfirmationModal onClose={onClose} onOk={() => this.handleDelete(id)} />;
        },
      });
    }
  };

  handleDelete = (id: number) => {
    this.props.removePeople!(id);
    this.props.removePeopleFromStore!();
    this.props.closeModal!(MODAL_PEOPLE);
  };

  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.edit === true && !this.props.currentYearFrozen) {
      return <Button type={'submit'} text={vocabulary.buttons.save} />
    }
  }

  renderDeleteButton = (id): JSX.Element | undefined => {
    if (this.state.permissions.remove === true && !this.props.currentYearFrozen) {
      return (
        <Button
          className={'delete-button'}
          text={vocabulary.buttons.delete}
          onClick={() => this.showConfirmationModal(id)} />
      )
    }
  }

  render() {
    const { people } = this.props;
    const initialValues: Person = { id: null, name: '' };

    if (people && people.current) {
      initialValues.id = people.current.id ? people.current.id : null;
      initialValues.name = people.current.name ? people.current.name : '';
    }


    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          this.handleSave(initialValues.id, values);
        }}
      >
        {({ errors, touched, dirty }) => (
          <Form>
            <CheckIfFormIsDirty dirty={dirty}></CheckIfFormIsDirty>
            <FormHeader renderSave={this.renderSaveButton()} renderDelete={this.renderDeleteButton(initialValues.id)} />
            <GrayPanel text={vocabulary.forms.people.description} people />

            <Container>
              <div className="field-wrapper">
                <InputField
                  name="name"
                  placeholder={vocabulary.forms.people.placeholders.name}
                  validate={utils.validateField}
                  className={'form__field_big'}
                />
                {errors.name && touched.name && <p className={'field-error'}>{errors.name}</p>}
              </div>
            </Container>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(PeopleForm);
