import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as utils from '../../../shared/utils';
import { bindActionCreators } from 'redux';
import { LoginPageState } from './login-page.interface';
import { AppState } from '../../../store';
import { clearAlerts } from '../../../store/alert/actions';
import { LoginForm } from '../../forms/login-form/login-form';
import { login } from '../../../store/user/actions';

const mapStateToProps = (state: AppState) => ({
  alert: state.alert,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      clearAlerts,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class LoginPage extends Component<PropsFromRedux, LoginPageState> {
  componentDidMount(): void {
    const { loggedIn } = this.props.authentication;

    if (loggedIn) {
      utils.history.push('/');
    }
  }

  handleSubmit = ({ username, password }) => {
    if (username && password) {
      this.props.login(username, password);
      this.props.clearAlerts();
    }
  };

  render() {
    const { alert } = this.props;
    return <LoginForm onSubmit={this.handleSubmit} alert={alert} />;
  }
}

export default connector(LoginPage);
