import socketClient from 'socket.io-client';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid'

import { ApiService } from './api.service';
import config from '../config';
import * as utils from '../shared/utils';
import events from '../shared/constants/events'
import { ConfigReportElement } from '../shared/interfaces/reports.interface';

export default class ReportService extends ApiService {
  private baseUrl = `/reports`;
  private socket = socketClient(config.socketURL, {
    autoConnect: false
  });

  public getFullReport = async () => {
    return await this.get(this.baseUrl);
  };

  public getConfigReport = async () => {
    return await this.get(`${this.baseUrl}/config-report`);
  };

  public getShortReport = async (id, entityName) => {
    return await this.get(`${this.baseUrl}/${entityName}/${id}`);
  };

  private openConnection = (sessionId): void => {
    if (this.socket.connected) return;


    const userId = jwt.decode(this.getToken()).id;

    this.socket.on(`pdf-${userId}-${sessionId}`, (data) => {
      try {
        const url = JSON.parse(data).url;
        utils.openExternalLink(url);
        this.socket.close();
      } catch (error) {
        this.socket.close();
      }
      document.body.dispatchEvent(events.UNLOCK.event);
    });

    this.socket.open();
  };

  private getPDF = (downloadFunc): void => {
    const sessionId = uuidv4();
    this.openConnection(sessionId);
    downloadFunc(sessionId);
  };

  public downloadFullReport = (type, data?:ConfigReportElement ): void => {
    this.getPDF((sessionId) => this.post(`${this.baseUrl}/pdf?sessionId=${sessionId}&type=${type}`, data));
  };

  public downloadDivisionReport = (divisionId, type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/division-pdf/${divisionId}?sessionId=${sessionId}&type=${type}`));
  };

  public downloadDivisionsReport = (type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/divisions-pdf?sessionId=${sessionId}&type=${type}`));
  }

  public downloadAgencyReport = (agencyId, type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/agency-pdf/${agencyId}?sessionId=${sessionId}&type=${type}`));
  }

  public downloadAgenciesReport = (type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/agencies-pdf?sessionId=${sessionId}&type=${type}`));
  }

  public downloadPolicyReport = (policyId): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/policy-pdf/${policyId}?sessionId=${sessionId}`));
  }

  public downloadPoliciesReport = (type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/policies-pdf?sessionId=${sessionId}&type=${type}`));
  }

  public downloadShortPolicyReportByPolicyID = ({ policyId, type }): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/short-policy-pdf/${policyId}?sessionId=${sessionId}&type=${type}`));
  };

  public downloadShortPoliciesReport = (type): void => {
    this.getPDF((sessionId) => this.get(`${this.baseUrl}/short-policies-pdf?sessionId=${sessionId}&type=${type}`));
  };

}
