import React, { Component } from "react";
import classNames from "classnames";
import { UserPanelProps, UserPanelState } from "./user-panel.interface";

export class UserPanel extends Component<UserPanelProps, UserPanelState> {
  state = { selected: false };

  handleClick = () => {
    const { onClick, isSelectable } = this.props;

    if (isSelectable) {
      this.setState((state) => {
        return {
          selected: !state.selected,
        };
      });
    }

    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      name,
      email,
      status,
      role,
      className,
      onDelete,
      isDeletable,
      isSelectable,
      isSmall,
      isDisabled,
      onClick,
      isFixed,
    } = this.props;
    const { selected } = this.state;

    let classList = "entities-item";

    if (isDeletable) {
      classList += " deletable";
    }

    if (isSmall) {
      classList += " small";
    }

    if (isDisabled) {
      classList += " disabled";
    }

    if (selected) {
      classList += " selected";
    }

    if (isSelectable || onClick) {
      classList += " hoverable";
    }

    if (isFixed) {
      classList += " fixed";
    }

    return (
      <div
        className={classNames(classList, className)}
        onClick={this.handleClick}
      >
        <div className="entities-item-content">
          <div className="entities-item__title">
            <div className={'user-name'}>{name}</div>
            <div className={'user-email'}>{email}</div>
            <div className={'user-status status-' + status.toLowerCase()}>{status}</div>
            <div className={'user-role'}>{role}</div>
          </div>
          {isDeletable && onDelete && (
            <i
              className="entities-item__delete"
              onClick={(event) => {
                return onDelete ? onDelete(event) : null;
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
