import React, { FC } from 'react';
import classNames from 'classnames';
import { Container } from '../../template/container/container';

type GrayPanelProps = {
  text: string;
  noMargin?: boolean;
  // ++++ N  E  U ++++ 
  first?: boolean;
  people?: boolean;
};

export const GrayPanel: FC<GrayPanelProps> = ({ text, noMargin, first, people }: GrayPanelProps) => (
  <div className={classNames('form-panel-gray', noMargin && 'no-margin', first && 'first', people && 'people')}>
    <Container>
      <p className={'form-panel-gray__text'}>{text}</p>
    </Container>
  </div>
);
