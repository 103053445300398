import React from 'react';
import { render } from 'react-dom';
import App from './components/app';
import { Provider } from 'react-redux';
import configureStore from './store';


const Root = (() => (
    <Provider store={configureStore()}>
      <App />
    </Provider>
))

render(<Root />, document.getElementById('root'));