import React, { Component } from "react";
import { PaginationPanelProps, PaginationPanelState } from "./pagination-panel.interface";

export class PaginationPanel extends Component<PaginationPanelProps, PaginationPanelState> {


  state = {
    buttonsArray: []
  };


  componentDidMount(){
    this.updateButtonsArray()
  }

  updateButtonsArray(){
    const { totalPages } = this.props
    const buttonsArray: any = []

    for (let index = 0; index < totalPages; index++) {
      buttonsArray.push(index)
    }

    this.setState({buttonsArray})
  }

  render() {
    const {
      page,
      changePage,
    } = this.props;

    return (
      <div
        className="pagination-list"
      >
        {this.state.buttonsArray.map((p, index) => (
          <div onClick={() => changePage(p)} className={`pagination-button ${p === page && 'active'}`} key={index}>
            <div className={"pagination-button__number"} >{p +1}</div>
          </div>
        ))}
      </div>
    );
  }
}
