import { vocabulary } from '../../vocabulary/german';

interface Item {
  id: string | number;
  title: string;
  checked?: boolean;
}

export default {
  reports: {
    policies: {
      elements: [] as Array<Item>,
      subElements: [
        {
          id: 'mtg',
          title: vocabulary.labels.midTermGoals,
          checked: false
        },
        {
          id: 'stg',
          title: vocabulary.buttons.reports.divisions.midTermGoalsExtended,
          checked: false
        },
        {
          id: 'reason',
          title: vocabulary.buttons.reports.divisions.reasons,
          checked: false,
        },
        {
          id: 'outcome',
          title: vocabulary.buttons.reports.divisions.outcomes,
          checked: false,
        },
        {
          id: 'action',
          title: vocabulary.buttons.reports.divisions.actions,
          checked: false,
        },
      ]
    },
    agencies: {
      elements: [] as Array<Item>,
      subElements: [
        {
          id: 'priorities',
          title: vocabulary.labels.priorities,
          checked: false
        },
        {
          id: 'mtg',
          title: vocabulary.labels.midTermGoals,
          checked: false
        }
      ]
    },
    divisions: {
      elements: [] as Array<Item>,
      subElements: [
        {
          id: 'mtg',
          title: vocabulary.labels.midTermGoals,
          checked: false
        },
        {
          id: 'stg',
          title: vocabulary.buttons.reports.divisions.midTermGoals,
          checked: false,
        },
        {
          id: 'reason',
          title: vocabulary.buttons.reports.divisions.reasons,
          checked: false,
        },
        {
          id: 'outcome',
          title: vocabulary.buttons.reports.divisions.outcomes,
          checked: false,
        },
        {
          id: 'action',
          title: vocabulary.buttons.reports.divisions.actions,
          checked: false,
        },
      ]
    },
  },
  headerCheckbox:[
    {
      id: 'policies',
      title: vocabulary.labels.policiesAreas,
      checked: false,
    },
    {
      id: 'agencies',
      title: vocabulary.labels.agencies,
      checked: false,
    },
    {
      id: 'divisions',
      title: vocabulary.labels.divisions,
    }
  ],
}