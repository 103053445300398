import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authentication } from './user/reducer';
import { alert } from './alert/reducer';
import { policies } from './policies/reducer';
import { modals } from './modals/reducer';
import { priorities } from './priorities/reducer';
import { menu } from './menu/reducer';
import { people } from './people/reducer';
import { agencies } from './agencies/reducer';
import { divisions } from './divisions/reducer';
import { statistics } from './general-statistics/reducer';
import { formIsDirty } from './formIsDirty';
import { years } from './years/reducer';
import { users } from './users/reducer';

const middleWareEnhancer = applyMiddleware(thunk);

const rootReducer = combineReducers({
  alert,
  authentication,
  policies,
  modals,
  priorities,
  menu,
  people,
  agencies,
  divisions,
  statistics,
  formIsDirty,
  years,
  users
});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  return createStore(rootReducer, composeWithDevTools(middleWareEnhancer));
}
type TypedDispatch<T> = ThunkDispatch<T, any,  AnyAction>;
 
export const useAppDispatch = () => useDispatch<TypedDispatch<AppState>>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default configureStore;
