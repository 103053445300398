import React, { FC } from 'react';
import { CheckboxFieldProps } from './checkbox-field.interface';

export const CheckboxField: FC<CheckboxFieldProps> = ({text, id, isHeader, checked, onChangeCheckbox, disabled}: CheckboxFieldProps) => {

  return (
    <div className={`form__checkbox-field${isHeader ? '_header' : ''}`}>
      <input type="checkbox" disabled={disabled} checked={checked} onChange={onChangeCheckbox} id={String(id)} />
      <label htmlFor={String(id)}> 
        <span className="form__checkbox-icon"></span>
        <span className={`form__checkbox-text${isHeader ? '_header' : ''}`}>{text}</span>
      </label>
    </div>
  );
};
