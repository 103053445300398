import { vocabulary } from '../../vocabulary/german';

export const MODAL_POLICY = vocabulary.labels.policiesAreas;
export const MODAL_PEOPLE = vocabulary.labels.people;
export const MODAL_AGENCY = vocabulary.labels.agencies;
export const MODAL_DIVISION = vocabulary.labels.divisions;
export const ASSIGN_AGENCY_MODAL = vocabulary.buttons.assignAgencies;
export const ASSIGN_PEOPLE_MODAL = vocabulary.buttons.assignPeople;
export const ASSIGN_DIVISION_MODAL = vocabulary.buttons.assignDivisions;
export const MODAL_USER = vocabulary.labels.users;
export const MODAL_CONFIG_FULL = vocabulary.labels.full;
