import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '../../../template/container/container';
import { PageHeader } from '../../../template/page-header/page-header';
import ReportService from '../../../../services/reports.service';
import { ReportsPageProps } from './reports-page.types';
import { vocabulary } from "../../../../vocabulary/german";
import { Spinner } from '../../../template/spinner/spinner';
import events from '../../../../shared/constants/events'
import { usePermissions } from '../../../../contexts/permissions.context';
import { openModal } from "../../../../store/modals/actions";
import { MODAL_CONFIG_FULL } from '../../../../shared/constants/modals';


export const ReportsPage: FC<ReportsPageProps> = () => {
  const matrix = usePermissions()
  const reportsService = new ReportService();
  const [locked, setLocked] = useState(false);
  const dispatch = useDispatch()

  const downloadBlock = [
    {
      title: vocabulary.buttons.reports.downloadPDF,
      type: 'pdf'
    },
    {
      title: vocabulary.buttons.reports.downloadWord,
      type: 'docx'
    },
  ]

  const downloadFullReport = (type): void => {
    setLocked(true)
    reportsService.downloadFullReport(type);
  };

  const downloadShortPoliciesReport = (type): void => {
    setLocked(true)
    reportsService.downloadShortPoliciesReport(type);
  };

  const unlock = () => setLocked(false);

  React.useEffect(() => {
    document.body.addEventListener(events.UNLOCK.name, unlock);

    return () => {
      document.body.removeEventListener(events.UNLOCK.name, unlock);
    };
  }, [])

  const otherProps = { permissions: matrix.permissions }

  return (
    <>
      <PageHeader title="Reports" btnText="" downloadReport={null} {...otherProps} />
      <Container>
        <div className="report-links">
          <div className="d-flex flex-row">
            <a className={'report__link'} href={'/reports/short-report'}>
              Politikbereiche <br /> Ambitionen<br /> Legislaturziele<br /> Abteilungen und Dienststellen
            </a>
          </div>
        </div>
      </Container>
      {downloadBlock.map((block) => (
        <div key={block.type} className={`report-${block.type} report-buttons`}>
        <Container>
          <p className="report-controls__title">{block.title}</p>

          <div className="report-pdf-content">
            <button type="button" className="report-pdf__btn" onClick={() => downloadFullReport(block.type)}>
              {vocabulary.buttons.reports.downloadFullReport}
              <i className="report-pdf__icon" />
            </button>

            <button type="button" className="report-pdf__btn" onClick={() => downloadShortPoliciesReport(block.type)}>
              {vocabulary.buttons.reports.downloadShortPoliciesReport}
              <i className="report-pdf__icon" />
            </button>
          </div>
        </Container>
      </div>
      ))}
      <div className="report-buttons">
        <Container>
          <p className="report-controls__title">{vocabulary.buttons.reports.downloadConfigReport}</p>

          <button type="button" className="report-pdf__btn" onClick={() => dispatch(openModal(MODAL_CONFIG_FULL))}>
            {vocabulary.buttons.reports.configureFulleReport}
            <i className="report-configure__icon" />
          </button>
        </Container>
      </div>
      {locked && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
    </>
  );
};
