export const PRIORITIES_GETALL_REQUEST = 'PRIORITIES_GETALL_REQUEST';
export const PRIORITIES_GETALL_SUCCESS = 'PRIORITIES_GETALL_SUCCESS';
export const PRIORITIES_GETALL_FAILURE = 'PRIORITIES_GETALL_FAILURE';

export const PRIORITY_CREATE_REQUEST = 'PRIORITY_CREATE_REQUEST';
export const PRIORITY_CREATE_SUCCESS = 'PRIORITY_CREATE_SUCCESS';
export const PRIORITY_CREATE_FAILURE = 'PRIORITY_CREATE_FAILURE';

export const PRIORITY_UPDATE_REQUEST = 'PRIORITY_UPDATE_REQUEST';
export const PRIORITY_UPDATE_SUCCESS = 'PRIORITY_UPDATE_SUCCESS';
export const PRIORITY_UPDATE_FAILURE = 'PRIORITY_UPDATE_FAILURE';

export const PRIORITY_REMOVE_REQUEST = 'PRIORITY_REMOVE_REQUEST';
export const PRIORITY_REMOVE_SUCCESS = 'PRIORITY_REMOVE_SUCCESS';
export const PRIORITY_REMOVE_FAILURE = 'PRIORITY_REMOVE_FAILURE';

export const REMOVE_PRIORITIES_FROM_STORE = 'REMOVE_PRIORITIES_FROM_STORE';
