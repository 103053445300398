import React, { FC } from "react";
import { FieldArray } from "formik";
import { CreateButton } from "../../../buttons/create-button/create-button";
import { PriorityProps } from "./priority-form.interface";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmationModal } from "../../../template/modals/confirmation-modal";
import { Container } from "../../../template/container/container";
import MidTermGoalsForm from "../mtg-form/mtg-form";
import { TextareaField } from "../../../fields/textarea-field/textarea-field";
import { vocabulary } from "../../../../vocabulary/german";
import * as utils from '../../../../shared/utils'
import { usePermissions } from '../../../../contexts/permissions.context';

export const PrioritiesForm: FC<PriorityProps> = (props: PriorityProps) => {
  const matrix = usePermissions()

  const showRemovePropertyConfirmationModal = (submitFunction): void => {
    confirmAlert({
      customUI: ({ onClose }): JSX.Element => {
        return <ConfirmationModal onClose={onClose} onOk={submitFunction} />;
      },
    });
  };

  const addEmptyProperty = (priorityHelper): void => {
    const YearId = utils.getCurrentYearIdFromLS();

    priorityHelper.push({
      title: "",
      description: "",
      MidtermGoals: [],
      YearId,
    });
  };

  return (
    <FieldArray
      name="Priorities"
      render={(priorityHelpers) => (
        <div className={"priorities"}>
          {props.values.Priorities && props.values.Priorities.length > 0 ? (
            props.values.Priorities.map((priority, index) => (
              <div key={index} className={"Priorities-item"}>
                <div className={"form-panel-gray"}>
                  <Container>
                    <div className={"row"}>
                      <div className={"col col_header"}>
                        <div className={"form-panel-gray-wrapper"}>
                          <p className={"form-panel-gray__text"}>
                            {vocabulary.labels.priority} #{index + 1}
                          </p>
                          {
                            (matrix.permissions.createNew === true) && (
                              <button
                                onClick={() =>
                                  showRemovePropertyConfirmationModal(() => {
                                    priorityHelpers.remove(index);
                                    props.clearSelectedAgency(index, undefined);
                                  })
                                }
                                className={"priorities__remove-icon"}
                              />
                            )
                          }
                        </div>
                      </div>
                      <div className={"col col_header"}>
                        <p className={"form-panel-gray__text"}>
                          {vocabulary.labels.midTermGoals}
                        </p>
                      </div>
                      <div className={"col col_header"}>
                        <p className={"form-panel-gray__text padding-left-30"}>
                          {vocabulary.labels.agencies}
                        </p>
                      </div>
                    </div>
                  </Container>
                </div>
                <Container>
                  <div className={"row"}>
                    <div className={"col"}>
                      <div className={"form-area-group"}>
                        <TextareaField
                          wrapperClass={"percent-30"}
                          name={`Priorities.${index}.title`}
                          placeholder={
                            vocabulary.forms.policy.placeholders
                              .newPriorityTitle
                          }
                        />
                        <TextareaField
                          wrapperClass={"percent-70"}
                          name={`Priorities.${index}.description`}
                          placeholder={
                            vocabulary.forms.policy.placeholders
                              .newPriorityDescr
                          }
                        />
                      </div>
                    </div>
                    <div className={"col col_goals"}>
                      <MidTermGoalsForm
                        priorityIndex={index}
                        priority={priority}
                        clearSelectedAgency={props.clearSelectedAgency}
                        handleAssignAgencies={props.handleAssignAgencies}
                      />
                    </div>
                  </div>
                </Container>
              </div>
            ))
          ) : (
            <></>
          )}

          <Container>
            {
              (matrix.permissions.addFieldsInside === true) && (
                <CreateButton
                  text={vocabulary.buttons.addPriority}
                  onClick={() => {
                    addEmptyProperty(priorityHelpers);
                  }}
                />
              )
            }
          </Container>
        </div>
      )}
    />
  );
};
