import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from 'store';

const SET_FORM_IS_DIRTY = 'SET_FORM_IS_DIRTY';

export const setFormDirty = (val: boolean): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: SET_FORM_IS_DIRTY, val });
};

export function formIsDirty(state: { isDirty: boolean } = { isDirty: false }, action) {
  switch (action.type) {
    case SET_FORM_IS_DIRTY: {
      return {
        isDirty: action.val,
      };
    }
    default:
      return state;
  }
}
