import { Division } from '../shared/interfaces/divisions.interface';
import { ApiService } from './api.service';

export default class DivisionsService extends ApiService {
  public getDivisions = async () => {
    return await this.get(`/divisions`);
  };

  public getDivision = async (id) => {
    return await this.get(`/divisions/${id}`);
  };

  public removeDivision = async (id) => {
    return await this.remove(`/divisions/${id}`);
  };

  public createDivision = async (formData: Division) => {
    const requestBody: Division = {
      id: null,
      title: formData.title,
      missions: formData.missions,
      environments: formData.environments,
      PeopleId: formData.PeopleId,
      AgencyId: formData.AgencyId,
      Activities: formData.Activities,
      ShortTermGoals: formData.ShortTermGoals,
    };

    return await this.post('/divisions', requestBody);
  };

  public updateDivision = async (id, formData) => {
    const requestBody = {
      title: formData.title,
      missions: formData.missions,
      environments: formData.environments,
      PeopleId: formData.PeopleId,
      AgencyId: formData.AgencyId,
      Activities: formData.Activities,
      ShortTermGoals: formData.ShortTermGoals,
    };

    return await this.put(`/divisions/${id}`, requestBody);
  };
}


