import { STATISTICS_GET_SUCCESS } from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import StatisticsService from '../../services/statistics.service';
import { handleActionResult } from '../action-helpers';

const statisticsService = new StatisticsService();

const getStatistics = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(statistics) {
    return {
      type: STATISTICS_GET_SUCCESS,
      statistics,
    };
  }

  const result = await statisticsService.getStatistics();
  return handleActionResult(result, () => dispatch(success(result)));
};

export {
  getStatistics
};