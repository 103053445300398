import { ApiService } from './api.service';

export default class PoliciesService extends ApiService {
  public getPolicies = async () => {
    return await this.get(`/policies`);
  };

  public getPolicy = async (id) => {
    return await this.get(`/policies/${id}`);
  };

  public createPolicy = async (formData) => {
    return await this.post(`/policies/`, formData);
  };

  public removePolicy = async (id) => {
    return await this.remove(`/policies/${id}`);
  };

  public updatePolicy = async (id, formData) => {
    return await this.put(`/policies/${id}`, formData);
  };
}



