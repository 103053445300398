import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'store';
import { getStatistics } from 'store/general-statistics/actions';
import { PageHeader } from '../../template/page-header/page-header';
import { Container } from 'components/template/container/container';
import { StatisticItem } from 'components/template/statistic-item/statistic-item';
import { vocabulary } from 'vocabulary/german';
import { usePermissions } from '../../../contexts/permissions.context';

const mapStateToProps = (state: AppState) => ({
  statistics: state.statistics,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getStatistics,
  },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const HomePage: FC<PropsFromRedux> = (props: PropsFromRedux) => {
  const matrix = usePermissions()
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(()=> {
    const role = localStorage.getItem('role');
    setIsAdmin(role === 'admin')
  }, [])

  useEffect(() => {
    const getStatistics = () => {
      let canceled = false;
      const cancel = (() => { canceled = true })

      if (!canceled) props.getStatistics!();
      return cancel;
    }

    const cancel = getStatistics()
    return () => { cancel() }
  }, [props.getStatistics]);

  const { statistics } = props;
  const otherProps = { permissions: matrix.permissions }

  return (
    <div className={'page-content'}>
      <PageHeader title="Home Page" btnText="" downloadReport={null} {...otherProps} />
      <Container>
        <div className={'statistic-wrapper'}>
          <StatisticItem title={vocabulary.labels.policiesAreas} link="/policy-areas" number={statistics!.policies} />
          <StatisticItem title={vocabulary.labels.agencies} link="/agencies" number={statistics!.agencies} />
          {isAdmin && (
            <>
              <StatisticItem title={vocabulary.labels.people} link="/people" number={statistics!.peoples} />
              <StatisticItem title={vocabulary.labels.priorities} link="/priorities" number={statistics!.priorities} />
            </>
          )}
          <StatisticItem title={vocabulary.labels.divisions} link="/divisions" number={statistics!.divisions} />
        </div>
      </Container>
    </div>
  )
};

export default connector(HomePage);
