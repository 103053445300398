import React, { FC } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { SelectFieldProps } from './select-field.interface';
import * as utils from '../../../shared/utils';

export const SelectField: FC<SelectFieldProps> = ({ name, className, children, forDivision, value }: SelectFieldProps) => {
  const isReadOnly = utils.getReadOnly();

  const editable = isReadOnly ? 'form__field_readonly' : '';

  return (
    <Field disabled={isReadOnly} name={name} className={classNames(className, editable)} component={'select'} value={value} >
      {children}
    </Field>
  );
};
