export const DIVISIONS_GETALL_REQUEST = 'DIVISIONS_GET_REQUEST';
export const DIVISIONS_GETALL_SUCCESS = 'DIVISIONS_GETALL_SUCCESS';
export const DIVISIONS_GETALL_FAILURE = 'DIVISIONS_GETALL_FAILURE';

export const DIVISION_GET_REQUEST = 'DIVISION_GET_REQUEST';
export const DIVISION_GET_SUCCESS = 'DIVISION_GET_SUCCESS';
export const DIVISION_GET_FAILURE = 'DIVISION_GET_FAILURE';

export const DIVISION_CREATE_REQUEST = 'DIVISION_CREATE_REQUEST';
export const DIVISION_CREATE_SUCCESS = 'DIVISION_CREATE_SUCCESS';
export const DIVISION_CREATE_FAILURE = 'DIVISION_CREATE_FAILURE';

export const DIVISION_UPDATE_REQUEST = 'DIVISION_UPDATE_REQUEST';
export const DIVISION_UPDATE_SUCCESS = 'DIVISION_UPDATE_SUCCESS';
export const DIVISION_UPDATE_FAILURE = 'DIVISION_UPDATE_FAILURE';

export const DIVISION_REMOVE_REQUEST = 'DIVISION_REMOVE_REQUEST';
export const DIVISION_REMOVE_SUCCESS = 'DIVISION_REMOVE_SUCCESS';
export const DIVISION_REMOVE_FAILURE = 'DIVISION_REMOVE_FAILURE';

export const REMOVE_DIVISION_FROM_STORE = 'REMOVE_DIVISION_FROM_STORE';
export const SELECT_DIVISIONS = 'SELECT_DIVISIONS';
export const CLEAR_SELECTED_DIVISIONS = 'CLEAR_SELECTED_DIVISIONS';
export const CLEAR_CURRENT_DIVISION = 'CLEAR_CURRENT_DIVISION';

export const CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION = 'CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION';
