import React from 'react';
import { ActivitiesProps } from './division-form-activities.interface';
import { FieldArray } from 'formik';
import { CreateButton } from '../../../buttons/create-button/create-button';
import { Container } from '../../../template/container/container';
import { TextareaField } from '../../../fields/textarea-field/textarea-field';
import { SelectField } from '../../../fields/select-field/select-field';
import { vocabulary } from '../../../../vocabulary/german';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from '../../../template/modals/confirmation-modal';
import { usePermissions } from '../../../../contexts/permissions.context';

const DivisionFormActivities: React.FC<ActivitiesProps> = ({currentYearFrozen = false, ...props}: ActivitiesProps) => {
  const matrix = usePermissions()
  const currentYear = props.yearName ? +props.yearName : 0;
  const lastYear = currentYear - 1;
  const beforeLastYear = lastYear - 1;

  return (
    <FieldArray
      name={'Activities'}
      render={(activitiesHelper) => (
        <div>
          <div className={'form-panel-gray'}>
            <Container>
              <div className="row">
                <div className="activity-col">
                  <p className={'form-panel-gray__text'}>{vocabulary.labels.activities}</p>
                </div>
                <div className="activity-col">
                  <p className={'form-panel-gray__text'}>{beforeLastYear}</p>
                </div>
                <div className="activity-col">
                  <p className={'form-panel-gray__text'}>{lastYear}</p>
                </div>
                <div className="activity-col">
                  <p className={'form-panel-gray__text'}>{currentYear}</p>
                </div>
                <div className="activity-col">
                  <p className={'form-panel-gray__text'}>{vocabulary.forms.division.tableLabels.controllable}</p>
                </div>
              </div>
            </Container>
          </div>

          {props.activities && props.activities.length > 0 ? (
            <>
              <div className="container">

                {props.activities.map((activity, index) => (
                  <div className={'activity-item'} key={index}>
                    <div className="row">
                      <div className="activity-col">
                        <TextareaField
                          className={'textarea-field_min_height_reduced'}
                          name={`Activities.${index}.title`}
                          placeholder={vocabulary.forms.activities.placeholders.activityDescription}
                          forDivision
                          currentYearFrozen={currentYearFrozen}
                        />
                      </div>
                      <div className="activity-col">
                        <TextareaField currentYearFrozen={currentYearFrozen} className={'textarea-field_min_height_reduced'} name={`Activities.${index}.beforeLastYear`} forDivision />
                      </div>
                      <div className="activity-col">
                        <TextareaField currentYearFrozen={currentYearFrozen} className={'textarea-field_min_height_reduced'} name={`Activities.${index}.lastYear`} forDivision />
                      </div>
                      <div className="activity-col">
                        <TextareaField currentYearFrozen={currentYearFrozen} className={'textarea-field_min_height_reduced'} name={`Activities.${index}.curYear`} forDivision />
                      </div>
                      <div className="activity-col">
                        <SelectField name={`Activities.${index}.controllable`} forDivision value={activity.controllable}>
                          <option value={Number('0')} label="Nein" />
                          <option value={Number('1')} label="Ja" />
                        </SelectField>
                        {
                          (currentYearFrozen ? matrix.permissions.freezeRemoveFieldsInside === true : matrix.permissions.removeFieldsInside === true) && (
                            <i
                              data-controlled-permissions={DivisionFormActivities.name}
                              className={'priorities__remove-icon'}
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <ConfirmationModal
                                        forDivision
                                        onClose={onClose}
                                        onOk={() => activitiesHelper.remove(index)}
                                      />
                                    );
                                  },
                                });
                              }}
                            />
                          )
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Container>
              <p className={'activity__message'}>Nicht gefunden: Aufgaben konnten nicht gefunden werden.</p>
            </Container>
          )}

          <Container>
            {
              (currentYearFrozen ? matrix.permissions.freezeAddFieldsInside === true : matrix.permissions.addFieldsInside === true) && (
                <CreateButton
                  text={vocabulary.buttons.addActivity}
                  onClick={() => {
                    activitiesHelper.push({
                      id: null,
                      title: '',
                      beforeLastYear: '',
                      lastYear: '',
                      curYear: '',
                      controllable: 1,
                      YearId: props.yearId
                    });
                  }}
                />
              )
            }
          </Container>
        </div>
      )}
    />
  );
}

export default DivisionFormActivities;
