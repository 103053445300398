import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../index';
import { handleActionResult } from '../action-helpers';
import { UserFormData } from '../../shared/interfaces/users.interface';
import UserService from '../../services/user.service';
import {
  USERS_GET_LIST_SUCCESS,
  USERS_GET_ONE_SUCCESS,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAILURE,
  USERS_DELETE_SUCCESS,
  REMOVE_USER_FROM_STORE,
} from './types';

const userService = new UserService();

const getUsers = (page: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(users) {
    return {
      type: USERS_GET_LIST_SUCCESS,
      users,
    };
  }

  const result = await userService.getUsers(page);
  return handleActionResult(result, () => dispatch(success(result)));
};

const getUser = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const success = (user) => {
    return {
      type: USERS_GET_ONE_SUCCESS,
      user,
    };
  }

  const result = await userService.getUser(id);
  return handleActionResult(result, () => dispatch(success(result)));
};

const inviteUser = (formData: UserFormData, page: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
    const success = (user) => {
      return {
        type: USERS_CREATE_SUCCESS,
        user,
      };
    }
    const failure = () => ({ 
      type: USERS_CREATE_FAILURE
    });

    try {
      const result = await userService.inviteUser(formData);
      dispatch(success(result));
      dispatch(getUsers(page));
    } catch (err) {
      dispatch(failure());
    }
    // return handleActionResult(result, () => {
    //   dispatch(success(result));
    //   dispatch(getUsers());
    // });
};

const deleteUser = (id: number, page: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const success = () => {
    return {
      type: USERS_DELETE_SUCCESS,
    };
  }

  const result = await userService.deleteUser(id);
  return handleActionResult(result, () => {
    dispatch(success());
    dispatch(getUsers(page));
  });
};

const removeUserFromStore = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: REMOVE_USER_FROM_STORE });
};

export {
  getUsers,
  getUser,
  inviteUser,
  deleteUser,
  removeUserFromStore,
};

