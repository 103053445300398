import React from "react";
import { DivisionsPageState } from "./divisions-page.interface";
import { PageHeader } from "../../template/page-header/page-header";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../store";
import { bindActionCreators } from "redux";
import { openModal } from "../../../store/modals/actions";
import { MODAL_DIVISION, MODAL_AGENCY } from "../../../shared/constants/modals";
import { Container } from "../../template/container/container";
import { getDivision, getDivisions } from "../../../store/divisions/actions";
import { clearSelectedPeople, getPeople } from "../../../store/people/actions";
import { vocabulary } from "../../../vocabulary/german";
import { getAgenciesWithDivisions } from "store/agencies/actions";
import { Spinner } from "components/template/spinner/spinner";
import { EntityPanel } from "components/panels/entity-panel/entity-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { Agency } from "shared/interfaces/agencies.interface";
import { getAgency, updateAgency, reorderAgencyDivisions } from "../../../store/agencies/actions";
import ReportService from "services/reports.service";
import events from '../../../shared/constants/events';
import { NotFoundMessage } from "components/template/not-found-message/not-found-message";
import { PermissionsContext } from "contexts";
import AgencyDivisionsListDraggable from "components/lists/agency-divisions-draggable-list/agency-divisions-draggable-list";

const mapStateToProps = (state: AppState) => ({
  divisions: state.divisions,
  agencies: state.agencies,
  isLoadingAgenciesList: state.agencies.isLoadingAgenciesList,
  currentYearFrozen: state.years.currentYearFrozen,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDivisions,
      getPeople,
      getDivision,
      clearSelectedPeople,
      openModal,
      getAgenciesWithDivisions,
      getAgency,
      updateAgency,

      reorderAgencyDivisions,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class DivisionsPage extends React.Component<PropsFromRedux, DivisionsPageState> {

  static contextType = PermissionsContext;
  state = { locked: false, permissions: this.context.permissions };
  unlock = (): void => { this.setState({ locked: false }) }

  componentDidMount(): void {
    this.props.getAgenciesWithDivisions();
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }


  handleSelect = (division): void => {
    if (division.id) {
      this.props.getDivision(division.id);
      this.props.openModal(MODAL_DIVISION);
    }

    if (division.AgencyId) {
      this.props.getAgency!(division.AgencyId);
    }
  };

  handleCreate = (): void => {
    this.props.openModal(MODAL_DIVISION);
    this.props.clearSelectedPeople();
  };


  handleReorderCurrentAgencyDivisions = ({ agencyId, divisionId, orderInAgencyDivisionsList }): void => {
    this.props.reorderAgencyDivisions({ agencyId, divisionId, orderInAgencyDivisionsList })
  }

  handleSelectAgency = (item: Agency) => {
    if (item && item.id) {
      this.props.getAgency!(item.id);
      this.props.openModal!(MODAL_AGENCY);
    }
  };

  downloadDivisionsReport = (type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadDivisionsReport(type);
  }

  renderCreateDivisionsBtnAgency = (item): JSX.Element | undefined => {
    if (this.state.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewDivision}
          onClick={() => this.handleSelectAgency(item)}
        />
      )
    }
  }

  renderEntityPanels() {
    if (this.props.isLoadingAgenciesList) return <Spinner isFullSize />;

    const NO_DATA = `Nicht gefunden: Ambitionen konnten nicht gefunden werden.`
    const agencies = Object.assign({ count: 0, rows: [] }, this.props.agencies)
    if (agencies.rows.length === 0) return <NotFoundMessage message={NO_DATA}/>

    return (
      <div className={"entities-list entities-list_priorities"}>
        {
          agencies.rows.map((item: Agency, index: number) => {
            const agency  = Object.assign({Divisions: []}, item);
            return (
              <div className={"row agencies-row"} key={index}>
                <div className={"col"}>
                  <EntityPanel isFixed title={agency.title} id={agency.id} />
                </div>
                <div className={"col"}>
                  <AgencyDivisionsListDraggable
                    agency={agency}
                    handleSelect={this.handleSelect}
                    handleSelectAgency={this.handleSelectAgency}
                    handleReorderCurrentAgencyDivisions={this.handleReorderCurrentAgencyDivisions}
                  />
                </div>
              </div>
            )
          }
          )
        }
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className={"page-content"}>
          <PageHeader 
            title={vocabulary.labels.divisions}
            downloadReport={this.downloadDivisionsReport}
            btnText={vocabulary.buttons.reports.downloadReportPdf} 
            btnTextDocx={vocabulary.buttons.reports.downloadReportDocx} 
          />
          <Container>
            {this.renderEntityPanels()}
          </Container>
        </div>
        {
          this.state.locked
          && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />
        }
      </React.Fragment>
    );
  }
}

export default connector(DivisionsPage);
