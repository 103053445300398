import React, { Component } from 'react';
import { Modal } from '../../template/modals/modal';
import { modalService } from '../../../services/modals.service';
import { ASSIGN_AGENCY_MODAL } from '../../../shared/constants/modals';
import { connect, ConnectedProps } from 'react-redux';
import AssignAgencyForm from '../../forms/assign-agency-form/assign-agency-form';
import { AssignAgencyModalState } from './assign-agency-modal.interface';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../../store/modals/actions';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, ASSIGN_AGENCY_MODAL);
  return { ...modal };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ closeModal }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignAgencyModal extends Component<PropsFromRedux, AssignAgencyModalState> {
  handleClose = () => {
    this.props.closeModal!(ASSIGN_AGENCY_MODAL);
  };

  render() {
    const { modal } = this.props;
    if (!modal.isOpen) return null;

    return (
      <Modal close={this.handleClose}>
        <AssignAgencyForm assignAgencies={modal.data.agencies} />
      </Modal>
    );
  }
}

export default connector(AssignAgencyModal);
