import React, { Component } from 'react';
import { ShortReportProps, ShortReportState } from './short-report-page.types';
import ReportDropDown from '../../../report-dropdown/report-dropdown';
import Dropdown from '../../../dropdown/dropdown';
import { PolicyReport } from './policy-report/policy-report';
import { AgencyReport } from './agency-report/agency-report';
import { DivisionReport } from './division-report/division-report';
import { Spinner } from '../../../template/spinner/spinner';
import AgenciesService from '../../../../services/agencies.service';
import PoliciesService from '../../../../services/policies.service';
import DivisionsService from '../../../../services/divisions.service';
import { vocabulary } from '../../../../vocabulary/german';
import { PermissionsContext } from 'contexts';
export default class ShortReportPage extends Component<ShortReportProps, ShortReportState> {

  static contextType = PermissionsContext;
  private agenciesService = new AgenciesService();
  private policiesService = new PoliciesService();
  private divisionsService = new DivisionsService();

  state = {
    policies: { data: [], loading: true } as any,
    agencies: { data: [], loading: true } as any,
    divisions: { data: [], loading: true } as any,
    permissions: this.context.permissions
  };

  componentDidMount(): void {
    this.policiesService.getPolicies().then((policies) => {
      this.setState({
        policies: {
          data: policies.rows,
          loading: false,
        },
      });
    });
    this.agenciesService.getAgencies().then((agencies) => {
      this.setState({
        agencies: {
          data: agencies.rows,
          loading: false,
        },
      });
    });
    this.divisionsService.getDivisions().then((divisions) => {
      this.setState({
        divisions: {
          data: divisions.rows,
          loading: false,
        },
      });
    });
  }

  render() {
    const { policies, agencies, divisions } = this.state;

    const renderPolicies = policies.data.map((policy) => (
      <ReportDropDown
        key={policy.id}
        item={policy}
        name={'policy'}
        renderDropdown={(entityData) => <PolicyReport entityData={entityData} />}
      />
    ));

    const renderAgencies = agencies.data.map((agency) => (
      <ReportDropDown
        key={agency.id}
        item={agency}
        name={'agency'}
        renderDropdown={(entityData) => <AgencyReport entityData={entityData} />}
      />
    ));

    const renderDivisions = divisions.data.map((division) => (
      <ReportDropDown
        key={division.id}
        item={division}
        name={'division'}
        renderDropdown={(entityData) => <DivisionReport entityData={entityData} />}
      />
    ));

    const isContentLoaded = !(policies.loading || agencies.loading || divisions.loading);

    return (
      <>
        <div className="short-report">
          {isContentLoaded ? (
            <>
              <Dropdown label={vocabulary.labels.policiesAreas}>{renderPolicies}</Dropdown>
              <Dropdown label={vocabulary.labels.agencies}>{renderAgencies}</Dropdown>
              <Dropdown label={vocabulary.labels.divisions}>{renderDivisions}</Dropdown>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </>
    );
  }
}
