import { Person } from '../shared/interfaces/people.interface';
import { ApiService } from './api.service';

export default class PeopleService extends ApiService {
  getPeople = async () => {
    return await this.get(`/peoples`);
  };

  getPerson = async (id: number) => {
    return await this.get(`/peoples/${id}`);
  };

  createPeople = async (formData: Person) => {
    return await this.post('/peoples/', { name: formData.name });
  };

  updatePeople = async (id: number, formData: Person) => {
    return await this.put(`/peoples/${id}`, { name: formData.name });
  };

  removePeople = async (id: number) => {
    return await this.remove(`/peoples/${id}`);
  };
}