import { ALERT_CLEAR, ALERT_ERROR, ALERT_SUCCESS } from './types';

export function alert(state = {}, action) {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        type: 'alert-success',
        ...action.message,
      };
    case ALERT_ERROR:
      return {
        type: 'alert-danger',
        ...action.message,
      };
    case ALERT_CLEAR:
      return {};
    default:
      return state;
  }
}
