import React, { Component } from 'react';
import { ASSIGN_PEOPLE_MODAL } from '../../../shared/constants/modals';
import { Modal } from '../../template/modals/modal';
import AssignPeopleForm from '../../forms/assign-people-form/assign-people-form';
import { modalService } from '../../../services/modals.service';
import { connect, ConnectedProps } from 'react-redux';
import { AssignPeopleModalState } from './assign-people-modal.interface';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, ASSIGN_PEOPLE_MODAL);
  return { ...modal };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ closeModal }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignPeopleModal extends Component<PropsFromRedux, AssignPeopleModalState> {
  handleClose = () => {
    this.props.closeModal!(ASSIGN_PEOPLE_MODAL);
  };

  render() {
    const { modal } = this.props;

    return (
      modal.isOpen && (
        <Modal close={this.handleClose} forDivision>
          <AssignPeopleForm forEntity={modal.data} />
        </Modal>
      )
    );
  }
}

export default connector(AssignPeopleModal);
