import React, { FC } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PoliciesListProps } from './policies-list.interface';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityPanel } from '../../panels/entity-panel/entity-panel';
import { updatePolicy } from '../../../store/policies/actions';
import { Policies } from '../../../shared/interfaces/policy.interface';
import * as utils from '../../../shared/utils';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePolicy,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export const PoliciesList: FC<PropsFromRedux & PoliciesListProps> = ({ policies, onSelect, updatePolicy }: PropsFromRedux & PoliciesListProps) => {

  const [state, setState] = React.useState<{ policies: Policies | null; }>({ policies: null })

  React.useState(() => {
    const setPolicies = () => {
      let canceled = false;
      const cancel = (() => { canceled = true });
      if (!canceled) setState({ policies })
      return cancel;
    }

    const cancel = setPolicies()
    return () => { cancel() }
  })


  const updateElementPositionInState = ({ sourceId, newPosition }: { sourceId: number | null | undefined, newPosition: number }) => {
    if (!sourceId) return;

    const oldPosition = (state.policies?.rows || []).findIndex(agency => agency.id === sourceId);
    (state.policies?.rows || []).splice(newPosition, 0, (state.policies?.rows || []).splice(oldPosition, 1)[0]);
    const updated: { policies: Policies; } = { ...state } as { policies: Policies; };
    setState(updated)
  }



  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) return;

    const sourceId = policies.rows[result.source.index].id
    const newPosition = result.destination.index;
    updateElementPositionInState({ newPosition, sourceId })

    utils.debounce(() => updatePolicy(sourceId, { order: result.destination.index }), 1000)
  }

  return (


    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="policies-list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {(state.policies?.rows || []).map((item, index) => (
              <Draggable draggableId={item.title} key={item.title} index={index}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={item.title}
                  >
                    <EntityPanel
                      onClick={() => onSelect(item)}
                      title={item.title}
                      id={item.id} />
                  </div>

                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>


  );
};

export default connector(PoliciesList);