import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { LeftMenuProps } from './left-menu.interface';
import { vocabulary } from '../../../vocabulary/german';

export const LeftMenu: FC<LeftMenuProps> = (props: LeftMenuProps) => (
  <div className={classNames('left-menu', { active: props.isOpen })}>
    <div className={'left-menu-content'}>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/">
        Home
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/policy-areas">
        {vocabulary.labels.policiesAreas}
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/priorities">
        {vocabulary.labels.priorities}
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/people">
        {vocabulary.labels.people}
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/agencies">
        {vocabulary.labels.agencies}
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/divisions">
        {vocabulary.labels.divisions}
      </Link>
      <Link className={'left-menu__link'} onClick={props.hideMenu} to="/reports">
        Reports
      </Link>
    </div>
    <div className={'left-menu-backdrop'} onClick={props.bgClick} />
  </div>
);
