import React, { Component } from 'react';
import classNames from 'classnames';
import { DropDownProps, DropDownState } from './dropdown.interface';

export default class Dropdown extends Component<DropDownProps, DropDownState> {
  state = {
    isExpanded: false,
  };

  changeState = () => {
    this.setState((state) => {
      return {
        isExpanded: !state.isExpanded,
      };
    });
  };

  render() {
    const { isExpanded } = this.state;
    const { label, children } = this.props;

    return (
      <div className="dropdown">
        <div className="dropdown-toggle" onClick={this.changeState}>
          <p className={'dropdown-toggle__name'}>
            <i className={classNames('dropdown-toggle__icon', isExpanded && 'is-active')} />
            {label}
          </p>
        </div>
        <div className={classNames('dropdown-content', isExpanded && 'is-expanded')}>{children}</div>
      </div>
    );
  }
}
