export const CREATE_YEAR_REQUEST = 'years/CREATE_YEAR_REQUEST';
export const CREATE_YEAR_SUCCESS = 'years/CREATE_YEAR_SUCCESS';
export const CREATE_YEAR_FAILURE = 'years/CREATE_YEAR_FAILURE';

export const GET_YEARS_REQUEST = 'years/GET_YEARS_REQUEST';
export const GET_YEARS_SUCCESS = 'years/GET_YEARS_SUCCESS';
export const GET_YEARS_FAILURE = 'years/GET_YEARS_FAILURE';

export const UPDATE_YEAR_REQUEST = 'years/UPDATE_YEAR_REQUEST';
export const UPDATE_YEAR_SUCCESS = 'years/UPDATE_YEAR_SUCCESS';
export const UPDATE_YEAR_FAILURE = 'years/UPDATE_YEAR_FAILURE';

export const REMOVE_YEAR_REQUEST = 'years/REMOVE_YEAR_REQUEST';
export const REMOVE_YEAR_SUCCESS = 'years/REMOVE_YEAR_SUCCESS';
export const REMOVE_YEAR_FAILURE = 'years/REMOVE_YEAR_FAILURE';

export const SET_CURRENT_YEAR_ID = 'years/SET_CURRENT_YEAR_ID';
export const CLEAR_CURRENT_YEAR_DATA = 'years/CLEAR_CURRENT_YEAR_DATA';
