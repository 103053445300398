import React from "react";
import { EntityPanel } from "components/panels/entity-panel/entity-panel";
import { NotFoundMessage } from "components/template/not-found-message/not-found-message";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAgency } from '../../../store/agencies/actions';
import { CreateButton } from "../../buttons/create-button/create-button";
import { usePermissions } from '../../../contexts/permissions.context';
import { vocabulary } from "../../../vocabulary/german";
import { AgencyDivisionsDraggableListProps, ReorderParams } from "./agency-divisions-draggable-list.interface";
import { Division } from "shared/interfaces/divisions.interface";

export const AgencyDivisionsListDraggable: React.FC<AgencyDivisionsDraggableListProps> = (({ agency, ...rest }) => {

  const matrix = usePermissions()
  const [state, setState] = React.useState<{ divisions: Division[] | [] }>({ divisions: agency.Divisions });

  const byDivisionOrder = (a, b) => a.orderInAgencyDivisionsList - b.orderInAgencyDivisionsList;

  const updateState = (newState) => {
    let canceled = false;
    const cancel = (() => { canceled = true; })

    Promise.resolve().then(() => canceled || setState(newState))

    return cancel;
  }


  React.useEffect(() => {
    const cancel = updateState({ divisions: agency.Divisions })
    return () => { cancel(); }
  }, [agency.Divisions])


  const renderCreateDivisionsBtnAgency = (item): JSX.Element | undefined => {
    if (matrix.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewDivision}
          onClick={() => rest.handleSelectAgency(item)}
        />
      )
    }
  }

  const updateElementPositionInState = ({ sourceId, newPosition }: { sourceId: number | null | undefined, newPosition: number }) => {
    const cloned = JSON.parse(JSON.stringify(state.divisions));
    const oldPosition = cloned.findIndex(d => d.id === sourceId);
    cloned.splice(newPosition, 0, cloned.splice(oldPosition, 1)[0]);
    const success = cloned.map((i, index) => { i.orderInAgencyDivisionsList = index; return i })
    const updatedState = {...state, divisions:success };
    updateState(updatedState)
  }

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    const division = (state.divisions || [])[result.source.index];
    const sourceId = division.id;
    const newPosition = result.destination.index;
    const params = { agencyId: agency.id, divisionId: sourceId, orderInAgencyDivisionsList: newPosition } as ReorderParams;
    rest.handleReorderCurrentAgencyDivisions(params)
    updateElementPositionInState({ newPosition, sourceId })
  }


  if (state.divisions.length === 0) {
    return (
      <React.Fragment>
        <NotFoundMessage
          message={`Nicht gefunden: Ambitionen konnten nicht gefunden werden`}
        />
        {renderCreateDivisionsBtnAgency(agency)}
      </React.Fragment>
    )
  }

  const divisions = (state.divisions || []).sort(byDivisionOrder) as Array<Division>

  return (
    <React.Fragment>
      <div className={`agency-divisions-draggable-list-container`}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="agency-divisions-list">
            {provided => (
              <div className={"entities-list"} ref={provided.innerRef} {...provided.droppableProps}>
                {divisions.map((division, divisionIndex) => (
                  <Draggable draggableId={division.title} key={division.title} index={divisionIndex}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={division.title}
                      >
                        <EntityPanel
                          key={divisionIndex}
                          onClick={() => rest.handleSelect(division)}
                          title={division.title}
                          id={division.id}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {renderCreateDivisionsBtnAgency(agency)}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </React.Fragment>
  )

})


const mapDispatchToProps = (dispatch) => bindActionCreators({ updateAgency, }, dispatch,);
export default connect(null, mapDispatchToProps,)(AgencyDivisionsListDraggable);
