/* eslint-disable */
import React, { FC } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EntityPanel } from "../../panels/entity-panel/entity-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { AgenciesListProps } from "./agencies-list.interface";
import { vocabulary } from "../../../vocabulary/german";
import { updateAgency } from '../../../store/agencies/actions';
import { Agencies } from '../../../shared/interfaces/agencies.interface';
import * as utils from '../../../shared/utils';
import { usePermissions } from '../../../contexts/permissions.context';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAgency,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export const AgenciesList: FC<PropsFromRedux & AgenciesListProps> = ({
  agencies,
  selectable,
  onSelect,
  onCreate,
  assignAgencies,
  updateAgency,
}: PropsFromRedux & AgenciesListProps) => {

  const matrix = usePermissions()
  const [state, setState] = React.useState<{ agencies: Agencies | null; }>({ agencies: null })


  React.useState(() => {
    const setAgencies = () => {
      let canceled = false;
      const cancel = (() => { canceled = true });
      if (!canceled) setState({ agencies })
      return cancel;
    }

    const cancel = setAgencies()
    return () => { cancel() }
  })


  const createAgencyButton = (): JSX.Element | undefined => {
    if (matrix.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewAgency}
          onClick={onCreate}
        />
      )
    }
  }


  const isAssign = (id: number | null) => {
    if (assignAgencies) {
      return assignAgencies.some((x: { id: number | null }) => x.id === id);
    }
  }


  const updateElementPositionInState = ({ sourceId, newPosition }: { sourceId: number | null | undefined, newPosition: number }) => {
    const oldPosition = (state.agencies?.rows || []).findIndex(agency => agency.id === sourceId);
    (state.agencies?.rows || []).splice(newPosition, 0, (state.agencies?.rows || []).splice(oldPosition, 1)[0]);
    const updated: { agencies: Agencies; } = { ...state } as { agencies: Agencies; };
    setState(updated)
  }

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    const sourceId = state.agencies?.rows[result.source.index].id;
    const newPosition = result.destination.index;
    updateElementPositionInState({ newPosition, sourceId })
    utils.debounce(() => updateAgency(sourceId, { order: result.destination.index }), 1000)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="agencies-list">
        {provided => (
          <div className={"entities-list"} ref={provided.innerRef} {...provided.droppableProps}>
            {(state.agencies?.rows || []).map((agency, index) => (
              <Draggable draggableId={agency.title} key={agency.title} index={index}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={agency.title}
                  >
                    <EntityPanel
                      key={index}
                      isSelectable={selectable}
                      onClick={() => onSelect(agency)}
                      title={agency.title}
                      id={agency.id}
                      isDisabled={isAssign(agency.id)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {onCreate && createAgencyButton()}
          </div>
        )}

      </Droppable>
    </DragDropContext>
  );
};

export default connector(AgenciesList);