import React, { Component } from "react";
import { FieldArray } from "formik";
import { EntityPanel } from "../../../panels/entity-panel/entity-panel";
import { CreateButton } from "../../../buttons/create-button/create-button";
import { connect, ConnectedProps } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { ConfirmationModal } from "../../../template/modals/confirmation-modal";
import { MidTermGoalsFormProps, MidTermGoalsFormState } from "./mtg-form.interface";
import { AppState } from "../../../../store";
import { bindActionCreators } from "redux";
import { setSelectedAgencies } from "../../../../store/agencies/actions";
import { TextareaField } from "../../../fields/textarea-field/textarea-field";
import { vocabulary } from "../../../../vocabulary/german";
import * as utils from '../../../../shared/utils'
import { PermissionsContext } from "contexts";

const mapStateToProps = (state: AppState) => ({ agencies: state.agencies });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSelectedAgencies }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class MidTermGoalsForm extends Component<PropsFromRedux & MidTermGoalsFormProps, MidTermGoalsFormState> {

  static contextType = PermissionsContext;
  state = { permissions: this.context.permissions }

  getAgencyName = (id: number): string => {
    const { agencies } = this.props;
    const agency = agencies && agencies.rows.find((agency) => agency.id === id);
    return agency && agency.title ? agency.title : "";
  };

  haveAgenciesSelected = (priorityIndex, midTermGoalIndex) => {
    const { agencies } = this.props;
    if (agencies && agencies.assigned) {
      return agencies.assigned.find(
        (item) =>
          item.assignedTo.priorityIndex === priorityIndex &&
          item.assignedTo.midTermGoalIndex === midTermGoalIndex
      );
    }
  };

  handleRemoveSelectedAssigning = (
    priorityIndex,
    midTermGoalIndex,
    selectedAgencyId
  ) => {
    const { agencies } = this.props;

    let newSelectedAgencies: Array<number> = [];

    if (agencies && agencies.assigned) {
      for (const agency of agencies.assigned) {
        if (
          agency.assignedTo.priorityIndex === priorityIndex &&
          agency.assignedTo.midTermGoalIndex === midTermGoalIndex
        ) {
          newSelectedAgencies = agency.selectedAgencies;
        }
      }

      for (const agencyIndex in newSelectedAgencies) {
        if (newSelectedAgencies[agencyIndex] === selectedAgencyId) {
          newSelectedAgencies.splice(Number(agencyIndex), 1);
        }
      }

      const selectedAgencies = {
        assignedTo: {
          priorityIndex: priorityIndex,
          midTermGoalIndex: midTermGoalIndex,
        },
        selectedAgencies: newSelectedAgencies,
      };

      if (this.props.setSelectedAgencies) {
        this.props.setSelectedAgencies(selectedAgencies);
      }
    }
  };

  removeSelectedAssigning = (
    priorityIndex,
    midTermGoalIndex,
    selectedAgencyId
  ) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() =>
              this.handleRemoveSelectedAssigning(
                priorityIndex,
                midTermGoalIndex,
                selectedAgencyId
              )
            }
          />
        );
      },
    });
  };

  removeAssignedAgency = (agencyIndex, agenciesHelper) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() => agenciesHelper.remove(agencyIndex)}
          />
        );
      },
    });
  };

  removeMidTermGoal = (MidtermGoalsHelper, midTermGoalIndex, priorityIndex) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() => {
              MidtermGoalsHelper.remove(midTermGoalIndex);
              this.props.clearSelectedAgency!(priorityIndex, midTermGoalIndex);
            }}
          />
        );
      },
    });
  };

  render() {
    const {
      priorityIndex,
      priority,
      handleAssignAgencies,
      agencies,
    } = this.props;

    const isDeletable = this.state.permissions.removeFieldsInside === true;

    return (
      <FieldArray
        name={`Priorities.${priorityIndex}.MidtermGoals`}
        render={(MidtermGoalsHelper) => (
          <div className={"mid-term-goals"}>
            {priority.MidtermGoals && priority.MidtermGoals.length > 0 ? (
              priority.MidtermGoals.map((midTermGoal, midTermGoalIndex) => (
                <div className={"mid-term-goals-item"} key={midTermGoalIndex}>
                  <div className={"mid-term-goals-item-content"}>
                    {
                      (this.state.permissions.removeFieldsInside === true) && (
                        <button
                          data-controlled-permissions={MidTermGoalsForm.name}
                          className={"mid-term-goals-item__remove-icon"}
                          onClick={() =>
                            this.removeMidTermGoal(
                              MidtermGoalsHelper,
                              midTermGoalIndex,
                              priorityIndex
                            )
                          }
                        />
                      )
                    }
                    <div className={"form-area-group"}>
                      <TextareaField
                        wrapperClass={"percent-30"}
                        name={`Priorities.${priorityIndex}.MidtermGoals.${midTermGoalIndex}.title`}
                        placeholder={
                          vocabulary.forms.policy.placeholders.newMtgTitle
                        }
                      />
                      <TextareaField
                        wrapperClass={"percent-70"}
                        name={`Priorities.${priorityIndex}.MidtermGoals.${midTermGoalIndex}.description`}
                        placeholder={
                          vocabulary.forms.policy.placeholders.newMtgDescr
                        }
                      />
                    </div>
                  </div>
                  <div className={"agencies"}>
                    <div className={"agencies-content"}>
                      <FieldArray
                        name={`Priorities.${priorityIndex}.MidtermGoals.${midTermGoalIndex}.Agencies`}
                        render={(agenciesHelper) => (
                          <div className={"agencies-list-wrapper"}>
                            {agencies &&
                              (this.haveAgenciesSelected(
                                priorityIndex,
                                midTermGoalIndex
                              ) ? (
                                <div className={"agencies-list"}>
                                  {agencies.assigned.map((item) => {
                                    if (
                                      item.assignedTo.priorityIndex ===
                                      priorityIndex &&
                                      item.assignedTo.midTermGoalIndex ===
                                      midTermGoalIndex
                                    ) {
                                      return item.selectedAgencies.map(
                                        (selectedAgencyId) => {
                                          return (
                                              <EntityPanel
                                                isDeletable={isDeletable}
                                                onDelete={() =>
                                                  this.removeSelectedAssigning(
                                                    priorityIndex,
                                                    midTermGoalIndex,
                                                    selectedAgencyId
                                                  )
                                                }
                                                isSmall={true}
                                                id={selectedAgencyId}
                                                title={this.getAgencyName(
                                                  selectedAgencyId
                                                )}
                                                key={selectedAgencyId}
                                              />
                                          );
                                        }
                                      );
                                    } else {
                                      return "";
                                    }
                                  })}
                                </div>
                              ) : midTermGoal.Agencies &&
                                midTermGoal.Agencies.length > 0 ? (
                                midTermGoal.Agencies.map(
                                  (agency, agencyIndex) => (
                                    <div
                                      className={"agencies-list"}
                                      key={agencyIndex}
                                    >
                                      <EntityPanel
                                        isDeletable={isDeletable}
                                        onDelete={() =>
                                          this.removeAssignedAgency(
                                            agencyIndex,
                                            agenciesHelper
                                          )
                                        }
                                        isSmall={true}
                                        id={agency.id}
                                        title={agency.title}
                                      />
                                    </div>
                                  )
                                )
                              ) : (
                                <></>
                              ))}
                            {
                              (this.state.permissions.assign === true) && (
                                <CreateButton
                                  text={vocabulary.buttons.assignAgencies}
                                  isSmall
                                  noWrapper
                                  onClick={() => {
                                    handleAssignAgencies({
                                      priorityIndex: priorityIndex,
                                      midTermGoalIndex: midTermGoalIndex,
                                      agencies: midTermGoal.Agencies,
                                    });
                                  }}
                                />
                              )
                            }
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
            <>
              {
                (this.state.permissions.addFieldsInside === true) && (
                  <CreateButton
                    noWrapper
                    text={vocabulary.buttons.addMidTermGoal}
                    onClick={() => {
                      MidtermGoalsHelper.push({
                        YearId: utils.getCurrentYearIdFromLS(),
                        title: "",
                        description: "",
                      });
                    }}
                  />
                )
              }
            </>
          </div>
        )}
      />
    );
  }
}

export default connector(MidTermGoalsForm);
