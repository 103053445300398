import React, { FC } from 'react';

import { ShortTermGoalsProps } from './division-form-stg.interface';
import { FieldArray } from 'formik';
import { CreateButton } from '../../../buttons/create-button/create-button';
import { vocabulary } from '../../../../vocabulary/german';
import * as utils from '../../../../shared/utils'

import DivisionFormStgItem from './division-form-stg-item';
import { usePermissions } from '../../../../contexts/permissions.context';

export const DivisionFormStg: FC<ShortTermGoalsProps> = ({ values, midTermGoal, yearId, currentYearFrozen = false }: ShortTermGoalsProps) => {
  const matrix = usePermissions()
  const onAddShortTermGoal = (shortTermGoalsHelper) => () => {
    shortTermGoalsHelper.push({
      MidtermGoalId: midTermGoal.id,
      title: '',
      outcome: '',
      Actions: [],
      reason: '',
      isShowReason: false,
      YearId: yearId
    });
  };

  const permissionsCanChange = utils.notChangeGoal();
  return (
    <FieldArray
      name={'ShortTermGoals'}
      render={(shortTermGoalsHelper) => {
        const hasGoals = values.ShortTermGoals && values.ShortTermGoals.length > 0;

        return (
          <>
            {hasGoals ? (
              <>
                {values.ShortTermGoals.map(
                  (shortTermGoal, shortTermGoalIndex) =>
                    shortTermGoal.MidtermGoalId === midTermGoal.id && (
                      <DivisionFormStgItem
                        key={shortTermGoalIndex}
                        shortTermGoal={shortTermGoal}
                        shortTermGoalIndex={shortTermGoalIndex}
                        shortTermGoalsHelper={shortTermGoalsHelper}
                        currentYearFrozen={currentYearFrozen}
                      />
                    ),
                )}
                {
                  ((currentYearFrozen ? matrix.permissions.freezeAddFieldsInside === true : matrix.permissions.addFieldsInside === true)
                    && !permissionsCanChange
                  ) && (
                    <CreateButton
                      text={vocabulary.buttons.addShortTermGoal}
                      onClick={onAddShortTermGoal(shortTermGoalsHelper)}
                    />
                  )
                }
              </>
            ) : (
              <>
                {
                  ((currentYearFrozen ? matrix.permissions.freezeAddFieldsInside === true : matrix.permissions.addFieldsInside === true)
                    && !permissionsCanChange
                  ) && (
                    <CreateButton
                      text={vocabulary.buttons.addShortTermGoal}
                      onClick={onAddShortTermGoal(shortTermGoalsHelper)}
                    />
                  )
                }
              </>
            )}
          </>
        )
      }}
    />
  );
};
