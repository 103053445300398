import React, { Component } from 'react';
import { DivisionModalState } from './division-modal.interface';
import { Modal } from '../../template/modals/modal';
import { MODAL_DIVISION } from '../../../shared/constants/modals';
import DivisionForm from '../../forms/division-form/division-form';
import { AppState } from '../../../store';
import { modalService } from '../../../services/modals.service';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../../store/modals/actions';
import { connect, ConnectedProps } from 'react-redux';
import { removeDivisionFromStore, getDivisions } from '../../../store/divisions/actions';
import { clearSelectedPeople } from '../../../store/people/actions';
import { getAgenciesWithDivisions } from 'store/agencies/actions';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_DIVISION);
  const { isDirty } = state.formIsDirty;
  return { ...modal, isDirty, isPending: state.divisions.isUpdateOrCreatePending };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      removeDivisionFromStore,
      clearSelectedPeople,
      getDivisions,
      getAgenciesWithDivisions,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class DivisionModal extends Component<PropsFromRedux, DivisionModalState> {
  handleClose = () => {
    if (this.props.isPending) return;

    this.props.closeModal!(MODAL_DIVISION);
    this.props.removeDivisionFromStore!();
    this.props.getAgenciesWithDivisions();
  };

  render() {
    const { modal, isDirty, isPending } = this.props;
    return (
      modal.isOpen && (
        <Modal formIsDirty={isDirty} close={this.handleClose} canClose={!isPending} forDivision>
          <DivisionForm />
        </Modal>
      )
    );
  }
}

export default connector(DivisionModal);
