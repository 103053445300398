import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from '../../template/modals/confirmation-modal';
import { connect, ConnectedProps } from 'react-redux';
import { ASSIGN_AGENCY_MODAL, MODAL_POLICY } from '../../../shared/constants/modals';
import { PrioritiesForm } from './priorities-form/priorities-form';
import { Button } from '../../buttons/button/button';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { FormHeader } from '../../template/form-header/FormHeader';
import { modalService } from '../../../services/modals.service';
import { PolicyFormState } from './policy-form.interface';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../store/modals/actions';
import { FixedHeader } from '../../template/fixed-header/fixed-header';
import { FormBody } from '../../template/form-body/form-body';
import { Container } from '../../template/container/container';
import * as utils from '../../../shared/utils';
import { InputField } from '../../fields/input-field/input-field';
import { createPolicy, removePolicy, updatePolicy } from '../../../store/policies/actions';
import { clearSelectedAgencies, clearSelectedAgency } from '../../../store/agencies/actions';
import { TextareaField } from '../../fields/textarea-field/textarea-field';
import { vocabulary } from '../../../vocabulary/german';
import CheckIfFormIsDirty from 'shared/utils/checkIfFormIsDirty';
import { Spinner } from '../../template/spinner/spinner';
import ReportService from 'services/reports.service';
import events from '../../../shared/constants/events'
import { PermissionsContext } from '../../../contexts/permissions.context';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, ASSIGN_AGENCY_MODAL);
  const isPending = state.policies.isUpdatePending || state.policies.isGetPending;

  return {
    currentYearFrozen: state.years.currentYearFrozen,
    policies: state.policies,
    modals: state.modals,
    agencies: state.agencies,
    isPending,
    ...modal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      openModal,
      removePolicy,
      createPolicy,
      updatePolicy,
      clearSelectedAgency,
      clearSelectedAgencies,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export class PolicyForm extends Component<PropsFromRedux, PolicyFormState> {
  static contextType = PermissionsContext;

  state = {
    locked: false,
    isDirty: false,
    permissions: this.context.permissions
  }

  unlock = (): void => { this.setState({ locked: false }); }

  componentDidMount(): void {
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  handleSubmitDelete = (id: number) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationModal onClose={onClose} onOk={() => this.handleDelete(id)} />;
      },
    });
  };

  downloadShortPolicyReportByPolicyID = (policyId, type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadShortPolicyReportByPolicyID({ policyId, type });
  };

  handleDelete = (id: number) => {
    this.props.removePolicy!(id);
    this.props.clearSelectedAgencies!();
    this.props.closeModal!(MODAL_POLICY);
  };

  clearSelectedAgency = (priorityIndex: number, mtgIndex: number) => {
    this.props.clearSelectedAgency!(priorityIndex, mtgIndex);
  };

  savePolicy = (values) => {
    const { agencies } = this.props;
    const newValues = values;

    newValues.Priorities &&
      newValues.Priorities.forEach((priority, priorityIndex) => {
        priority.MidtermGoals.forEach((midTermGoal, midTermGoalIndex) => {
          if (midTermGoal.Agencies) {
            midTermGoal.Agencies.forEach((agency, agencyIndex) => {
              if (agency.id) {
                midTermGoal.Agencies[agencyIndex] = agency.id;
              }
            });
          }

          if (agencies) {
            for (const obj of agencies.assigned) {
              if (
                obj.assignedTo.priorityIndex === priorityIndex &&
                obj.assignedTo.midTermGoalIndex === midTermGoalIndex
              ) {
                newValues.Priorities[priorityIndex].MidtermGoals[midTermGoalIndex].Agencies = obj.selectedAgencies;
              }
            }
          }
        });
      });

    if (newValues.id) {
      // when we UPDATE the Politikbereiche behavior
      this.props.updatePolicy!(newValues.id, newValues);
    } else {
      // when we CREATE the Politikbereiche behavior
      this.props.createPolicy!(newValues);
    }

    this.props.clearSelectedAgencies!();
  };

  handleAssignAgencies = (assignedToData) => {
    this.props.openModal!(ASSIGN_AGENCY_MODAL, assignedToData);
  };

  renderDeleteButton = (id): JSX.Element | undefined => {
    if (this.state.permissions.remove === true && !this.props.currentYearFrozen) {
      return (
        <Button
          className={'delete-button'}
          onClick={() => this.handleSubmitDelete(id)}
          text={vocabulary.buttons.delete}
        />
      )
    }
  }

  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.remove === true && !this.props.currentYearFrozen) {
      return <Button type={'submit'} text={vocabulary.buttons.save} id='saveBtn' />
    }
  }

  renderReportsButton = (id, type): JSX.Element | undefined => {
    if (this.state.permissions.printReport === true) {
      return (
        <Button
          onClick={() => this.downloadShortPolicyReportByPolicyID(id, type)}
          text={type === 'pdf' ? vocabulary.buttons.reports.downloadReportPdf : vocabulary.buttons.reports.downloadReportDocx }
        />
      )
    }
  }



  render() {
    const { policies, isPending } = this.props;

    const initialValues = {
      id: null,
      title: '',
      guidingPrincipleDescription: '',
      Priorities: [],
    };

    if (policies && policies.current) {
      initialValues.id = policies.current.id ? policies.current.id : null;
      initialValues.title = policies.current.title ? policies.current.title : '';
      initialValues.guidingPrincipleDescription = policies.current.guidingPrincipleDescription
        ? policies.current.guidingPrincipleDescription
        : '';
      initialValues.Priorities = policies.current.Priorities ? policies.current.Priorities : [];
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.savePolicy}
      >
        {({ errors, touched, values, dirty }) => (
          <Form>
            <CheckIfFormIsDirty dirty={dirty} />
            <FixedHeader>
              <FormHeader
                renderSave={this.renderSaveButton()}
                renderDelete={this.renderDeleteButton(initialValues.id)}
                renderReport={this.renderReportsButton(initialValues.id, 'pdf')}
                renderDocxReport={this.renderReportsButton(initialValues.id, 'docx')}
              />
              <Container>
                <div className={'field-wrapper'}>
                  <InputField
                    name={'title'}
                    className={'form__field_big'}
                    placeholder={vocabulary.forms.policy.placeholders.newPolicyArea}
                    validate={utils.validateField}
                  />
                  {errors.title && touched.title && <p className={'field-error'}>{errors.title}</p>}
                </div>
              </Container>
            </FixedHeader>

            <FormBody>
              <GrayPanel text={vocabulary.forms.policy.description} first />

              <Container>
                <div className={'field-wrapper'}>
                  <TextareaField
                    name="guidingPrincipleDescription"
                    validate={utils.validateField}
                    className={'form__field_textarea_policy'}
                    placeholder={vocabulary.forms.policy.placeholders.newGuidingPriniciple}
                  />
                  {errors.guidingPrincipleDescription && touched.guidingPrincipleDescription && (
                    <p className={'field-error'}>{errors.guidingPrincipleDescription}</p>
                  )}
                </div>
              </Container>

              <PrioritiesForm
                values={values}
                handleAssignAgencies={this.handleAssignAgencies}
                clearSelectedAgency={this.clearSelectedAgency}
              />
            </FormBody>
            { isPending && <Spinner isFullSize />}
            {this.state.locked && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />}
          </Form>
        )}
      </Formik>
    );
  }
}

export default connector(PolicyForm);
