import { Agencies, Agency } from '../../shared/interfaces/agencies.interface';

export const AGENCIES_GETALL_REQUEST = 'AGENCIES_GETALL_REQUEST';
export const AGENCIES_GETALL_SUCCESS = 'AGENCIES_GETALL_SUCCESS';
export const AGENCIES_GETALL_FAILURE = 'AGENCIES_GETALL_FAILURE';

export const AGENCIES_CREATE_REQUEST = 'AGENCIES_CREATE_REQUEST';
export const AGENCIES_CREATE_SUCCESS = 'AGENCIES_CREATE_SUCCESS';
export const AGENCIES_CREATE_FAILURE = 'AGENCIES_CREATE_FAILURE';

export const AGENCIES_UPDATE_REQUEST = 'AGENCIES_UPDATE_REQUEST';
export const AGENCIES_UPDATE_SUCCESS = 'AGENCIES_UPDATE_SUCCESS';
export const AGENCIES_UPDATE_FAILURE = 'AGENCIES_UPDATE_FAILURE7';

export const AGENCIES_REMOVE_REQUEST = 'AGENCIES_REMOVE_REQUEST';
export const AGENCIES_REMOVE_SUCCESS = 'AGENCIES_REMOVE_SUCCESS';
export const AGENCIES_REMOVE_FAILURE = 'AGENCIES_REMOVE_FAILURE';

export const AGENCY_GET_REQUEST = 'AGENCY_GET_REQUEST';
export const AGENCY_GET_SUCCESS = 'AGENCY_GET_SUCCESS';
export const AGENCY_GET_FAILURE = 'AGENCY_GET_FAILURE';

export const SELECT_AGENCIES = 'SELECT_AGENCIES';
export const CLEAR_SELECTED_AGENCIES = 'CLEAR_SELECTED_AGENCIES';
export const CLEAR_SELECTED_AGENCY = 'CLEAR_SELECTED_AGENCY';

export const REMOVE_AGENCY_FROM_STORE = 'REMOVE_AGENCY_FROM_STORE';

export const CLEAR_AGENCY_ID_FROM_DIVISION = 'CLEAR_AGENCY_ID_FROM_DIVISION';

interface AgenciesGetAllRequestAction {
  type: typeof AGENCIES_GETALL_REQUEST;
}

interface AgenciesGetAllSuccessAction {
  type: typeof AGENCIES_GETALL_SUCCESS;
  agencies: Agencies;
}

interface AgenciesGetAllFailureAction {
  type: typeof AGENCIES_GETALL_FAILURE;
  error: {
    message: string;
  };
}

interface AgencyGetRequestAction {
  type: typeof AGENCY_GET_REQUEST;
}

interface AgencyGetSuccessAction {
  type: typeof AGENCY_GET_SUCCESS;
  agency: Agency;
}

interface AgencyGetFailureAction {
  type: typeof AGENCY_GET_FAILURE;
  error: {
    message: string;
  };
}

interface SelectAgenciesAction {
  type: typeof SELECT_AGENCIES;
  data: any;
}

interface RemoveAgencyFromStoreAction {
  type: typeof REMOVE_AGENCY_FROM_STORE;
}

interface ClearSelectedAgenciesAction {
  type: typeof CLEAR_SELECTED_AGENCIES;
}

interface ClearSelectedAgencyAction {
  type: typeof CLEAR_SELECTED_AGENCY;
  data: any;
}

interface UpdateAgenciesRequest {
  type: typeof AGENCIES_UPDATE_REQUEST;
}
interface UpdateAgenciesSuccess {
  type: typeof AGENCIES_UPDATE_SUCCESS;
  agency: any;
}
interface UpdateAgenciesFailure {
  type: typeof AGENCIES_UPDATE_FAILURE;
}
interface ClearAgencyIdFromDivision {
  type: typeof CLEAR_AGENCY_ID_FROM_DIVISION;
  payload: { id: number };
}

export type AgenciesActionTypes =
  | AgenciesGetAllRequestAction
  | AgenciesGetAllSuccessAction
  | AgenciesGetAllFailureAction
  | AgencyGetRequestAction
  | AgencyGetSuccessAction
  | AgencyGetFailureAction
  | RemoveAgencyFromStoreAction
  | SelectAgenciesAction
  | ClearSelectedAgenciesAction
  | ClearSelectedAgencyAction
  | UpdateAgenciesRequest
  | UpdateAgenciesSuccess
  | UpdateAgenciesFailure
  | ClearAgencyIdFromDivision;
