import React, { Component } from 'react';
import classNames from 'classnames';
import { ReportDropDownProps, ReportDropDownState } from './report-dropdown.interface';
import ReportService from '../../services/reports.service';
import { Spinner } from '../template/spinner/spinner';

export default class ReportDropDown extends Component<ReportDropDownProps, ReportDropDownState> {
  state = {
    isExpanded: false,
    entityData: {} as any,
    loading: true,
  };

  private reportService = new ReportService();

  handleClick = (id: number, entityName: string) => {
    return this.reportService.getShortReport(id, entityName).then((res) => {
      return res;
    });
  };

  changeState = () => {
    const { id } = this.props.item;
    const { name } = this.props;
    const { isExpanded } = this.state;

    this.setState((state) => {
      return {
        isExpanded: !state.isExpanded,
      };
    });

    if (!isExpanded && id && name) {
      this.handleClick(id, name).then((res) => {
        this.setState({
          entityData: res,
          loading: false,
        });
      });
    }
  };

  renderReport = (): JSX.Element => {
    const { renderDropdown } = this.props;
    const { entityData } = this.state;

    return renderDropdown(entityData);
  };

  render() {
    const { isExpanded, loading } = this.state;
    const { children } = this.props;
    const label = this.props.item.title;

    return (
      <div className="dropdown">
        <div className="dropdown-toggle" onClick={this.changeState}>
          <p className={'dropdown-toggle__name'}>
            <i className={classNames('dropdown-toggle__icon', isExpanded && 'is-active')} />
            {label}
          </p>
        </div>
        <div className={classNames('dropdown-content', isExpanded && 'is-expanded')}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {this.renderReport()}
              {children}
            </>
          )}
        </div>
      </div>
    );
  }
}
