import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { PasswordField } from '../../fields/password-field/password-field';
import { InputField } from '../../fields/input-field/input-field';
import { LoginFormProps } from './login-form.interface';
import { Form, Formik } from 'formik';
import * as utils from '../../../shared/utils'
import { Button } from '../../buttons/button/button';
import BaarLogoTransparentPNG from '../../../assets/images/baar_logo_transparent.png'

export const LoginForm: FC<LoginFormProps> = ({ alert, onSubmit }: LoginFormProps) => {
  const initialValues = {
    username: '',
    password: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)}>
      {({ errors, touched }) => (
        <Form className="form login-form">
          <img className="baar-logo" src={BaarLogoTransparentPNG} alt="logo" />
          <div className="form-group">
            <label className="form-label">Username</label>
            <InputField name="username" validate={utils.validateField} isAuth />
            {errors.username && touched.username && <p className={'field-error'}>{errors.username}</p>}
          </div>

          <div className="form-group">
            <label className="form-label">Password</label>
            <PasswordField name="password" validate={utils.validateField} />
            {errors.password && touched.password && <p className={'field-error'}>{errors.password}</p>}
          </div>

          {alert.description && <p className={`field-error ${alert.type}`}>{alert.description}!</p>}

          <div className={'login-form-footer'}>
            <div className="reset-password">
              <div className="reset-password-button">
                <Link to='/reset-password'>
                  Reset password
                </Link>
              </div>
            </div>
            <Button text="Login" type="submit" className="button_login" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

