import {
  PRIORITIES_GETALL_FAILURE,
  PRIORITIES_GETALL_REQUEST,
  PRIORITIES_GETALL_SUCCESS,
  REMOVE_PRIORITIES_FROM_STORE,
} from './types';

export function priorities(state = {}, action) {
  switch (action.type) {
    case PRIORITIES_GETALL_REQUEST:
      return {
        loading: true,
      };
    case PRIORITIES_GETALL_SUCCESS: {
      return {
        ...action.priorities,
      };
    }
    case PRIORITIES_GETALL_FAILURE: {
      return {
        error: action.error,
      };
    }
    case REMOVE_PRIORITIES_FROM_STORE: {
      return {};
    }
    default:
      return state;
  }
}
