import * as types from './types'


const policyAreas: types.PolicyAreas = {
  page: 'Politikbereiche',
  pathname: '/policy-areas',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: true,
    addFieldsInside: false,
    removeFieldsInside: false,
    assign: false,
    printReport: true,
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',

  }
}

const priorities: types.Priorities = {
  page: 'Ambitionen',
  pathname: '/priorities',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: true,
    addFieldsInside: false,
    removeFieldsInside: false,
    assign: false,
    printReport: true,
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',
  }
}

const people: types.People = {
  page: 'Personen',
  pathname: '/people',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: true,
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: 'empty',
    printReport: 'empty',
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',

  }
}

const agencies: types.Agencies = {
  page: 'Abteilungen',
  pathname: '/agencies',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: true,
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: false,
    printReport: true,
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',

  }
}


const divisions: types.Divisions = {
  page: 'Bereiche/Diensteinheiten',
  pathname: '/divisions',
  permissions: {
    createNew: false,
    edit: false,
    remove: false,
    view: true,
    addFieldsInside: false,
    removeFieldsInside: false,
    assign: false,
    printReport: true,
    inviteNewUsers: 'empty',
    dragFields: false,
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',
    freezeEdit: false,
    freezeAssign: false,
    freezeCreateNew: false,
    freezeRemove: false,
  }
}


const profile: types.Profile = {
  page: 'Mein Profil',
  pathname: '/profile',
  permissions: {
    createNew: 'empty',
    edit: true,
    remove: 'empty',
    view: true,
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: 'empty',
    printReport: 'empty',
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',

  }
}

const years: types.Years = {
  page: 'Jahre',
  pathname: '/years',
  permissions: {
    createNew: false,
    edit: 'empty',
    remove: false,
    view: true,
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: 'empty',
    printReport: 'empty',
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: false,
    freezeYearsGoals: false,

  }
}

const reports: types.Reports = {
  page: 'Reports',
  pathname: '/reports',
  permissions: {
    createNew: 'empty',
    edit: 'empty',
    remove: 'empty',
    view: true,
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: 'empty',
    printReport: true,
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',

  }
}

const users: types.Users = {
  page: 'Benutzer',
  pathname: '/users',
  permissions: {
    createNew: 'empty',
    edit: 'empty',
    remove: 'empty',
    view: 'empty',
    addFieldsInside: 'empty',
    removeFieldsInside: 'empty',
    assign: 'empty',
    printReport: 'empty',
    inviteNewUsers: 'empty',
    dragFields: 'empty',
    freezeYears: 'empty',
    freezeYearsGoals: 'empty',
  }
}

export const viewer: types.Page[] = [policyAreas, priorities, people, agencies, divisions, profile, years, reports, users]


export default viewer