import React, { FC } from 'react';
import { StatusTextProps } from './status-text.interface';
import {vocabulary} from "../../../vocabulary/german";

export const StatusText: FC<StatusTextProps> = ({ status, classes }: StatusTextProps) => {
  const renderStatusText = (status: number) => {
    if (status === 0) {
      return vocabulary.statuses.notStarted;
    }

    if (status === 1) {
      return vocabulary.statuses.inProgress;
    }

    if (status === 2) {
      return vocabulary.statuses.finished;
    }
  };

  return <p className={classes ? classes : ''}>{renderStatusText(status)}</p>;
};
