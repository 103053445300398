import React, { FC } from 'react';
import { GrayPanel } from '../../../../panels/gray-panel/gray-panel';
import { Container } from '../../../../template/container/container';
import { StatusText } from '../../../../template/status-text/status-text';
import { vocabulary } from '../../../../../vocabulary/german';
import * as utils from '../../../../../shared/utils';

export const DivisionReport: FC<any> = ({ entityData }) => {
  const renderControllableText = (id: number) => {
    switch (id) {
      case 0: {
        return 'Nein';
      }
      case 1: {
        return 'Ja';
      }
      default:
        return '';
    }
  };

  const renderActivities = (entityData) => {
    const yearInLS = utils.getCurrentYearFromLS();
    const currentYear = yearInLS && Number.isFinite(+yearInLS) ? +yearInLS : 0;
    const lastYear = currentYear - 1;
    const beforeLastYear = lastYear - 1;

    return (
      entityData.Activities &&
      entityData.Activities.map((activity, activityIndex) => (
        <div className="d-flex flex-row short-report_bordered" key={activity.id}>
          <div className="d-flex flex-column w-100">
            {activityIndex === 0 && <GrayPanel text={`${beforeLastYear}`} noMargin />}
            <Container>
              <p>{activity.beforeLastYear}</p>
            </Container>
          </div>
          <div className="d-flex flex-column w-100">
            {activityIndex === 0 && <GrayPanel text={`${lastYear}`} noMargin />}
            <Container>
              <p>{activity.lastYear}</p>
            </Container>
          </div>
          <div className="d-flex flex-column w-100 ">
            {activityIndex === 0 && <GrayPanel text={`${currentYear}`} noMargin />}
            <Container>
              <p>{activity.current}</p>
            </Container>
          </div>
          <div className="d-flex flex-column w-100">
            {activityIndex === 0 && <GrayPanel text={'Controllable'} noMargin />}
            <Container>
              <p>{renderControllableText(activity.controllable)}</p>
            </Container>
          </div>
        </div>
      ))
    );
  };

  return (
    <div className={'short-report-policy'}>
      {/*<div className="d-flex flex-row short-report-row">
        <div className="d-flex flex-column w-100 min-w-50">
          <GrayPanel text={vocabulary.labels.mission} noMargin />
          <Container>
            <p className="short-report__description">{entityData.missions}</p>
          </Container>
        </div>
        <div className="d-flex flex-column w-100 min-w-50">
          <GrayPanel text={vocabulary.labels.environment} noMargin />
          <Container>
            <p className="short-report__description">{entityData.environments}</p>
          </Container>
        </div>
      </div>*/}

      <div className="d-flex flex-row short-report-row">
        <div className="d-flex flex-column w-100 min-w-50">
          <GrayPanel text={vocabulary.labels.activities} noMargin />
          <Container>
            <p>{entityData.missions}</p>
          </Container>
        </div>
        <div className="d-flex flex-column w-100 min-w-50">{renderActivities(entityData)}</div>
      </div>

      {entityData.MidtermGoals &&
        entityData.MidtermGoals.map((mtg, mtgIndex) => (
          <div className="d-flex flex-row short-report-row short-report-mtg" key={mtg.id}>
            <div className="d-flex flex-column w-100 min-w-40">
              <div className="short-report-stg" key={mtg.id}>
                {mtgIndex === 0 && <GrayPanel text={vocabulary.labels.midTermGoal} noMargin />}

                <div className={'d-flex flex-row short-report_bordered'}>
                  <div className={'d-flex flex-column min-w-25 more-line-height'}>
                    <Container>
                      <p
                        className='byerley-bold short-report-title'
                        title={mtg.title}
                      >
                        {mtg.title}
                      </p>
                    </Container>
                  </div>
                  <div className={'d-flex flex-column min-w-25 more-line-height'}>
                    <Container>
                      <p>{mtg.description}</p>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column w-100 min-w-60 ">
              {mtg.ShortTermGoals &&
                mtg.ShortTermGoals.map((stg, stgIndex) => (
                  <div className="d-flex flex-row short-report_bordered" key={stg.id}>
                    <div className={'d-flex flex-column w-100 min-w-40'}>
                      {mtgIndex === 0 && stgIndex === 0 && (
                        <GrayPanel text={vocabulary.labels.shortTermGoal} noMargin />
                      )}
                      <Container>
                        <div className={'d-flex flex-row short-report_bordered more-line-height'}>
                          <div className={'d-flex flex-column'}>
                            <p className={'short-report-stg__title'}> {stg.title}</p>
                          </div>
                        </div>
                      </Container>
                    </div>
                    <div className={'d-flex flex-column w-100 min-w-12'}>
                      <div className={'d-flex flex-row'}>
                        <div className="d-flex flex-column w-100">
                          {mtgIndex === 0 && stgIndex === 0 && <GrayPanel text={vocabulary.labels.outcome} noMargin />}
                          <div className={'d-flex flex-row short-report_bordered'}>
                            <Container>
                              <div className={'d-flex flex-column min-w-50'}>
                                <p> {stg.outcome}</p>
                                <br/>
                                <GrayPanel text={vocabulary.forms.shortTermGoals.placeholders.srgReason} noMargin />
                                <p>{ stg.reason }</p>
                              </div>
                            </Container>
                          </div>
                        </div>
                        <div className="d-flex flex-column w-100">
                          {mtgIndex === 0 && stgIndex === 0 && <GrayPanel text={vocabulary.labels.action} noMargin />}
                          <Container>
                            {stg.Actions &&
                              stg.Actions.map((action) => (
                                <div className={'short-report_bordered'} key={action.id}>
                                  <p>{action.title}</p>
                                </div>
                              ))}
                          </Container>
                        </div>
                      </div>
                    </div>
                    <div className={'d-flex flex-column w-100'} style={{ width: 100 }}>
                      {mtgIndex === 0 && stgIndex === 0 && <GrayPanel text={'Status'} noMargin />}
                      <div className={'d-flex flex-row short-report_bordered'}>
                        <div className={'d-flex flex-column'}>
                          <Container>
                            <StatusText status={stg.status} />
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};
