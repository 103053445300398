import * as React from 'react';
import { setFormDirty } from 'store/formIsDirty';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

type Props = ReturnType<typeof mapDispatchToProps> & { dirty: boolean; };

const CheckIfFormIsDirty: React.FC<Props> = ({ ...rest }) => {
  React.useEffect(() => {
    const setDirty = () => {
      let canceled = false;
      const cancel = (() => { canceled = true; })
      if (!canceled) rest.setFormDirty(rest.dirty);
      return cancel;
    }

    const cancel = setDirty()
    return () => { cancel() }
  }, [rest])

  return <></>;
};


const mapDispatchToProps = (dispatch) => bindActionCreators({ setFormDirty, }, dispatch,);
export default connect(null, mapDispatchToProps,)(CheckIfFormIsDirty);
