import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as utils from '../../shared/utils'

const YEARS_ROUTE = '/years';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const hasToken = utils.isTokenExist();
  const currentYearId = utils.getCurrentYearIdFromLS();
  const currentYear = utils.getCurrentYearFromLS();
  const hasCurrentYear = !!currentYearId && !!currentYear;

  return (
    <Route
      {...rest}
      render={props => {
        const isYearPage = props.location.pathname === YEARS_ROUTE;

        if (!hasToken) return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        if (!hasCurrentYear && !isYearPage
          && props.location.pathname !== '/profile'
          && props.location.pathname !== '/users') {
          return <Redirect to={{ pathname: YEARS_ROUTE, state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}
