import React, { Component, SyntheticEvent } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { PasswordFieldProps, PasswordFieldState } from './password-field.interface';

export class PasswordField extends Component<PasswordFieldProps, PasswordFieldState> {
  state = { mask: 'password' };

  toggleMask = (event: SyntheticEvent) => {
    event.preventDefault();
    this.setState({ mask: this.state.mask === 'password' ? 'text' : 'password' });
  };

  render() {
    const { className, placeholder, validate, name } = this.props;
    const { mask } = this.state;

    return (
      <div className="form__field-wrapper">
        <Field
          name={name}
          type={mask}
          component={'input'}
          placeholder={placeholder}
          className={classNames('form__field', className)}
          validate={validate}
        />
        <i
          className={classNames('form__field_eye', mask === 'text' &&  'form__field_eye_active')}
          onClick={(event) => this.toggleMask(event)}
        />
      </div>
    );
  }
}