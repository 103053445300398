import React, { Component } from 'react';
import { AssignDivisionFormState } from './assign-divisions-form.interface';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../store';
import { ASSIGN_DIVISION_MODAL, MODAL_DIVISION } from '../../../shared/constants/modals';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../store/modals/actions';
import { FormHeader } from '../../template/form-header/FormHeader';
import { Button } from '../../buttons/button/button';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { DivisionsList } from '../../lists/divisions-list/divisions-list';
import { getDivisions, setSelectedDivisions } from '../../../store/divisions/actions';
import { FormBody } from '../../template/form-body/form-body';
import { vocabulary } from '../../../vocabulary/german';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => {
  return {
    divisions: state.divisions,
    currentYearFrozen: state.years.currentYearFrozen
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDivisions,
      setSelectedDivisions,
      closeModal,
      openModal,
    },
    dispatch,
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignDivisionsForm extends Component<PropsFromRedux, AssignDivisionFormState> {

  static contextType = PermissionsContext;
  state = { selectedDivisions: [], permissions: this.context.permissions };

  componentDidMount(): void {
    this.props.getDivisions!();
  }

  handleSubmit = () => {
    const selectedDivisions = { selectedDivisions: this.state.selectedDivisions };

    this.props.setSelectedDivisions!(selectedDivisions);
    this.props.closeModal!(ASSIGN_DIVISION_MODAL, {});
  };

  handleCreateClick = () => {
    this.props.openModal!(MODAL_DIVISION);
  };

  selectDivision = (agency) => {
    // toggle divisions from local state
    if (this.state.selectedDivisions.some((val) => val === agency.id)) {
      const array: number[] = [...this.state.selectedDivisions];
      const index = array.indexOf(agency.id);
      if (index > -1) {
        array.splice(index, 1);
        this.setState({ selectedDivisions: array });
      }
    } else {
      this.setState({ selectedDivisions: [...this.state.selectedDivisions, agency.id] });
    }
  };

  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.edit === true || !this.props.currentYearFrozen) {
      return (
        <Button
          disabled={this.state.selectedDivisions.length === 0}
          onClick={this.handleSubmit}
          text={vocabulary.buttons.save}
        />
      )
    }
  }

  render() {
    const { divisions } = this.props;

    return (
      <>
        <FormHeader renderSave={this.renderSaveButton()} />
        <FormBody noMargin>
          <GrayPanel text={vocabulary.labels.assignDivisionToAgency} />
          <div className="container">
            {divisions && (
              <DivisionsList
                showDisabledItems
                selectable
                divisions={divisions}
                onCreate={this.handleCreateClick}
                onSelect={(division) => this.selectDivision(division)}
              />
            )}
          </div>
        </FormBody>
      </>
    );
  }
}

export default connector(AssignDivisionsForm);
