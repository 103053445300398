import {
  CLEAR_SELECTED_PEOPLE,
  PEOPLE_GET_FAILURE,
  PEOPLE_GET_SUCCESS,
  PEOPLE_GETALL_FAILURE,
  PEOPLE_GETALL_SUCCESS,
  REMOVE_PEOPLE_FROM_STORE,
  SELECT_PEOPLE_FOR_AGENCY,
  SELECT_PEOPLE_FOR_DIVISION,
} from './types';

const initialState: any = {};

export function people(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_GETALL_SUCCESS:
      return {
        ...state,
        ...action.people,
      };
    case PEOPLE_GETALL_FAILURE: {
      return {
        error: action.error,
      };
    }
    case PEOPLE_GET_SUCCESS: {
      return {
        ...state,
        current: action.people,
      };
    }
    case PEOPLE_GET_FAILURE: {
      return {
        error: action.error,
      };
    }
    case REMOVE_PEOPLE_FROM_STORE: {
      return {
        ...state,
        current: {},
      };
    }
    case SELECT_PEOPLE_FOR_AGENCY: {
      return {
        ...state,
        selected: {
          forAgency: action.data.forAgency,
          forDivision: state.selected && state.selected.forDivision ? state.selected.forDivision : null,
        }
      };
    }
    case SELECT_PEOPLE_FOR_DIVISION: {
      return {
        ...state,
        selected: {
          forAgency: state.selected && state.selected.forAgency ? state.selected.forAgency : null,
          forDivision: action.data.forDivision,
        },
      };
    }
    case CLEAR_SELECTED_PEOPLE: {
      return {
        ...state,
        selected: null,
      };
    }
    default:
      return state;
  }
}
