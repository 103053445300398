import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  LOGOUT,
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
  UpdateFailureAction,
  UpdateRequestAction,
  UpdateSuccessAction,
  LogoutAction,
  CHECK_USER_LOGGED,
  LOAD_USER_PROFILE,
} from './types';
import * as utils from '../../shared/utils';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import { sendAlertError } from '../alert/actions';
import UserService from '../../services/user.service';

const userService = new UserService();

const login = (username, password): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function request(user): LoginRequestAction {
    return { type: LOGIN_REQUEST, user };
  }

  function success(user): LoginSuccessAction {
    return { type: LOGIN_SUCCESS, user };
  }

  function failure(error): LoginFailureAction {
    return { type: LOGIN_FAILURE, error };
  }

  dispatch(request({ username }));
try{
  const result = await userService.login(username, password);

  if (!result.error) {
    dispatch(success(result));
    utils.history.push('/');
  } else {
    console.log('1')
    dispatch(failure(result));
    dispatch(sendAlertError(result));
  }
} catch (error) {
  console.log('2')

  console.log(error)
  dispatch(failure(error));
  dispatch(sendAlertError(error));
}

};

const logout = (): LogoutAction => {
  userService.logout();
  return { type: LOGOUT };
};


function requestUpdateProfile(status): UpdateRequestAction {
  return { type: UPDATE_PROFILE_REQUEST, status };
}

function successUpdateProfile(status): UpdateSuccessAction {
  return { type: UPDATE_PROFILE_SUCCESS, status};
}

function failureUpdateProfile(status): UpdateFailureAction {
  return { type: UPDATE_PROFILE_FAILURE, status };
}

const updateProfile = (email, password) => async (dispatch) => {

  dispatch(requestUpdateProfile(true))

  try {
    const result = await userService.updateProfile(email, password);

    dispatch(requestUpdateProfile(false));

    if(!result.error){
      dispatch(loadUserProfile())
      dispatch(successUpdateProfile(true));
      dispatch(failureUpdateProfile(false));
      setTimeout(() => dispatch(successUpdateProfile(false)), 4000)
    }else{
      dispatch(failureUpdateProfile(true));
    }

  } catch (e) {
    dispatch(failureUpdateProfile(true));
  }

}

const loadUserProfile = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const result = await userService.loadProfile();
  dispatch({type: LOAD_USER_PROFILE,  payload: result});
}

const checkIsLogged = () => async (dispatch) => {
  dispatch({
    type: CHECK_USER_LOGGED,
  });
};

export {
  login,
  logout,
  checkIsLogged,
  loadUserProfile,
  updateProfile,
};
