import React, { FC } from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import { InputFieldProps } from './input-field.interface';
import { usePermissions } from 'contexts';

export const InputField: FC<InputFieldProps> = ({
  name,
  placeholder,
  className,
  validate,
  forDivision = false,
  autocomplete = 'on',
  isAuth = false,
  currentYearFrozen = false
}: InputFieldProps) => {
  const matrix = usePermissions();
  const currentEdit = currentYearFrozen && typeof matrix.permissions.freezeEdit === 'boolean'
    ? matrix.permissions.freezeEdit : matrix.permissions.edit
  const isReadOnly = currentEdit === false && !isAuth;
  const editable = isReadOnly ? 'form__field_readonly' : '';

  return (
    <Field
      readOnly={isReadOnly}
      name={name}
      className={classNames('form__field', editable, className)}
      component={'input'}
      placeholder={placeholder}
      validate={validate}
      autoComplete={autocomplete}
    />
  );
};
