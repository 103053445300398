export const vocabulary = {
  preloader: {
    waitForReport: 'Bitte warten Sie, bis der Report generiert wurde…'
  },
  labels: {
    policiesAreas: "Politikbereiche",
    policyArea: "Politikbereich",
    profile: "Mein Profil",
    priorities: "Ambitionen",
    priority: "Ambition",
    agencies: "Abteilungen",
    agency: "Abteilung",
    people: "Personen",
    person: "Person",
    midTermGoals: "Legislaturziele",
    midTermGoal: "Legislaturziel",
    divisions: "Bereiche / Diensteinheiten",
    division: "Bereich/Diensteinheit",
    mission: "Auftrag",
    environment: "Indikator",
    activities: "Aufgaben",
    activity: "Aufgabe",
    shortTermGoals: "Jahresziele",
    shortTermGoal: "Jahresziel",
    outcome: "Indikator",
    action: "Massnahme",
    assignAgencyToMidtermGoal: "Abteilung an Legislaturziel zuweisen",
    assignPersonToAgency: "Person dieser Abteilung zuweisen",
    assignDivisionToAgency: "Bereich(e) dieser Abteilung zuweisen",
    yearSelections: "Jahre",
    users: "Benutzer",
    full: "Vollständigen",
    selectAll: "Alles auswählen",
    freezeGoalYear: "Jahresziele verabschiedet",
    unFreezeGoalYear: "Jahresziele freigegeben",
  },
  forms: {
    policy: {
      description: "Leitsatz",
      placeholders: {
        newGuidingPriniciple: "Leitsatz",
        newPolicyArea: "Neuer Politikbereich",
        newPriorityTitle: "Ambition Titel",
        newPriorityDescr: "Ambition Beschrieb",
        newMtgTitle: "Legislaturziel Titel",
        newMtgDescr: "Legislaturziel Beschrieb",
      },
    },
    people: {
      description: "Personen",
      placeholders: {
        name: "Vorname Name",
      },
    },
    agency: {
      description: "Informationen zur Abteilung",
      responsiblePerson: "Verantwortliche Person",
      placeholders: {
        newAgency: "Neue Abteilung",
        newAgencyDescr: "Abteilung Beschrieb",
      },
    },
    division: {
      description: "Informationen zum Bereich",
      tableLabels: {
        controllable: "Steuerbar",
      },
      placeholders: {
        divisionTitle: "Neuer Bereich",
        mission: "Auftrag",
        environment: "Indikator",
      },
    },
    activities: {
      placeholders: {
        activityDescription: "Aufgabenbeschrieb",
      },
    },
    shortTermGoals: {
      placeholders: {
        stgTitle: "Jahresziel",
        stgOutcome: "Indikator",
        srgReason: "Begründung",
      },
    },
    users: {
      description: "Benutzer",
      placeholders: {
        username: "Nutzername",
        email: "Email",
        role: "Rolle",
      }
    },
    full: {
      description: "Vollständigen",
    }
  },
  buttons: {
    save: "Speichern",
    delete: "Löschen",
    PDF: "PDF",
    addActivity: "Aufgabe",
    addMidTermGoal: "Legislaturziel hinzufügen",
    addPriority: "Ambitionen hinzufügen",
    addShortTermGoal: "Jahresziel",
    addAction: "Massnahme",
    createNewPolicy: "Politikbereich",
    createNewPeople: "Person hinzufügen",
    createNewAgency: "Abteilung erstellen",
    createNewDivision: "Bereich erstellen",
    createNewYear: "Jahr hinzufügen",
    assignAgencies: "Abteilungen zuweisen",
    assignPeople: "Personen zuweisen",
    assignDivisions: "Bereiche zuweisen",
    reassignPerson: "Person neu zuweisen",
    reports: {
      downloadPDF: "PDF herunterladen",
      downloadWord: "Word herunterladen",
      downloadConfigReport: "PDF / Word Report konfigurieren",
      downloadFullReport: "Vollständigen Report herunterladen",
      downloadReport: "Report",
      downloadReportPdf: "Report PDF",
      downloadShortPoliciesReport:
        "Zusammenfassung Politikbereiche herunterladen",
      downloadReportDocx: "Report Word",
      configureFulleReport: "Report konfigurieren",
      divisions: {
        outcomes: "Indikatoren",
        reasons: "Begründungen",
        actions: "Massnahmen",
        midTermGoals: "Jahresziele",
        midTermGoalsExtended: "Jahresziele Bereiche / Diensteinheiten"
      }
    },
    inviteUser: "Invite new",
  },

  messages: {
    unsavedChanges: "Möchten Sie Ihre Änderungen verwerfen?\n",
  },
  statuses: {
    notStarted: "○",
    inProgress: "◓",
    finished: "●",
  },
  roles: {
    viewer: "Viewer",
    manager: "Manager",
    admin: "Admin",
  }
};
