import React, { FC } from "react";
import { EntityPanel } from "../../panels/entity-panel/entity-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { DivisionsListProps } from "./divisions-list.interface";
import { vocabulary } from "../../../vocabulary/german";
import { useDispatch } from "react-redux";
import { clearCurrentDivision } from "../../../store/divisions/actions";
import { clearSelectedPeople } from "../../../store/people/actions";
import { usePermissions } from '../../../contexts/permissions.context';

export const DivisionsList: FC<DivisionsListProps> = ({
  divisions,
  onSelect,
  onCreate,
  hideCreateButton,
  showDisabledItems,
  selectable,
}: DivisionsListProps) => {
  const matrix = usePermissions()
  const dispatch = useDispatch();
  const handleCreate = () => {
    dispatch(clearCurrentDivision());
    dispatch(clearSelectedPeople());
    onCreate();
  };

  const createDivisionBtn = (): JSX.Element | undefined => {
    if (matrix.permissions.createNew === true && !hideCreateButton) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewDivision}
          onClick={handleCreate}
        />
      )
    }
  }


  return divisions && divisions.rows && divisions.rows.length > 0 ? (
    <div className={"entities-list"}>
      {divisions.rows.map((item, index) => (
        <EntityPanel
          isDisabled={showDisabledItems ? !!item.AgencyId : false}
          isSelectable={selectable}
          onClick={() => onSelect(item)}
          key={index}
          title={item.title}
          id={item.id}
        />
      ))}
      {createDivisionBtn()}
    </div>
  ) : (
    <>
      <NotFoundMessage message={`Nicht gefunden: Bereiche/Diensteinheiten konnten nicht gefunden werden.`} />
      {createDivisionBtn()}
    </>
  );
};
