import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { FormHeader } from '../../template/form-header/FormHeader';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { ASSIGN_PEOPLE_MODAL, MODAL_PEOPLE } from '../../../shared/constants/modals';
import { PeopleList } from '../../lists/people-list/people-list';
import { AssignPeopleFormProps, AssignPeopleFormState } from './assign-people.interface';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';
import { getPeople, setSelectedPeopleForAgency, setSelectedPeopleForDivision } from '../../../store/people/actions';
import { FormBody } from '../../template/form-body/form-body';
import { vocabulary } from '../../../vocabulary/german';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ({
  people: state.people
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPeople,
      closeModal,
      openModal,
      setSelectedPeopleForDivision,
      setSelectedPeopleForAgency,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignPeopleForm extends Component<PropsFromRedux & AssignPeopleFormProps, AssignPeopleFormState> {

  static contextType = PermissionsContext;
  state = { selectedPeople: [], permissions: this.context.permissions };

  componentDidMount(): void {
    this.props.getPeople!();
  }

  selectPeople = (people) => {
    if (this.props.forEntity) {
      if (this.props.forEntity === 'agency') {
        this.props.setSelectedPeopleForAgency!({ forAgency: { peopleId: people.id } });
      }

      if (this.props.forEntity === 'division') {
        this.props.setSelectedPeopleForDivision!({ forDivision: { peopleId: people.id } });
      }

      this.props.closeModal!(ASSIGN_PEOPLE_MODAL);
    }
  };

  handleCreatePeople = () => {
    this.props.openModal!(MODAL_PEOPLE);
  };

  render() {
    const { people } = this.props;

    return (
      <>
        <FormHeader />
        <FormBody noMargin>
          <GrayPanel text={vocabulary.labels.assignPersonToAgency} />
          <div className="container">
            {people && (
              <PeopleList
                isSelectable={true}
                people={people}
                createClick={this.handleCreatePeople}
                panelClick={(people) => this.selectPeople(people)}
              />
            )}
          </div>
        </FormBody>
      </>
    );
  }
}

export default connector(AssignPeopleForm);
