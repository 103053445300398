
import * as types from './types'
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import AgenciesService from '../../services/agencies.service';
import { handleActionResult } from '../action-helpers';

const agenciesService = new AgenciesService();


const getAgenciesSuccess = (agencies) => ({ type: types.AGENCIES_GETALL_SUCCESS, agencies })
const getAgenciesRequest = () => ({ type: types.AGENCIES_GETALL_REQUEST })
const getAgenciesFailure = () => ({ type: types.AGENCIES_GETALL_FAILURE })
const getAgencies = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(getAgenciesRequest())

  const failureCB = () => dispatch(getAgenciesFailure())
  const successCB = (agencies) => () => dispatch(getAgenciesSuccess(agencies))

  const result = await agenciesService.getAgencies();
  handleActionResult(result, successCB(result), failureCB);
};

const reorderAgencyDivisions = (params): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  const failureCB = () => {}
  const successCB = () => () => {}

  const result = await agenciesService.reorderAgencyDivisions(params);
  handleActionResult(result, successCB(), failureCB);
};

const getAgenciesWithDivisions = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(getAgenciesRequest())

  const failureCB = () => dispatch(getAgenciesFailure())
  const successCB = (agencies) => () => dispatch(getAgenciesSuccess(agencies))

  const result = await agenciesService.getAgenciesWithDivisions();
  handleActionResult(result, successCB(result), failureCB);
};

const getAgencySuccess = (agency): types.AgenciesActionTypes => ({ type: types.AGENCY_GET_SUCCESS, agency });
const getAgencyRequest = (): types.AgenciesActionTypes => ({ type: types.AGENCY_GET_REQUEST });
const getAgencyFailure = (error): types.AgenciesActionTypes => ({ type: types.AGENCY_GET_FAILURE, error });
const getAgency = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();
  if (state.agencies.isGetPending) return;

  dispatch(getAgencyRequest());

  const failureCB = (error) => dispatch(getAgencyFailure({ message: error.message || 'Get agencies failed' }));
  const successCB = (agency) => () => dispatch(getAgencySuccess(agency))

  const result = await agenciesService.getAgency(id);
  handleActionResult(result, successCB(result), failureCB);
};


const removeAgencyRequest = () => ({ type: types.AGENCIES_REMOVE_REQUEST })
const removeAgencyFailure = () => ({ type: types.AGENCIES_REMOVE_FAILURE })
const removeAgencySuccess = () => ({ type: types.AGENCIES_REMOVE_SUCCESS })
const removeAgency = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(removeAgencyRequest())

  const failureCB = () => dispatch(removeAgencyFailure())
  const successCB = () => {
    dispatch(removeAgencySuccess());
    dispatch(getAgencies());
  }

  const result = await agenciesService.removeAgency(id);
  handleActionResult(result, successCB, failureCB);
};

const createAgencyRequest = () => ({ type: types.AGENCIES_CREATE_REQUEST })
const createAgencyFailure = () => ({ type: types.AGENCIES_CREATE_FAILURE })
const createAgencySuccess = (agency) => ({ type: types.AGENCIES_CREATE_SUCCESS, agency })
const createAgency = (formData): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(createAgencyRequest())

  const failureCB = () => dispatch(createAgencyFailure())
  const successCB = (agency) => () => {
    dispatch(createAgencySuccess(agency));
    dispatch({ type: types.REMOVE_AGENCY_FROM_STORE, });
    dispatch(getAgencies());
  }

  const result = await agenciesService.createAgency(formData);
  handleActionResult(result, successCB(result), failureCB);
};

const updateAgencySuccess = (agency) => ({ type: types.AGENCIES_UPDATE_SUCCESS, agency });
const updateAgencyRequest = () => ({ type: types.AGENCIES_UPDATE_REQUEST });
const updateAgencyFailure = () => ({ type: types.AGENCIES_UPDATE_FAILURE });
const updateAgency = (id, formData): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();
  if (state.agencies.isUpdatePending) return;

  dispatch(updateAgencyRequest());

  const failureCB = () => dispatch(updateAgencyFailure());
  const successCB = ({ agencies, agency }) => () => {
    dispatch(getAgency(id));
    dispatch(updateAgencySuccess(agency))
    dispatch(getAgenciesSuccess(agencies))
  }

  const agency = await agenciesService.updateAgency(id, formData);
  const agencies = await agenciesService.getAgenciesWithDivisions();
  const data = { agencies, agency }
  handleActionResult(data, successCB(data), failureCB);
};

const setSelectedAgencies = (data): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: types.SELECT_AGENCIES,
    data,
  });
};

const clearSelectedAgencies = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: types.CLEAR_SELECTED_AGENCIES,
  });
};

const clearSelectedAgency = (
  priorityIndex: number,
  midTermGoalIndex: number,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: types.CLEAR_SELECTED_AGENCY,
    data: {
      priorityIndex,
      midTermGoalIndex,
    },
  });
};

const removeAgencyFromStore = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: types.REMOVE_AGENCY_FROM_STORE });
};

const setCurrentAgency = (agency) => async (dispatch) => {
  dispatch({
    type: types.AGENCY_GET_SUCCESS,
    agency,
  });
};

const clearAgencyIdFromDivision = (id: number) => (dispatch) => dispatch({
  type: types.CLEAR_AGENCY_ID_FROM_DIVISION,
  payload: { id }
});

export {
  getAgencies,
  getAgenciesWithDivisions,
  getAgency,
  removeAgency,
  createAgency,
  updateAgency,
  setSelectedAgencies,
  clearSelectedAgencies,
  clearSelectedAgency,
  removeAgencyFromStore,
  setCurrentAgency,
  clearAgencyIdFromDivision,

  reorderAgencyDivisions
};
