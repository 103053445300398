import {
  CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION,
  CLEAR_CURRENT_DIVISION,
  CLEAR_SELECTED_DIVISIONS, DIVISION_CREATE_FAILURE, DIVISION_CREATE_REQUEST,
  DIVISION_CREATE_SUCCESS,
  DIVISION_GET_SUCCESS,
  DIVISION_REMOVE_SUCCESS, DIVISION_UPDATE_FAILURE, DIVISION_UPDATE_REQUEST,
  DIVISION_UPDATE_SUCCESS,
  DIVISIONS_GETALL_SUCCESS,
  REMOVE_DIVISION_FROM_STORE,
  SELECT_DIVISIONS
} from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';
import { Division } from '../../shared/interfaces/divisions.interface';
import DivisionsService from '../../services/divisions.service';
import { handleActionResult } from '../action-helpers';
import { closeModal } from '../modals/actions';
import { MODAL_DIVISION } from '../../shared/constants/modals';

const divisionsService = new DivisionsService();

const getDivisions = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(divisions) {
    return {
      type: DIVISIONS_GETALL_SUCCESS,
      divisions,
    };
  }

  const result = await divisionsService.getDivisions();
  return handleActionResult(result, () => dispatch(success(result)));
};

const getDivision = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success(division) {
    return {
      type: DIVISION_GET_SUCCESS,
      division,
    };
  }

  const result = await divisionsService.getDivision(id);
  return handleActionResult(result, () => dispatch(success(result)));
};

const removeDivisionFromStore = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({ type: REMOVE_DIVISION_FROM_STORE });
};

const createDivisionSuccess = (division) => ({ type: DIVISION_CREATE_SUCCESS,  division });
const createDivisionRequest = () => ({ type: DIVISION_CREATE_REQUEST });
const createDivisionFailure = () => ({ type: DIVISION_CREATE_FAILURE });

const createDivision = (formData: Division): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(createDivisionRequest());

  try {
    const result = await divisionsService.createDivision(formData);
    dispatch(createDivisionSuccess(result));
    dispatch(closeModal(MODAL_DIVISION));
    dispatch(getDivisions());
  } catch (e) {
    dispatch(createDivisionFailure());
  }
};

const updateDivisionSuccess = (division) => ({ type: DIVISION_UPDATE_SUCCESS,  division });
const updateDivisionRequest = () => ({ type: DIVISION_UPDATE_REQUEST });
const updateDivisionFailure = () => ({ type: DIVISION_UPDATE_FAILURE });

const updateDivision = (id: number, formData): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch,
) => {
  dispatch(updateDivisionRequest());

  try {
    const result = await divisionsService.updateDivision(id, formData);
    dispatch(updateDivisionSuccess(result));
  } catch (e) {
    dispatch(updateDivisionFailure());
  }
};

const removeDivision = (id: number): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  function success() {
    return {
      type: DIVISION_REMOVE_SUCCESS,
    };
  }

  const result = await divisionsService.removeDivision(id);
  return handleActionResult(result, () => {
    dispatch(success());
    dispatch(getDivisions());
  });
};

const setSelectedDivisions = (data): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: SELECT_DIVISIONS,
    data,
  });
};

const clearSelectedDivisions = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: CLEAR_SELECTED_DIVISIONS,
  });
};

const setCurrentDivision = (division) => async (dispatch) => {
  dispatch({
    type: DIVISION_GET_SUCCESS,
    division,
  });
};

const clearCurrentDivision = () => ({ type: CLEAR_CURRENT_DIVISION });

const clearAgencyIdFromSelectedDivision = (id: number) => dispatch => dispatch({
  type: CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION,
  payload: { id }
})

export {
  getDivisions,
  getDivision,
  removeDivisionFromStore,
  createDivision,
  updateDivision,
  removeDivision,
  setSelectedDivisions,
  clearSelectedDivisions,
  setCurrentDivision,
  clearCurrentDivision,
  clearAgencyIdFromSelectedDivision
};
