import React from 'react';

import cn from 'classnames';

import { Field } from 'formik';

import { TextareaField } from '../../../fields/textarea-field/textarea-field';
import { vocabulary } from '../../../../vocabulary/german';
import { confirmAlert } from "react-confirm-alert";
import { ConfirmationModal } from '../../../template/modals/confirmation-modal';
import { DivisionFormActions } from '../division-form-actions/division-form-actions';

import { DivisionFormStgItemProps } from './division-form-stg.interface';
import * as utils from '../../../../shared/utils'
import { usePermissions } from '../../../../contexts/permissions.context';


const GOAL_STATUSES = {
  0: 'notStarted',
  1: 'inProgress',
  2: 'finished'
};
const DivisionFormStgItem = ({ shortTermGoalIndex, shortTermGoalsHelper, shortTermGoal, currentYearFrozen = false }: DivisionFormStgItemProps) => {
  const matrix = usePermissions()
  const reasonClass = cn('priorities__report-icon', !!shortTermGoal.isShowReason && 'priorities__report-icon__disabled');
  const activeStatus = GOAL_STATUSES[shortTermGoal.status];
  const isReadOnly = utils.getReadOnly();
  const changeReason = () => { shortTermGoal.isShowReason = !shortTermGoal.isShowReason; }
  const permissionsCanChange = utils.notChangeGoal();
  return (
    <div className='stg'>
      <div className="stg-fields">
        <div className="stg-row-fields-upper">
          {

            <TextareaField
              name={`ShortTermGoals.${shortTermGoalIndex}.title`}
              placeholder={vocabulary.forms.shortTermGoals.placeholders.stgTitle}
              className="priorities__reason-field-wrapper"
              disabled={permissionsCanChange}
              forDivision
              currentYearFrozen={currentYearFrozen}
            />
          }
          <TextareaField
            name={`ShortTermGoals.${shortTermGoalIndex}.outcome`}
            placeholder={vocabulary.forms.shortTermGoals.placeholders.stgOutcome}
            className="form__field_no-margin priorities__reason-field-wrapper"
            forDivision
            currentYearFrozen={currentYearFrozen}
          />
          <div className="field-wrapper field-wrapper_row field-wrapper_row_shrink">
            <span className="division-actions__main-status">{vocabulary.statuses[activeStatus]}</span>
            <div className="priorities__reason-wrapper">
              <span onClick={changeReason}>
                <Field type="checkbox" name={`ShortTermGoals.${shortTermGoalIndex}.isShowReason`} disabled={isReadOnly} />
              </span> 
              <i className={reasonClass} />
            </div>
            {
              (currentYearFrozen ? matrix.permissions.freezeRemoveFieldsInside === true : matrix.permissions.removeFieldsInside === true) && (
                <button
                  data-controlled-permissions={DivisionFormStgItem.name}
                  className='priorities__remove-icon'
                  onClick={() => {
                    confirmAlert({
                      customUI: ({ onClose }) => {
                        return (
                          <ConfirmationModal
                            forDivision
                            onClose={onClose}
                            onOk={() => shortTermGoalsHelper.remove(shortTermGoalIndex)}
                          />
                        );
                      },
                    });
                  }}
                />
              )
            }
          </div>
        </div>
        {
          !!shortTermGoal.isShowReason && (
          <div className="stg-row-fields-lower priorities__reason-field-wrapper">
            <TextareaField
              name={`ShortTermGoals.${shortTermGoalIndex}.reason`}
              placeholder={vocabulary.forms.shortTermGoals.placeholders.srgReason}
              forDivision
              currentYearFrozen
            />
          </div>
        )}
        <div className='stg-row-fields-lower'>
          <DivisionFormActions currentYearFrozen={currentYearFrozen} shortTermGoal={shortTermGoal} shortTermGoalIndex={shortTermGoalIndex} />
        </div>
      </div>
    </div>
  );
}

export default DivisionFormStgItem;
