import {
  ALERT_CLEAR,
  ALERT_ERROR,
  ALERT_SUCCESS,
  AlertClearAction,
  AlertErrorAction,
  AlertSuccessAction,
} from './types';

export const success = (message): AlertSuccessAction => {
  return { type: ALERT_SUCCESS, message };
};

export const sendAlertError = (message): AlertErrorAction => {
  return { type: ALERT_ERROR, message };
};

export const clearAlerts = (): AlertClearAction => {
  return { type: ALERT_CLEAR };
};
