import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { EntityPanel } from "../../panels/entity-panel/entity-panel";
import { CreateButton } from "../../buttons/create-button/create-button";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { PageHeader } from "../../template/page-header/page-header";
import { MODAL_PEOPLE } from "../../../shared/constants/modals";
import { PeoplePageState } from "./people-page.interface";
import { AppState } from "../../../store";
import { bindActionCreators } from "redux";
import { openModal } from "../../../store/modals/actions";
import { Person } from "../../../shared/interfaces/people.interface";
import { Container } from "../../template/container/container";
import { getPeople, getPerson } from "../../../store/people/actions";
import { Spinner } from "../../template/spinner/spinner";
import { vocabulary } from "../../../vocabulary/german";
import { PermissionsContext } from "contexts";

const mapStateToProps = (state: AppState) => ({
  people: state.people,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPeople,
      getPerson,
      openModal,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PeoplePage extends Component<PropsFromRedux, PeoplePageState> {

  static contextType = PermissionsContext;
  state = { permissions: this.context.permissions }

  componentDidMount(): void {
    this.props.getPeople!();
  }

  handleClick = () => {
    this.props.openModal!(MODAL_PEOPLE);
  };

  handleEdit = (item: Person) => {
    if (item && item.id) {
      this.props.getPerson!(item.id);
      this.props.openModal!(MODAL_PEOPLE);
    }
  };

  createBtn = (): JSX.Element | undefined => {
    if (this.state.permissions.createNew === true) {
      return (
        <CreateButton
          text={vocabulary.buttons.createNewPeople}
          onClick={this.handleClick}
        />
      )
    }
  }

  renderEntityPanels() {
    const { people } = this.props;


    if (people && people.rows) {
      if (people.rows.length > 0) {
        return (
          <div className={"entities-list"}>
            {people.rows.map((item, index) => (
              <EntityPanel
                onClick={() => this.handleEdit(item)}
                key={index}
                title={item.name}
                id={item.id}
              />
            ))}
            {this.createBtn()}
          </div>
        );
      } else {
        return (
          <>
            <NotFoundMessage
              message={`Nicht gefunden: Personen konnten nicht gefunden werden.`}
            />
            {this.createBtn()}
          </>
        );
      }
    } else {
      return <Spinner />;
    }
  }

  render() {
    return (
      <div className={"page-content"}>
        <PageHeader title={vocabulary.labels.people} btnText="" downloadReport={null} />
        <Container>{this.renderEntityPanels()}</Container>
      </div>
    );
  }
}

export default connector(PeoplePage);
