import { MODAL_CLOSE, MODAL_OPEN } from './types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../index';
import { Action } from 'redux';

export const openModal = (
  modalName: string,
  data: any = null,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: MODAL_OPEN,
    modal: {
      name: modalName,
      isOpen: true,
      data: data,
    },
  });
};

export const closeModal = (
  modalName: string,
  data: any = null,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch({
    type: MODAL_CLOSE,
    modal: {
      name: modalName,
      isOpen: false,
      data: data,
    },
  });
};
