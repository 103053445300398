import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonProps } from './button.interface';

export const Button: FC<ButtonProps> = ({ disabled, type, text, className, onClick, id }: ButtonProps) => {
  let classList = 'button';
  const rest = id ? { id } : {};

  if (disabled) {
    classList += ' disabled';
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type ? type : 'button'}
      className={classNames(classList, 'form__button', className)}
      {...rest}
    >
      {text}
    </button>
  );
};
