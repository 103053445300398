import React, { FC } from "react";
import { Container } from "../container/container";
import { FormHeaderProps } from "./form-header.interface";

export const FormHeader: FC<FormHeaderProps> = (props: FormHeaderProps) => {
  const { renderSave, renderDelete, renderReport, renderDocxReport } = props;
  return (
    <Container>
      <div className={"form-controls"}>
        {renderSave}
        {renderDelete}
        {renderDocxReport}
        {renderReport}
      </div>
    </Container>
  );
};
