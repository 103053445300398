import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { confirmAlert } from "react-confirm-alert";
import { PageHeader } from "../../template/page-header/page-header";
import { Container } from "../../template/container/container";
import { vocabulary } from "../../../vocabulary/german";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { Spinner } from "../../template/spinner/spinner";
import { CreateButton } from "../../buttons/create-button/create-button";
import { UsersPageState } from './users-page.interface';
import { AppState } from '../../../store';
import { getUsers, deleteUser } from '../../../store/users/actions';
import { openModal } from "../../../store/modals/actions";
import { MODAL_USER } from "../../../shared/constants/modals";
import { UserPanel } from '../../panels/user-panel/user-panel';
import { PaginationPanel } from '../../panels/pagination-panel/pagination-panel';
import { ConfirmationModal } from '../../template/modals/confirmation-modal';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ({
  users: state.users,
  totalPages: state.users.totalPages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      deleteUser,
      openModal,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class UsersPage extends Component<PropsFromRedux, UsersPageState> {
  static contextType = PermissionsContext;
  role = localStorage.getItem('role');
  userId = localStorage.getItem('userId');

  state = {
    page: 0,
    permissions: this.context.permissions
  }

  componentDidMount(): void {
    this.props.getUsers!(this.state.page);
  }

  changePage = (page: number): void => {
    this.setState({ page: page });
    this.props.getUsers!(page);
  }

  showRemoveConfirmationModal = (id: number, event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }): JSX.Element => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() => this.props.deleteUser!(id, this.state.page)}
          />
        );
      },
    });
  };

  handleClick = () => {
    this.props.openModal!(MODAL_USER, { page: this.state.page });
  };

  renderInviteNewUserButton = (): JSX.Element | undefined => {
    if ( this.state.permissions.inviteNewUsers === true) {
      return (
        <CreateButton text={vocabulary.buttons.inviteUser} onClick={this.handleClick} />
      )
    }
  }

  renderUsersList() {
    const isAdmin = this.role === 'admin';
    const { users, totalPages } = this.props;

    if (users && users.rows) {
      if (users.rows.length > 0) {
        return (
          <div className={"entities-list"}>
            {users.rows.map((item, index) => (
              <div className={'users-item'} key={index}>
                <UserPanel
                  name={item.username}
                  email={item.email}
                  status={item.registrationStatus}
                  role={item.roles[0]}
                  id={item.id}
                  isDeletable={isAdmin && item.username !== 'admin' && Number(this.userId) !== item.id}
                  onDelete={(event) => this.showRemoveConfirmationModal(item.id, event)}
                />
              </div>
            ))}
            <PaginationPanel changePage={this.changePage} totalPages={totalPages} page={this.state.page} />
            {this.renderInviteNewUserButton()}
          </div>
        );
      } else {
        return (
          <>
            <NotFoundMessage message={`Nicht gefunden`} />
            {this.renderInviteNewUserButton()}
          </>
        );
      }
    } else {
      return <Spinner />;
    }
  }

  render() {
    return (
      <div className={"page-content"}>
        <PageHeader title={vocabulary.labels.users} btnText="" downloadReport={null} />
        <Container>{this.renderUsersList()}</Container>
      </div>
    );
  }
}

export default connector(UsersPage);
