import {
  AGENCIES_GETALL_FAILURE,
  AGENCIES_GETALL_REQUEST,
  AGENCIES_GETALL_SUCCESS,
  AGENCIES_UPDATE_FAILURE,
  AGENCIES_UPDATE_REQUEST,
  AGENCIES_UPDATE_SUCCESS,
  AgenciesActionTypes,
  AGENCY_GET_FAILURE,
  AGENCY_GET_REQUEST,
  AGENCY_GET_SUCCESS,
  CLEAR_AGENCY_ID_FROM_DIVISION,
  CLEAR_SELECTED_AGENCIES,
  CLEAR_SELECTED_AGENCY,
  REMOVE_AGENCY_FROM_STORE,
  SELECT_AGENCIES
} from './types';
import * as utils from '../../shared/utils';
import { Division } from '../../shared/interfaces/divisions.interface';

function mergeAssignedAgencies(state, data) {
  if (utils.helpers.isObjectEmpty(state.assigned)) {
    return {
      ...state,
      assigned: [data],
    };
  }

  const newAssignedArr = state.assigned.slice();

  const index = newAssignedArr.findIndex(
    (item) =>
      item.assignedTo.priorityIndex === data.assignedTo.priorityIndex &&
      item.assignedTo.midTermGoalIndex === data.assignedTo.midTermGoalIndex,
  );

  if (index !== -1) {
    newAssignedArr[index].selectedAgencies = data.selectedAgencies;
  } else {
    newAssignedArr.push(data);
  }

  return {
    ...state,
    assigned: newAssignedArr,
  };
}

function clearSelectedAgency(state, data) {
  if (!utils.helpers.isObjectEmpty(state.assigned)) {
    if (typeof data.midTermGoalIndex !== 'undefined') {
      // update assigned arr

      let newAssignedArr = [...state.assigned];

      const idx = newAssignedArr.findIndex(
        (item) =>
          item.assignedTo.midTermGoalIndex === data.midTermGoalIndex &&
          item.assignedTo.priorityIndex === data.priorityIndex,
      );

      // remove element and recalculate indexes
      if (idx !== -1) {
        const currentPriorityIndex = newAssignedArr[idx].assignedTo.priorityIndex;
        newAssignedArr = [...newAssignedArr.slice(0, idx), ...newAssignedArr.slice(idx + 1)];

        newAssignedArr.map((item, index) => {
          if (index >= idx) {
            if (item.assignedTo.priorityIndex === currentPriorityIndex) {
              item.assignedTo.midTermGoalIndex = item.assignedTo.midTermGoalIndex - 1;
            }
          }

          return item;
        });
      }

      return {
        ...state,
        assigned: newAssignedArr,
      };
    } else {
      const newAssignedArr = [...state.assigned];

      // remove element and recalculate indexes
      const filterAssigned = newAssignedArr.filter((item) => item.assignedTo.priorityIndex !== data.priorityIndex);
      filterAssigned.map((item) => {
        if (item.assignedTo.priorityIndex > data.priorityIndex) {
          item.assignedTo.priorityIndex = item.assignedTo.priorityIndex - 1;
        }

        return item;
      });

      return {
        ...state,
        assigned: filterAssigned,
      };
    }
  }

  return {
    ...state,
  };
}



const initialState = {
  assigned: [],
  current: {
    Divisions: []
  },
  isGetAgencyPending: false,
  isUpdatePending: false,

  isLoadingAgenciesList: false
};

export function agencies(state = initialState, action: AgenciesActionTypes) {
  switch (action.type) {
    case AGENCIES_GETALL_REQUEST: {
      return {
        ...state,
        isLoadingAgenciesList: true
      };
    }
    case AGENCIES_GETALL_SUCCESS: {
      return {
        ...state,
        ...action.agencies,
        isLoadingAgenciesList: false
      };
    }
    case AGENCIES_GETALL_FAILURE: {
      return {
        error: action.error,
        isLoadingAgenciesList: false
      };
    }



    case AGENCY_GET_REQUEST: {
      return {
        ...state,
        isGetAgencyPending: true
      }
    }
    case AGENCY_GET_SUCCESS: {
      return {
        ...state,
        current: action.agency,
        isGetAgencyPending: false
      };
    }
    case AGENCY_GET_FAILURE: {
      return {
        error: action.error,
        isGetAgencyPending: false
      };
    }
    case AGENCIES_UPDATE_REQUEST: {
      return {
        ...state,
        isUpdatePending: true
      }
    }
    case AGENCIES_UPDATE_SUCCESS:
    case AGENCIES_UPDATE_FAILURE: {
      return {
        ...state,
        isUpdatePending: false
      }
    }
    case REMOVE_AGENCY_FROM_STORE: {
      return {
        ...state,
        current: {},
      };
    }
    case SELECT_AGENCIES: {
      return mergeAssignedAgencies(state, action.data);
    }
    case CLEAR_SELECTED_AGENCIES: {
      return {
        ...state,
        assigned: [],
      };
    }
    case CLEAR_SELECTED_AGENCY: {
      return clearSelectedAgency(state, action.data);
    }
    case CLEAR_AGENCY_ID_FROM_DIVISION: {
      const { id } = action.payload;

      return {
        ...state,
        current: {
          ...state.current,
          Divisions: state.current.Divisions.map((division: Division) => {
            if (id === division.id) {
              return {
                ...division,
                AgencyId: null,
                toUnclaim: true
              }
            }

            return division;
          })
        }
      }
    }
    default:
      return state;
  }
}
