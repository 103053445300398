import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_USER } from '../../../shared/constants/modals';
import { closeModal } from '../../../store/modals/actions';
import { Button } from '../../buttons/button/button';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { FormHeader } from '../../template/form-header/FormHeader';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { UserInvitationFormProps, UserInvitationFormState } from './user-invitation-form.interface';
import { UserFormData } from '../../../shared/interfaces/users.interface';
import { Container } from '../../template/container/container';
import { inviteUser } from '../../../store/users/actions';
import { InputField } from '../../fields/input-field/input-field';
import { SelectField } from '../../fields/select-field/select-field';
import * as utils from '../../../shared/utils';
import { vocabulary } from '../../../vocabulary/german';
import CheckIfFormIsDirty from 'shared/utils/checkIfFormIsDirty';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ({ 
  currentYearFrozen: state.years.currentYearFrozen,
  users: state.users 
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      inviteUser,
      closeModal,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
class UserInvitationForm extends Component<PropsFromRedux & UserInvitationFormProps, UserInvitationFormState> {

  static contextType = PermissionsContext;
  state: UserInvitationFormState = {
    error: false,
    permissions: this.context.permissions
  }

  handleSave = (user: UserFormData) => {
    const { page } = this.props;
    this.props.inviteUser!(user, page);
    this.props.closeModal!(MODAL_USER);
  };


  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.inviteNewUsers === true && !this.props.currentYearFrozen) {
      return (<Button type={'submit'} text={vocabulary.buttons.save} />)
    }
  }

  render() {
    const { users } = this.props;
    const initialValues: UserFormData = { id: null, username: '', email: '', role: 'viewer' };

    if (users && users.current) {
      initialValues.id = users.current.id ? users.current.id : null;
      initialValues.username = users.current.username ? users.current.username : '';
      initialValues.email = users.current.email ? users.current.email : '';
      initialValues.role = users.current.roles ? users.current.roles[0] : '';
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          this.handleSave(values);
        }}
      >
        {({ errors, touched, dirty, ...rest }) => {
          return (
            <Form>
              <CheckIfFormIsDirty dirty={dirty}></CheckIfFormIsDirty>
              <FormHeader renderSave={this.renderSaveButton()} />
              <GrayPanel text={vocabulary.forms.users.description} people />

              <Container>
                <div className="field-wrapper">
                  <InputField
                    name="username"
                    placeholder={vocabulary.forms.users.placeholders.username}
                    validate={utils.validateField}
                    className={'form__field_big'}
                  />
                  {errors.username && touched.username && <p className={'field-error'}>{errors.username}</p>}
                </div>
                <div className="field-wrapper">
                  <InputField
                    name="email"
                    placeholder={vocabulary.forms.users.placeholders.email}
                    validate={utils.validateField}
                    className={'form__field_big'}
                  />
                  {errors.email && touched.email && <p className={'field-error'}>{errors.email}</p>}
                </div>
                <p>{vocabulary.forms.users.placeholders.role}</p>
                <div className="field-wrapper">
                  <SelectField
                    name="role"
                    className=""
                    value={rest?.values?.role}
                  >
                    <option value="viewer" label={vocabulary.roles.viewer} />
                    <option value="manager" label={vocabulary.roles.manager} />
                    <option value="admin" label={vocabulary.roles.admin} />
                  </SelectField>
                </div>
              </Container>
            </Form>
          )
        }}
      </Formik>
    );
  }
}

export default connector(UserInvitationForm);
