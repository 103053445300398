import React, { FC } from 'react';

import cn from 'classnames';

import { SpinnerProps } from './spinner.interface';

const SpinnerMessage = (({ ...rest }) => {
  const messageClass = cn('spinner-message')
  const message = rest.message.trim()

  return message ? <div className={messageClass}>{message}...</div> : <></>;
})

export const Spinner: FC<SpinnerProps> = ({ isFullSize = false, message = '' }: SpinnerProps) => {
  const spinnerClass = cn('spinner-wrapper', isFullSize && 'spinner-wrapper__full')

  return (
    <div className={spinnerClass}>
      <div className={'spinner'} />
      {message && <SpinnerMessage message={message} />}
    </div>
  );
};
