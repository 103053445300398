import { ApiService } from './api.service';

export default class StatisticsService extends ApiService {
  public getStatistics = async () => {
    return await this.get(`/general/counts`);
  };
}



