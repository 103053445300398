import { MENU_CLOSE, MENU_OPEN } from './types';

export function menu(state: any = { isOpen: false }, action) {
  switch (action.type) {
    case MENU_OPEN:
      return {
        isOpen: true,
      };
    case MENU_CLOSE:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
}
