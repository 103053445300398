import React, { useState } from 'react';
import { ResetPasswordForm } from '../../forms/reset-password-form/reset-password-form';
import UserService from '../../../services/user.service';
import { Spinner } from "../../template/spinner/spinner";

const userService = new UserService();

const ResetPasswordPage = () => {

  const [success, setSuccess] = useState(false);
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [error, setError] = useState(false);


  const  handleSubmit = ({ email }) => {

    if ( email ) {

      setStatusLoaded(true)
      setSuccess(false)
      setError(false)

      userService.resetPassword(email).then(
        (data) => setSuccess(true)
      ).catch(
        () => setError(true)
      ).finally(
        () => setStatusLoaded(false)
      );
    }
  };

  return statusLoaded ? <Spinner/> : <ResetPasswordForm error={ error } success={ success } onSubmit={ handleSubmit }  />;

}



export default ResetPasswordPage
