export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

export interface AlertSuccessAction {
  type: typeof ALERT_SUCCESS;
  message: string;
}

export interface AlertErrorAction {
  type: typeof ALERT_ERROR;
  message: string;
}

export interface AlertClearAction {
  type: typeof ALERT_CLEAR;
}
