import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Router, Route, Switch } from 'react-router-dom';

import * as utils from '../../../shared/utils';

import { AppState } from '../../../store';
import { closeMenu, openMenu } from '../../../store/menu/actions';
import { checkIsLogged } from '../../../store/user/actions';

import { PrivateRoute } from '../../private-route/private-route';

import TopBar from '../top-bar/top-bar';
import Priorities from '../../pages/priorities-page/priorities-page';
import LoginPage from '../../pages/login-page/login-page';
import ResetPassword from '../../pages/reset-password-page/reset-password-page';
import UpdatePassword from '../../pages/update-password-page/update-password-page';
import LogoutPage from '../../pages/logout-page/logout-page';
import Policies from '../../pages/policies-page/policies-page';
import PolicyModal from '../../modals/policy-modal/policy-modal';
import DivisionsPage from '../../pages/division-page/divisions-page';
import ProfilePage from '../../pages/profile-page/profile-page';
import Agencies from '../../pages/agencies-page/agencies-page';
import PeopleModal from '../../modals/people-modal/people-modal';
import AssignAgencyModal from '../../modals/assign-agency-modal/assign-agency-modal';
import AgencyModal from '../../modals/agency-modal/agency-modal';
import AssignPeopleModal from '../../modals/assign-people-modal/assign-people-modal';
import PeoplePage from '../../pages/people-page/people-page';
import { LeftMenu } from '../left-menu/left-menu';
import AuthRoute from '../../authRoute/AuthRoute';

import { PageLayoutState } from './page-layout.interface';
import DivisionModal from '../../modals/division-modal/division-modal';
import AssignDivisionsModal from '../../modals/assign-divisions-modal/assign-divisions-modal';
import { ReportsPage } from '../../pages/reports/reports-page/reports-page';
import { FullReportPage } from '../../pages/reports/full-report-page/full-report-page';
import ShortReportPage from '../../pages/reports/short-report-page/short-report-page';
import HomePage from '../../pages/home-page/home-page';
import YearSelectionPage from '../../pages/year-selection-page/year-selection-page';
import UsersPage from '../../pages/users-page/users-page';
import UserModal from '../../modals/user-modal/user-modal';
import ConfigReportModal from '../../modals/config-report-modal/config-report-modal';
import { PermissionsProvider } from '../../../contexts';

const mapStateToProps = (state: AppState) => ({ menu: state.menu, authentication: state.authentication });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openMenu,
      closeMenu,
      checkIsLogged,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PageLayout extends Component<PropsFromRedux, PageLayoutState> {
  componentDidMount(): void {
    this.props.checkIsLogged!();
  }

  hideMenu = () => {
    this.props.closeMenu!();
  };

  handleBackdropClick = () => {
    this.props.closeMenu!();
  };

  render() {
    const {
      menu,
      authentication: { loggedIn },
    } = this.props;

    return (
      <div className={'page'}>
        <Router history={utils.history}>
          <PermissionsProvider>
            {loggedIn && <TopBar />}
            {loggedIn && <LeftMenu isOpen={menu.isOpen} bgClick={this.handleBackdropClick} hideMenu={this.hideMenu} />}
            <Switch>
              <AuthRoute path="/login" component={LoginPage} />
              <Route path="/logout" component={LogoutPage} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/update-password/:token" component={UpdatePassword} />
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute exact path="/policy-areas" component={Policies} />
              <PrivateRoute exact path="/priorities" component={Priorities} />
              <PrivateRoute exact path="/people" component={PeoplePage} />
              <PrivateRoute exact path="/profile" component={ProfilePage} />
              <PrivateRoute exact path="/agencies" component={Agencies} />
              <PrivateRoute exact path="/divisions" component={DivisionsPage} />
              <PrivateRoute exact path="/reports" component={ReportsPage} />
              <PrivateRoute exact path="/reports/full-report" component={FullReportPage} />
              <PrivateRoute exact path="/reports/short-report" component={ShortReportPage} />
              <PrivateRoute path="/years" component={YearSelectionPage} />
              <PrivateRoute path="/users" component={UsersPage} />
              <Route path="*" component={LoginPage} />
            </Switch>
            {loggedIn && <PolicyModal />}
            {loggedIn && <AssignAgencyModal />}
            {loggedIn && <AgencyModal />}
            {loggedIn && <AssignDivisionsModal />}
            {loggedIn && <DivisionModal />}
            {loggedIn && <AssignPeopleModal />}
            {loggedIn && <PeopleModal />}
            {loggedIn && <UserModal />}
            {loggedIn && <ConfigReportModal />}
          </PermissionsProvider>
        </Router>
      </div>
    );
  }
}

export default connector(PageLayout);
