import { MODAL_OPEN, MODAL_CLOSE } from './types';
import {
  ASSIGN_AGENCY_MODAL,
  ASSIGN_DIVISION_MODAL,
  ASSIGN_PEOPLE_MODAL,
  MODAL_AGENCY,
  MODAL_DIVISION,
  MODAL_PEOPLE,
  MODAL_POLICY,
  MODAL_USER,
  MODAL_CONFIG_FULL,
} from '../../shared/constants/modals';

// Default order of modals hierarchy.
const initialState = [
  {
    name: MODAL_POLICY,
    isOpen: false,
    data: null,
  },
  {
    name: ASSIGN_AGENCY_MODAL,
    isOpen: false,
    data: null,
  },
  {
    name: MODAL_AGENCY,
    isOpen: false,
    data: null,
  },
  {
    name: ASSIGN_DIVISION_MODAL,
    isOpen: false,
    data: null,
  },
  {
    name: MODAL_DIVISION,
    isOpen: false,
    data: null,
  },
  { name: ASSIGN_PEOPLE_MODAL, isOpen: false, data: null },
  { name: MODAL_PEOPLE, isOpen: false, data: null },
  { name: MODAL_USER, isOpen: false, data: null },
  { name: MODAL_CONFIG_FULL, isOpen: false, data: null },
];

export function modals(state: any = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return mergeModalState(state, action, true);
    case MODAL_CLOSE:
      return mergeModalState(state, action, false);
    default:
      return state;
  }
}

const mergeModalState = (state, action, modalStatus: boolean) => {
  let modalIndex: number = state.findIndex((modal) => modal.name === action.modal.name);

  if (modalIndex !== -1) {
    state[modalIndex].isOpen = modalStatus;
    state[modalIndex].data = action.modal.data;

    return [...state];
  } else {
    return [...state, action.modal];
  }
};
