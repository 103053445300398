import React, { FC } from 'react';
import { Container } from '../container/container';
import { PageHeaderProps } from './page-header.interface';

export const PageHeader: FC<PageHeaderProps> = ({ title, btnText, btnTextDocx='', downloadReport }: PageHeaderProps) => (



  <div className={'page-header'}>
    <Container>
      <div className="d-flex title-btn-wrapper">
        <h1 className={'page__title'}>{title}</h1>
        <div className="d-flex">
          {
            downloadReport && btnText ? (
              <button type="button" className={'report-pdf__division'} onClick={() => downloadReport('pdf')}>
                {btnText}
                <i className={'report-pdf__icon'} />
              </button>
            ) : null
          }
          {
            downloadReport && btnTextDocx ? (
              <button type="button" className={'report-pdf__division'} onClick={() => downloadReport('docx')}>
                {btnTextDocx}
                <i className={'report-pdf__icon'} />
              </button>
            ) : null
          }
        </div>
      </div>
    </Container>
  </div>
);
