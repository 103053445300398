import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { MODAL_POLICY } from "../../../shared/constants/modals";
import { PageHeader } from "../../template/page-header/page-header";
import { CreateButton } from "../../buttons/create-button/create-button";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { PoliciesState } from "./policies-page.interface";
import { AppState } from "../../../store";
import { bindActionCreators } from "redux";
import { openModal } from "../../../store/modals/actions";
import { Container } from "../../template/container/container";
import { Spinner } from "../../template/spinner/spinner";
import { getPolicies, getPolicy } from "../../../store/policies/actions";
import { vocabulary } from "../../../vocabulary/german";
import PoliciesList from "../../lists/policies-list/policies-list";
import ReportService from "services/reports.service";
import events from '../../../shared/constants/events'
import { PermissionsContext } from "contexts";

const mapStateToProps = (state: AppState) => ({
  policies: state.policies,
  isGetPending: state.policies.isGetPending,
  isUpdatePending: state.policies.isUpdatePending,
  isLoadingPolicies: state.policies.isLoadingPolicies
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPolicies,
      getPolicy,
      openModal,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PoliciesPage extends Component<PropsFromRedux, PoliciesState> {
  static contextType = PermissionsContext
  state = { locked: false, permissions: this.context.permissions };
  unlock = (): void => { this.setState({ locked: false }); }

  componentDidMount(): void {
    this.props.getPolicies!();
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  handleClick = () => {
    this.props.openModal!(MODAL_POLICY);
  };

  handleEdit = (item) => {
    if (item.id) {
      this.props.getPolicy!(item.id);
      this.props.openModal!(MODAL_POLICY);
    }
  };

  downloadShortPoliciesReport = (type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadShortPoliciesReport(type);
  }

  renderPolicyButton = (): JSX.Element | undefined => {
    if (this.state.permissions.createNew  === true) {
      return (
        <CreateButton
        text={vocabulary.buttons.createNewPolicy}
        onClick={() => this.handleClick()}
      />
      )
    }
  }

  renderEntityPanels() {
    const policies = this.props.policies;
    if (this.props.isLoadingPolicies) return <Spinner />;

    if (policies && policies.rows && policies.rows.length !== 0) {
      return (
        <div className={"entities-list"}>
          <PoliciesList policies={policies} onSelect={this.handleEdit} />
          {this.renderPolicyButton()}
        </div>
      );
    }


    return (
      <>
        <NotFoundMessage message={`Nicht gefunden: Politikbereiche konnten nicht gefunden werden.`} />
        {this.renderPolicyButton()}
      </>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className={"page-content"}>
          <PageHeader 
            title={vocabulary.labels.policiesAreas}
            btnText={vocabulary.buttons.reports.downloadReportPdf}
            downloadReport={this.downloadShortPoliciesReport}
            btnTextDocx={vocabulary.buttons.reports.downloadReportDocx}
          />
          <Container>{this.renderEntityPanels()} </Container>
        </div>
        {
          this.state.locked
          && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />
        }
      </React.Fragment>
    );
  }
}

export default connector(PoliciesPage);
