function getModalDataFromState(state, modalName) {
  const modal = state.modals.find(item => item.name === modalName);

  return typeof modal !== 'undefined'
    ? {
        modal: {
          isOpen: modal.isOpen,
          data: modal.data,
        },
      }
    : {
        modal: {
          isOpen: false,
        },
      };
}

export const modalService = {
  getModalDataFromState,
};
