import { MENU_CLOSE, MENU_OPEN } from './types';

export const openMenu = () => dispatch => {
  dispatch({
    type: MENU_OPEN,
  });
};

export const closeMenu = () => dispatch => {
  dispatch({
    type: MENU_CLOSE,
  });
};
