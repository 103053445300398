import * as utils from '../shared/utils';
import { ApiService } from './api.service';
import { UserFormData } from '../shared/interfaces/users.interface';

export default class UserService extends ApiService {
  public login = async (username, password) => {
    const requestBody = {
      password: password,
      username: username,
    };

    const response = await this.post('/auth/login', requestBody);

    if (!response.error) {
      this.setCredentials(response.accessToken, response.refreshToken);
    }

    return response;
  };

  public logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) this.post('/auth/logout', { refreshToken })

    this.unsetCredentials();
    utils.history.push('/login');
  };

  public resetPassword = async (email) => {
    await this.post('/auth/reset-password', {
      email,
    });
  };

  public updatePassword = async (resetPasswordToken) => {
    await this.post('/auth/update-password', {
      resetPasswordToken,
    });
  };

  public loadProfile = async () => {
    const response = await this.get('/users/current');
    return response;
  };

  public updateProfile = async (email, password) => {
    const requestBody = {
      email,
      password,
    };
    const response = await this.put('/users/current', requestBody);
    return response;
  };

  public getUsers = async (page: number) => {
    return this.get(`/users/?page=${page}`);
  };

  public getUser = async (id: number) => {
    return this.get(`/users/${id}`);
  };

  public inviteUser = async (formData: UserFormData) => {
    return this.post('/users/', {
      username: formData.username,
      email: formData.email,
      roles: [formData.role],
    });
  };

  public deleteUser = async (id: number) => {
    return this.remove(`/users/${id}`);
  };
};