export const PEOPLE_GETALL_REQUEST = 'PEOPLE_GETALL_REQUEST';
export const PEOPLE_GETALL_SUCCESS = 'PEOPLE_GETALL_SUCCESS';
export const PEOPLE_GETALL_FAILURE = 'PEOPLE_GETALL_FAILURE';

export const PEOPLE_CREATE_REQUEST = 'PEOPLE_CREATE_REQUEST';
export const PEOPLE_CREATE_SUCCESS = 'PEOPLE_CREATE_SUCCESS';
export const PEOPLE_CREATE_FAILURE = 'PEOPLE_CREATE_FAILURE';

export const PEOPLE_UPDATE_REQUEST = 'PEOPLE_UPDATE_REQUEST';
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS';
export const PEOPLE_UPDATE_FAILURE = 'PEOPLE_UPDATE_FAILURE';

export const PEOPLE_REMOVE_REQUEST = 'PEOPLE_REMOVE_REQUEST';
export const PEOPLE_REMOVE_SUCCESS = 'PEOPLE_REMOVE_SUCCESS';
export const PEOPLE_REMOVE_FAILURE = 'PEOPLE_REMOVE_FAILURE';

export const PEOPLE_GET_REQUEST = 'PEOPLE_GET_REQUEST';
export const PEOPLE_GET_SUCCESS = 'PEOPLE_GET_SUCCESS';
export const PEOPLE_GET_FAILURE = 'PEOPLE_GET_FAILURE';

export const REMOVE_PEOPLE_FROM_STORE = 'REMOVE_PEOPLE_FROM_STORE';
export const SELECT_PEOPLE_FOR_AGENCY = 'SELECT_PEOPLE_FOR_AGENCY';
export const SELECT_PEOPLE_FOR_DIVISION = 'SELECT_PEOPLE_FOR_DIVISION';
export const CLEAR_SELECTED_PEOPLE = 'CLEAR_SELECTED_PEOPLE';
