import React, { Component } from 'react';
import { PasswordField } from '../../fields/password-field/password-field';
import { InputField } from '../../fields/input-field/input-field';
import { LoginFormProps } from './profile-form.interface';
import { Form, Formik } from 'formik';
import * as utils from '../../../shared/utils';
import { Button } from '../../buttons/button/button';
import { Spinner } from "../../template/spinner/spinner";

class ProfileForm extends Component<LoginFormProps>  {


  renderError(touched) {
    if (this.props.equalPasswordError && touched.repeatPassword) {
      return (
        <p className={'field-error'}>Passwords should be equals.</p>
      )
    }

    if (!this.props.equalPasswordError && touched.repeatPassword && this.props.strongPasswordError) {
      return (
        <p className={'field-error'}>Password does not correspond to requirements.</p>
      )

    }
  }


  render() {

    const { user, updateProfileSuccess, updateProfileFailure, updateProfileRequest } = this.props.authentication

    const initialValues = {
      email: user! && user!.email ? user.email : '',
      password: '',
      repeatPassword: ''
    };


    return (
      <>
        {updateProfileRequest ? <Spinner /> :
          <Formik enableReinitialize initialValues={initialValues} onSubmit={this.props.onSubmit}>
            {({ errors, touched }) => (
              <Form autoComplete="off" className="form profile-form">
                <div className="form-group">
                  <label className="form-label-black">Email</label>
                  <InputField autocomplete="off" name="email" validate={utils.validateEmail} />
                  {errors.email && touched.email && <p className={'field-error'}>{errors.email}</p>}
                </div>
                <div className="form-group">
                  <label className="form-label-black">Password</label>
                  <PasswordField name="password" />
                </div>
                <div className="form-group">
                  <p className="form-label">The password must contain at least one uppercase character, one lowercase character, one digit, and one special character. </p>
                </div>

                <div className="form-group">
                  <label className="form-label-black">Repeat Password</label>
                  <PasswordField name="repeatPassword" />
                  {this.renderError(touched)}
                </div>
                <div className={'login-form-footer'}>
                  <div />
                  <Button text="Save" type="submit" className="button_login" />
                </div>
                {updateProfileSuccess && <p className="field-success">The profile is updated successfully!</p>}
                {updateProfileFailure && <p className="field-error">Something went wrong!</p>}
              </Form>
            )}
          </Formik>
        }
      </>
    );
  }
};

export default ProfileForm;