import {
  STATISTICS_GET_SUCCESS,
  STATISTICS_GET_FAILURE
} from './types';

export function statistics(state = {}, action) {
  switch (action.type) {
    case STATISTICS_GET_SUCCESS: {
      return {
        ...action.statistics,
      };
    }
    case STATISTICS_GET_FAILURE: {
      return {
        error: action.error,
      };
    }
    default:
      return state;
  }
}
