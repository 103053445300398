import React, { Component } from "react";
import classNames from "classnames";
import { EntityPanelProps, EntityPanelState } from "./entity-panel.interface";

export class EntityPanel extends Component<EntityPanelProps, EntityPanelState> {
  state = { selected: false };
  handleClick = () => {
    const { onClick, isSelectable } = this.props;
    if (isSelectable) this.setState((state) => ({ selected: !state.selected }));
    if (onClick) onClick();
  };

  render() {
    const {
      title = "Unnamed entity",
      className,
      onDelete,
      isDeletable,
      isSelectable,
      isSmall,
      isDisabled,
      onClick,
      isFixed,
    } = this.props;
    const { selected } = this.state;

    let classList = "entities-item";

    if (isDeletable) classList += " deletable";
    if (isSmall) classList += " small";
    if (isDisabled) classList += " disabled";
    if (selected) classList += " selected";
    if (isSelectable || onClick) classList += " hoverable";
    if (isFixed) classList += " fixed";

    return (
      <div className={classNames(classList, className)} onClick={this.handleClick} >
        <div className="entities-item-content">
          <p className="entities-item__title">{title}</p>
          {isDeletable && onDelete && (<i className="entities-item__delete" onClick={(event) => !onDelete || onDelete(event)} />)}
        </div>
      </div>
    );
  }
}
