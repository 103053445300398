import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { EntityPanel } from "../../panels/entity-panel/entity-panel";
import { NotFoundMessage } from "../../template/not-found-message/not-found-message";
import { PageHeader } from "../../template/page-header/page-header";
import { bindActionCreators } from "redux";
import { openModal } from "../../../store/modals/actions";
import { PrioritiesState } from "./priorities-page.interface";
import { AppState } from "../../../store";
import * as utils from "../../../shared/utils";
import { CreateButton } from "../../buttons/create-button/create-button";
import { MODAL_POLICY } from "../../../shared/constants/modals";
import { Container } from "../../template/container/container";
import { getPolicies, getPolicy } from "../../../store/policies/actions";
import { Spinner } from "../../template/spinner/spinner";
import { vocabulary } from "../../../vocabulary/german";
import ReportService from "services/reports.service";
import events from '../../../shared/constants/events'
import { PermissionsContext } from "contexts";

const mapStateToProps = (state: AppState) => ({ policies: state.policies });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openModal,
      getPolicies,
      getPolicy,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PrioritiesPage extends Component<PropsFromRedux, PrioritiesState> {
  static contextType = PermissionsContext;
  state = { locked: false, permissions: this.context.permissions };
  unlock = (): void => { this.setState({ locked: false }); }


  componentDidMount(): void {
    this.props.getPolicies!();
    document.body.addEventListener(events.UNLOCK.name, this.unlock);
  }

  componentWillUnmount(): void {
    document.body.removeEventListener(events.UNLOCK.name, this.unlock);
  }

  handleAddPriority = (id: number): void => {
    if (id) {
      this.props.getPolicy!(id);
      this.props.openModal!(MODAL_POLICY);
    }
  };


  downloadPoliciesReport = (type) => {
    this.setState({ locked: true });
    const reportsService = new ReportService();
    reportsService.downloadPoliciesReport(type);
  }

  renderEntityPanels() {
    const { policies } = this.props;

    if (!policies || !policies.rows) return <Spinner />;

    if (!policies.rows.length) {
      return (
        <NotFoundMessage
          message={`Nicht gefunden: Ambitionen konnten nicht gefunden werden.`}
        />
      );
    }

    return (
      <div className={"entities-list entities-list_priorities"}>
        {policies.rows.map((policy, index) => (
          <div className={"row policies-row"} key={index}>
            <div className={"col"}>
              <EntityPanel isFixed title={policy.title} id={policy.id} />
            </div>
            <div className={"col"}>
              {policy.Priorities &&
                !utils.helpers.isObjectEmpty(policy.Priorities) ? (
                <>
                  {policy.Priorities.map((priority, priorityIndex) => (
                    <EntityPanel
                      key={priorityIndex}
                      title={priority.title}
                      id={priority.id}
                      onClick={() => this.handleAddPriority(policy.id)}
                    />
                  ))}
                  {
                    (this.state.permissions.createNew === true) && (
                      <CreateButton
                        text={vocabulary.buttons.addPriority}
                        onClick={() => this.handleAddPriority(policy.id)}
                      />
                    )
                  }
                </>
              ) : (
                <>
                  <NotFoundMessage
                    message={`Nicht gefunden: Ambitionen konnten nicht gefunden werden`}
                  />
                  {
                    (this.state.permissions.createNew === true) && (
                      <CreateButton
                        text={vocabulary.buttons.addPriority}
                        onClick={() => this.handleAddPriority(policy.id)}
                      />
                    )
                  }
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className={"page-content"}>
          <PageHeader
            title={vocabulary.labels.priorities}
            btnTextDocx={vocabulary.buttons.reports.downloadReportDocx}
            downloadReport={this.downloadPoliciesReport}
            btnText={vocabulary.buttons.reports.downloadReportPdf}
          />

          <Container>{this.renderEntityPanels()}</Container>
        </div>
        {
          this.state.locked
          && <Spinner isFullSize message={vocabulary.preloader.waitForReport} />
        }
      </React.Fragment>
    );
  }
}

export default connector(PrioritiesPage);
