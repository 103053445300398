import React, { Component } from 'react';
import ReportService from '../../../../services/reports.service';
import { ReportPageProps, ReportPageState } from './full-report-page.types';
import * as utils from '../../../../shared/utils';
import { ReportElement } from '../../../../shared/interfaces/reports.interface';
import { GrayPanel } from '../../../panels/gray-panel/gray-panel';
import { Container } from '../../../template/container/container';
import { StatusText } from '../../../template/status-text/status-text';
import { Spinner } from '../../../template/spinner/spinner';
import { PermissionsContext } from 'contexts';

export class FullReportPage extends Component<ReportPageProps, ReportPageState> {

  static contextType = PermissionsContext;

  state = {
    report: [] as Array<ReportElement>,
    loading: true,
    permissions: this.context.permissions
  };

  private reportsService = new ReportService();

  componentDidMount(): void {
    this.reportsService.getFullReport().then((data) => {
      this.setState({
        report: data,
        loading: false,
      });
    });
  }

  render() {
    const { report, loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <>
        <div className="report">
          {!utils.helpers.isObjectEmpty(report) &&
            report.map((policy, policyIndex) => (
              <div className="report-item" key={policyIndex}>
                <div className="report-col">
                  <Container>
                    <p className="report__title">{policy.title}</p>
                  </Container>
                  <GrayPanel text={'Guiding principle'} />

                  <Container>
                    <p className="report__description"><b>{policy.guidingPrincipleDescription}</b></p>
                  </Container>
                </div>
                <div className="report-col">
                  <div className="report-priorities">
                    {policy.Priorities &&
                      policy.Priorities.map((priority, priorityIndex) => (
                        <div className="report-priorities-row" key={priorityIndex}>
                          <div className="report-priorities-content">
                            <GrayPanel text={priority.title} />
                            <Container>
                              <p className="report-priorities-row__description">{priority.description}</p>
                            </Container>
                          </div>
                          <div className="report-mid-term-goals">
                            {priority.MidtermGoals &&
                              priority.MidtermGoals.map((midTermGoal, mtgIndex) => (
                                <div className="report-mid-term-goals-row" key={mtgIndex}>
                                  <div className="report-mid-term-goals-content">
                                    {mtgIndex === 0 && <GrayPanel text={'Mid-Term Goal'} />}
                                    <Container>
                                      <div className="report-mid-term-goals-info">
                                        <p className="report-mid-term-goals-info__title">{midTermGoal.title}</p>
                                        <p className="report-mid-term-goals-info__description">
                                          {midTermGoal.description}
                                        </p>
                                      </div>
                                    </Container>
                                  </div>
                                  <div className="report-agencies">
                                    {midTermGoal.Agencies &&
                                      midTermGoal.Agencies.map((agency, agencyIndex) => (
                                        <div className="report-agencies-row" key={agencyIndex}>
                                          <div className="report-agencies-content">
                                            {agencyIndex === 0 && mtgIndex === 0 && <GrayPanel text={'Agencies'} />}
                                            <Container>
                                              <div className="report-agencies-info">
                                                <p className="report-agencies-info__title">{agency.title}</p>
                                              </div>
                                            </Container>
                                          </div>
                                          <div className="report-division">
                                            {agency.Divisions &&
                                              agency.Divisions.map((division, divisionIndex) => (
                                                <div className="report-division-row" key={divisionIndex}>
                                                  <div className="report-division-content">
                                                    {divisionIndex === 0 && agencyIndex === 0 && mtgIndex === 0 && (
                                                      <GrayPanel text={'Divisions'} />
                                                    )}
                                                    <Container>
                                                      <div className="report-agencies-info">
                                                        <p className="report-agencies-info__title">{division.title}</p>
                                                      </div>
                                                    </Container>
                                                  </div>

                                                  <div className="report-stg">
                                                    {division.ShortTermGoals &&
                                                      division.ShortTermGoals.map((stg, stgIndex) => (
                                                        <div className="report-stg-row" key={stgIndex}>
                                                          <div className="report-stg-content">
                                                            { mtgIndex === 0 && agencyIndex === 0 && divisionIndex === 0 && stgIndex === 0 && (
                                                                <GrayPanel text={'Short-Term Goals'} />
                                                              )}
                                                            <Container>
                                                              <div className="report-agencies-info">
                                                                <p className="report-agencies-info__title">
                                                                  {stg.title}
                                                                </p>
                                                              </div>
                                                            </Container>
                                                          </div>
                                                          <div className="report-status">
                                                            <div className="report-status-row">
                                                              <div className="report-status-content">
                                                                {mtgIndex === 0 &&
                                                                  agencyIndex === 0 &&
                                                                  stgIndex === 0 &&
                                                                  divisionIndex === 0 && <GrayPanel text={'Status'} />}
                                                                <Container>
                                                                  <div className="report-agencies-info">
                                                                    <StatusText
                                                                      classes={'report-agencies-info__title'}
                                                                      status={stg.status}
                                                                    />
                                                                  </div>
                                                                </Container>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </>
    );
  }
}
