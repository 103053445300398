import { 
  CHECK_USER_LOGGED, 
  LOGIN_FAILURE, 
  LOGIN_SUCCESS, 
  LOGOUT, 
  LOAD_USER_PROFILE, 
  UPDATE_PROFILE_SUCCESS, 
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  AuthenticationState,
} from './types';

const initialState: AuthenticationState = { 
  loggedIn: false, 
  user: null, 
  updateProfileSuccess: false,
  updateProfileFailure: false,
  updateProfileRequest: false 
};

export function authentication(state = initialState, action): AuthenticationState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return{
        ...state,
        updateProfileSuccess: action.status,
      }
    case UPDATE_PROFILE_FAILURE:
      return{
        ...state,
        updateProfileFailure: action.status,
      }
    case UPDATE_PROFILE_REQUEST:
      return{
        ...state,
        updateProfileRequest: action.status,
      }  
    case CHECK_USER_LOGGED: {
      const accessToken = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');

      return {
        ...state,
        loggedIn: !!(accessToken && refreshToken),
      };
    }
    case LOAD_USER_PROFILE: {
      return {
        ...state,
        user: action.payload,
      };
    }
    default:
      return state;
  }
}

