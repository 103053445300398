import React, { FC } from 'react';
import { CreateButtonProps } from './create-button.interface';
import { IconCreate } from '../../icons/icon-create';
import cn from 'classnames';

export const CreateButton: FC<CreateButtonProps> = ({
  noWrapper,
  type = 'button',
  isSmall,
  onClick,
  disabled,
  text,
}: CreateButtonProps) => {
  const wrapperClass = noWrapper ? '' : 'button__wrapper';
  const buttonClassList = cn('button button_create', isSmall && 'button_create_small');

  return (
    <div className={wrapperClass} >
      <button type={type} className={buttonClassList} onClick={onClick} disabled={disabled} data-controlled-permissions={CreateButton.name}>
        <IconCreate />
        {text}
      </button>
    </div>
  )
};
