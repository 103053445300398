export const POLICIES_GETALL_REQUEST = 'POLICIES_GETALL_REQUEST';
export const POLICIES_GETALL_SUCCESS = 'POLICIES_GETALL_SUCCESS';
export const POLICIES_GETALL_FAILURE = 'POLICIES_GETALL_FAILURE';

export const POLICY_POST_REQUEST = 'POLICY_POST_REQUEST';
export const POLICY_POST_SUCCESS = 'POLICY_POST_SUCCESS';
export const POLICY_POST_FAILURE = 'POLICY_POST_FAILURE';

export const POLICY_REMOVE_REQUEST = 'POLICY_REMOVE_REQUEST';
export const POLICY_REMOVE_SUCCESS = 'POLICY_REMOVE_SUCCESS';
export const POLICY_REMOVE_FAILURE = 'POLICY_REMOVE_FAILURE';

export const POLICY_UPDATE_REQUEST = 'POLICY_UPDATE_REQUEST';
export const POLICY_UPDATE_SUCCESS = 'POLICY_UPDATE_SUCCESS';
export const POLICY_UPDATE_FAILURE = 'POLICY_UPDATE_FAILURE';

export const POLICY_GET_REQUEST = 'POLICY_GET_REQUEST';
export const POLICY_GET_SUCCESS = 'POLICY_GET_SUCCESS';
export const POLICY_GET_FAILURE = 'POLICY_GET_FAILURE';

export const REMOVE_POLICY_FROM_STORE = 'REMOVE_POLICY_FROM_STORE';
