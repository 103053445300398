import { ApiService } from './api.service';
import { UpdateYearPayload, UpdateFrozenYearGoalsPayload } from '../store/years/types';

export default class YearsService extends ApiService {
  public createYear = async () => {
    return await this.post('/years/', {});
  };

  public getYears = async () => {
    return await this.get('/years/');
  };

  public updateYear = async (id: number, payload: UpdateYearPayload | UpdateFrozenYearGoalsPayload) => {
    return await this.put(`/years/${id}`, payload);
  };

  public removeYear = async (id: number) => {
    return await this.remove(`/years/${id}`);
  };
}
