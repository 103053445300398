import React, { Component } from 'react';
import { Modal } from '../../template/modals/modal';
import { connect, ConnectedProps } from 'react-redux';
import { MODAL_PEOPLE } from '../../../shared/constants/modals';
import { modalService } from '../../../services/modals.service';
import PeopleForm from '../../forms/people-form/people-form';
import { closeModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { PeopleModalState } from './people-modal.interface';
import { removePeopleFromStore, getPeople } from '../../../store/people/actions';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_PEOPLE);
  const { isDirty } = state.formIsDirty;
  return { ...modal, isDirty };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      removePeopleFromStore,
      getPeople,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PeopleModal extends Component<PropsFromRedux, PeopleModalState> {
  handleClose = () => {
    this.props.closeModal!(MODAL_PEOPLE);
    this.props.removePeopleFromStore!();
    this.props.getPeople();
  };

  render() {
    const { modal, isDirty } = this.props;
    return (
      modal.isOpen && (
        <Modal formIsDirty={isDirty} close={this.handleClose}>
          <PeopleForm />
        </Modal>
      )
    );
  }
}

export default connector(PeopleModal);
