import React, { FC } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from './confirmation-modal';
import { ModalProps } from './modals.interface';
import { vocabulary } from '../../../vocabulary/german';
import * as ICONS from '../../../assets/icons'

export const Modal: FC<ModalProps> = ({ close, children, forDivision, formIsDirty, canClose = true }: ModalProps) => {
  const modalRef = React.createRef<HTMLDivElement>();
  const onModalClose = (): void => {
    if (!canClose) return;

    const modal = modalRef.current;
    modal!.classList.add('close');
    setTimeout(function () { close(); }, 800);
  }
  const handleClose = (): void => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationModal
            forDivision={forDivision}
            onClose={onClose}
            onOk={() => onModalClose()}
            text={vocabulary.messages.unsavedChanges}
          />
        );
      },
    });
  };


  const onClose = () => formIsDirty ? handleClose() : onModalClose()

  return (
    <div className={'modal'}>
      <div className={'modal-content'} ref={modalRef}>
        <ICONS.CloseSVG className={'form_modal__close'} onClick={onClose} />
        {children}
      </div>
      <div className={'modal-backdrop'} onClick={() => onClose()} />
    </div>
  );
};
