import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageHeader } from "../../template/page-header/page-header";
import { Container } from "../../template/container/container";
import { vocabulary } from "../../../vocabulary/german";
import { ProfilePageState } from './profile-page.interface';
import { AppState } from '../../../store';
import  ProfileForm  from '../../forms/profile-form/profile-form';
import { loadUserProfile, updateProfile } from '../../../store/user/actions';
import * as utils from '../../../shared/utils';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ( {
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUserProfile,
      updateProfile,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class ProfilePage extends Component<PropsFromRedux, ProfilePageState> {

  static contextType = PermissionsContext;

  state = {
    equalPasswordError: false,
    strongPasswordError: false,
    permissions: this.context.permissions
  }

  componentDidMount(): void {
    this.props.loadUserProfile()
  }

  handleSubmit = ({ email, password, repeatPassword }) => {

    const isEmptyPassword = password === '';

    this.setState({strongPasswordError: false, equalPasswordError: false})

    if(!isEmptyPassword && password !== repeatPassword){

      this.setState({equalPasswordError: true})
    } else if(!isEmptyPassword && !utils.validateStrongPassword(password)) {

      this.setState({strongPasswordError: true})
    }else{
      this.setState({equalPasswordError: false, strongPasswordError: false})
      this.props.updateProfile(email, password)
    }
  };

  render() {
    const { authentication } = this.props;
    return (
      <div className={"page-content"}>
        <PageHeader title={vocabulary.labels.profile} btnText="" downloadReport={null}/>
        <Container>
          <ProfileForm
            authentication={authentication}
            equalPasswordError={this.state.equalPasswordError}
            strongPasswordError={this.state.strongPasswordError}
            onSubmit={this.handleSubmit}
          />
        </Container>
      </div>
    )

  }
}

export default connector(ProfilePage);
