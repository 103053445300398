import React, { Component } from 'react';
import { ASSIGN_DIVISION_MODAL } from '../../../shared/constants/modals';
import { Modal } from '../../template/modals/modal';
import AssignDivisionsForm from '../../forms/assign-divisions-form/assign-divisions-form';
import { AppState } from '../../../store';
import { modalService } from '../../../services/modals.service';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../../store/modals/actions';
import { connect, ConnectedProps } from 'react-redux';
import { AssignDivisionsModalState } from './assign-divisions-modal.interface';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, ASSIGN_DIVISION_MODAL);
  return { ...modal };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ closeModal }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignDivisionsModal extends Component<PropsFromRedux, AssignDivisionsModalState> {
  handleClose = () => {
    this.props.closeModal!(ASSIGN_DIVISION_MODAL, null);
  };

  render() {
    const { modal } = this.props;

    return (
      modal.isOpen && (
        <Modal close={this.handleClose}>
          <AssignDivisionsForm />
        </Modal>
      )
    );
  }
}

export default connector(AssignDivisionsModal);
