/* eslint-disable */
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
/* eslint-enable */

export const validateField = (value) => {
  if (!value || value.trim().length === 0) {
    return 'Validierungsfehler (Feld ist erforderlich)';
  }
};


export const validateEmail = (value) => {

  if (!value || value.trim().length === 0) {

    return 'Validierungsfehler (Feld ist erforderlich)';

  } else if (!emailRegExp.test(String(value).trim().toLowerCase())) {

    return 'Please enter valid email';

  }
}


export const validateStrongPassword = (value) => {
  return strongRegex.test(value)
}