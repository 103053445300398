import React, { FC } from 'react';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { FieldArray, FieldArrayRenderProps } from 'formik';

import { ActionsProps } from './division-form-actions.interface';
import { CreateButton } from '../../../buttons/create-button/create-button';
import { TextareaField } from '../../../fields/textarea-field/textarea-field';
import { vocabulary } from '../../../../vocabulary/german';
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmationModal } from '../../../template/modals/confirmation-modal';
import { SelectField } from '../../../fields/select-field/select-field';
import * as utils from '../../../../shared/utils'
import { usePermissions } from '../../../../contexts/permissions.context';

const DragHandle  = SortableHandle(() => <span className="priorities__sort-handle">::</span>);
const SortableWrapper  = SortableContainer(({ children }) => <div>{children}</div>);

const ActionItem = SortableElement(({ action, actionIndex, shortTermGoalIndex, actionHelpers }) => {
  const matrix = usePermissions();


  return (
    <div key={actionIndex} className="division-actions__wrapper">
      <SelectField
        name={`ShortTermGoals.${shortTermGoalIndex}.Actions.${actionIndex}.status`}
        className="division-actions__status customized"
        forDivision
        value={action.status}
      >
        <option value={0} label={vocabulary.statuses.notStarted} />
        <option value={1} label={vocabulary.statuses.inProgress} />
        <option value={2} label={vocabulary.statuses.finished} />
      </SelectField>
      <div className="field-wrapper">
        <TextareaField
          forDivision
          name={`ShortTermGoals.${shortTermGoalIndex}.Actions.${actionIndex}.title`}
          placeholder="Massnahme"
        />
        {
          (matrix.permissions.removeFieldsInside === true) && (
            <i
              className={'division__remove-icon'}
              onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <ConfirmationModal
                        forDivision
                        onClose={onClose}
                        onOk={() => actionHelpers.remove(actionIndex)}
                      />
                    );
                  },
                });
              }}
            />
          )
        }
      </div>
      {(matrix.permissions.dragFields === true) && (<DragHandle />)}
    </div>
  )
})

export const DivisionFormActions: FC<ActionsProps> = ({ shortTermGoal, shortTermGoalIndex, currentYearFrozen = false }: ActionsProps) => {
  const matrix = usePermissions()
  const addAction = (helpers: FieldArrayRenderProps) => () => {
    helpers.push({
      title: '',
      YearId: utils.getCurrentYearIdFromLS(),
      status: 0,
      order: shortTermGoal.Actions.length + 1
    });
  }

  return (
    <FieldArray
      name={`ShortTermGoals.${shortTermGoalIndex}.Actions`}
      render={(actionHelpers) => {
        const hasActions = shortTermGoal.Actions && shortTermGoal.Actions.length > 0;

        const handleSortEnd = ({ oldIndex, newIndex }) => {
          actionHelpers.move(oldIndex, newIndex);

          setTimeout(() => {
            if (hasActions) {
              for (let i = 0; i < shortTermGoal.Actions.length; i++) {
                actionHelpers.form.setFieldValue(`${actionHelpers.name}[${i}].order`, i + 1);
              }
            }
          });
        }

        return (
          <div className={'division-actions'}>
            {hasActions ? (
              <>
                <SortableWrapper
                  onSortEnd={handleSortEnd}
                  lockAxis="y"
                  lockToContainerEdges
                  useDragHandle
                  lockOffset={20}
                >
                  {shortTermGoal.Actions.map((action, actionIndex) => (
                    <ActionItem
                      key={actionIndex}
                      index={actionIndex}
                      action={action}
                      actionIndex={actionIndex}
                      actionHelpers={actionHelpers}
                      shortTermGoalIndex={shortTermGoalIndex}
                    />
                  ))}
                </SortableWrapper>
                {
                  currentYearFrozen ? matrix.permissions.freezeAddFieldsInside === true : matrix.permissions.addFieldsInside === true && (
                      <CreateButton
                        text={vocabulary.buttons.addAction}
                        onClick={addAction(actionHelpers)}
                      />
                    )
                }
              </>
            ) : (
              <>
                {
                  currentYearFrozen ? matrix.permissions.freezeAddFieldsInside === true : matrix.permissions.addFieldsInside === true && (
                      <CreateButton
                        text={vocabulary.buttons.addAction}
                        onClick={addAction(actionHelpers)}
                      />
                    )
                }
              </>
            )}
          </div>
        )
      }}
    />
  );
}
