import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as utils from '../../../shared/utils';

import { PageHeader } from '../../template/page-header/page-header';
import { YearSelectionState } from './year-selection-page.interface';
import { AppState } from '../../../store';
import { Container } from '../../template/container/container';
import { vocabulary } from '../../../vocabulary/german';
import { EntityPanel } from '../../panels/entity-panel/entity-panel';
import { CreateButton } from '../../buttons/create-button/create-button';
import {
  createYear,
  getYears,
  removeYear,
  updateYear,
  setCurrentYear
} from '../../../store/years/actions';
import { confirmAlert } from "react-confirm-alert";
import { ConfirmationModal } from '../../template/modals/confirmation-modal';
import { Spinner } from '../../template/spinner/spinner';
import { NotFoundMessage } from '../../template/not-found-message/not-found-message';
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => ({
  yearsById: state.years.yearsById,
  yearsIds: state.years.yearsIds,
  isGetPending: state.years.isGetYearsPending,
  isCreatePending: state.years.isCreateYearPending
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createYear,
      getYears,
      updateYear,
      removeYear,
      setCurrentYear
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class YearSelectionPage extends Component<PropsFromRedux, YearSelectionState> {
  static contextType = PermissionsContext;
  role = localStorage.getItem('role');

  state = {
    permissions: this.context.permissions,
  }

  componentDidMount(): void {
    this.props.getYears();
  }

  showRemoveConfirmationModal = (id: number, event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }): JSX.Element => {
        return (
          <ConfirmationModal
            onClose={onClose}
            onOk={() => this.props.removeYear({ id })}
          />
        );
      },
    });
  };

  public checkFreezeUnfreeze(id: number, enabled: boolean) {
    this.props.updateYear(id, { enabled: !enabled })
  }

  public checkFrozenYearGoals(id: number, frozenYearGoals: boolean) {
    this.props.updateYear(id, { frozenYearGoals: !frozenYearGoals })
  }

  public createYear = (): void => {
    this.props.createYear();
  };

  public selectYear = (id: number, year: string, isFrozen: boolean, isYearGoalFrozen: boolean) => {
    this.props.setCurrentYear(id, +year, isFrozen, isYearGoalFrozen);
    utils.history.push('/');
  }

  render() {
    const { yearsById, yearsIds, isCreatePending, isGetPending } = this.props;
    const hasYears = this.props.yearsIds.length > 0;
    return (
      <div className={'page-content'}>
        <PageHeader title={vocabulary.labels.yearSelections} btnText="" downloadReport={null} />
        <Container>
          {isGetPending && <Spinner />}
          {!isGetPending && (
            <>
              {hasYears && (
                <div className={'entities-list'}>
                  {yearsIds.map((id) => {
                    const year = yearsById[id];
                    const itemClass = year.enabled ? '' : 'year-disabled'
                    return (
                      <div className='list-item' key={year.id}>
                        <EntityPanel
                          key={year.id}
                          title={year.title}
                          id={year.id}
                          onClick={() => this.selectYear(year.id, year.title, !year.enabled, year.frozenYearGoals)}
                          className={itemClass}
                          isDeletable={this.context.role === 'admin'}
                          onDelete={(event) => this.showRemoveConfirmationModal(year.id, event)}
                        />
                        {
                          (this.state.permissions.freezeYears === true) && (
                            <div
                              className={`year-${year.enabled ? 'freeze' : 'unfreeze'}`}
                              onClick={() => {
                                this.checkFreezeUnfreeze(year.id, year.enabled);
                              }}
                            />
                          )
                        }
                        {
                          (year.enabled && this.state.permissions.freezeYearsGoals) && (
                            <div
                              className={`years-goals-${!year.frozenYearGoals ? 'freeze' : 'unfreeze'}`}
                              onClick={() => this.checkFrozenYearGoals(year.id, year.frozenYearGoals)}
                            />
                          )
                        }
                        {this.context.role !== "viewer" && year.enabled && year.frozenYearGoals
                          && (
                            <div>
                              &nbsp;&nbsp;{vocabulary.labels.freezeGoalYear}
                            </div>
                          )
                        }
                      </div>
                    );
                  })}
                </div>)}
              {!hasYears && <NotFoundMessage message="Jahre nicht gefunden" />}
              {this.context.role === 'admin' && (
                <CreateButton
                  text={vocabulary.buttons.createNewYear}
                  onClick={this.createYear}
                  disabled={isCreatePending}
                />
              )}
            </>
          )}
        </Container>
      </div>
    );
  }
}

export default connector(YearSelectionPage);
