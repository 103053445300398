import React, { Component } from 'react';
import { Modal } from '../../template/modals/modal';
import { connect, ConnectedProps } from 'react-redux';
import { closeModal } from '../../../store/modals/actions';
import { MODAL_POLICY } from '../../../shared/constants/modals';
import { modalService } from '../../../services/modals.service';
import PolicyForm from '../../forms/policy-form/policy-form';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { PolicyModalState } from './policy-modal.interface';
import { removePolicyFromStore } from '../../../store/policies/actions';
import { clearSelectedAgencies } from '../../../store/agencies/actions';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, MODAL_POLICY);
  const { isDirty } = state.formIsDirty;
  const isPending = state.policies.isUpdatePending || state.policies.isGetPending;

  return { ...modal, isDirty, isPending };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeModal,
      removePolicyFromStore,
      clearSelectedAgencies
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class PolicyModal extends Component<PropsFromRedux, PolicyModalState> {

  handleClose = () => {
    if (this.props.isPending) return;

    this.props.closeModal!(MODAL_POLICY);
    this.props.clearSelectedAgencies!();
    this.props.removePolicyFromStore!();
  };

  render() {
    const { modal, isDirty } = this.props;
    if (!modal.isOpen) return null;

    return (
      <Modal formIsDirty={isDirty} close={this.handleClose}>
        <PolicyForm />
      </Modal>
    );
  }
}

export default connector(PolicyModal);
