/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as utils from '../../shared/utils';

export interface Props {
  [key: string]: any;
}

const AuthRoute = ({ component: Component, ...rest }: Props ): ReactElement => (
  <Route {...rest} render={( props: any ): ReactElement => {
    const hasToken = utils.isTokenExist();

    if (hasToken) {
      return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }

    return <Component {...props} />;
  }} />
);

export default AuthRoute;
