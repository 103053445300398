import React, { Component } from 'react';
import MenuIcon from '../../../assets/icons/menu.svg';
import { Link } from 'react-router-dom';
import * as utils from '../../../shared/utils';
import classNames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../store';
import { bindActionCreators } from 'redux';
import { closeMenu, openMenu } from '../../../store/menu/actions';
import { loadUserProfile } from '../../../store/user/actions';
import { vocabulary } from '../../../vocabulary/german';
import BaarLogoTransparentPNG from '../../../assets/images/baar_logo_transparent.png'

const mapStateToProps = (state: AppState) => ({
  menu: state.menu,
  modals: state.modals,
  currentYearName: state.years.currentYearName,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openMenu,
  closeMenu,
  loadUserProfile
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class TopBar extends Component<PropsFromRedux> {

  state = {
    isExpanded: false,
  }

  componentDidMount() {
    this.props.loadUserProfile()
  }

  toggleMenu = () => {
    this.props.menu.isOpen ? this.props.closeMenu!() : this.props.openMenu!();
  };

  renderTab(name: string, key: number): JSX.Element {
    return (
      <div className={'top-bar-tab'} key={key}>
        <p className={'top-bar-tab__text'}>{name}</p>
      </div>
    );
  }

  renderTabForReports(): JSX.Element {
    const pageUrl: any = window.location.pathname.split('/').pop();

    if (pageUrl) {
      switch (pageUrl) {
        case 'short-report': {
          return (
            <div className={'top-bar-tab'}>
              <p className={'top-bar-tab__text'}>Kurz-Berichte</p>
            </div>
          );
        }
        case 'full-report': {
          return (
            <div className={'top-bar-tab'}>
              <p className={'top-bar-tab__text'}>Vollstängiger Report</p>
            </div>
          );
        }
        default: {
          return <></>;
        }
      }
    } else {
      return <></>;
    }
  }


  toggleDropdown() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  render() {
    const { modals, currentYearName, authentication } = this.props;
    const { isExpanded } = this.state
    const role = localStorage.getItem('role');
    const isAdmin = (role === 'admin');
    const hasToken = utils.isTokenExist();

    if (!hasToken) return null;

    return (
      <div className={'top-bar'}>
        {!!currentYearName && (
          <div className={'top-bar-left'}>
            <div className={'top-bar-icon-wrapper'}>
              <img className={'top-bar__menu-icon'} src={MenuIcon} alt="" onClick={this.toggleMenu} />
            </div>
          </div>
        )}
        <div className={'top-bar-mid'}>
          <Link to="/">
            <img className={'top-bar-logo'} src={BaarLogoTransparentPNG} alt="logo" />
          </Link>
          {!!currentYearName && (
            <div className="top-bar-mid__year">
              <span>{vocabulary.labels.yearSelections}: </span>
              <Link to='/years'>{currentYearName}</Link>
            </div>
          )}
        </div>
        <div className="top-bar-right">
          <div className={'top-bar-tabs'}>
            {this.renderTabForReports()}
            {modals!.map((modal, index) => modal.isOpen && this.renderTab(modal.name!, index))}
          </div>
          <div className="profile-dropdown" >
            <div className="profile-dropdown-toggle">
              <p className={'profile-dropdown-toggle__name'}>
                <Link to={'/profile'}>
                  {authentication.user && authentication.user.username}
                </Link>
                <i className={classNames('profile-dropdown-toggle__icon', isExpanded && 'is-active')} onClick={() => this.toggleDropdown()} />
              </p>
            </div>
            <div className={classNames('profile-dropdown-toggle profile-dropdown-modal', isAdmin && isExpanded && 'is-active')}>
              <Link to={'/users'} onClick={() => this.toggleDropdown()}>
                <p className="dropdown-item">{'Users'}</p>
              </Link>
            </div>
            <div className={classNames('profile-dropdown-toggle profile-dropdown-modal', isExpanded && 'is-active')}>
              <Link to={'/logout'}>
                <p className="dropdown-item">{'Logout'}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(TopBar);
