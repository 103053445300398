import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeModal } from '../../../store/modals/actions';
import { bindActionCreators } from 'redux';
import { LogoutPageState } from './logout-page.interface';
import { closeMenu } from '../../../store/menu/actions';
import { AppState } from '../../../store';
import { logout } from '../../../store/user/actions';
import { clearCurrentYear } from '../../../store/years/actions';


const mapStateToProps = (state: AppState) => ({ modals: state.modals });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      closeModal,
      closeMenu,
      clearCurrentYear
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class LogoutPage extends Component<PropsFromRedux, LogoutPageState> {
  componentDidMount(): void {
    const { modals } = this.props;

    modals.forEach((modal) => {
      if (modal.isOpen) {
        this.props.closeModal!(modal.name!);
      }
    });

    this.props.logout!();
    this.props.clearCurrentYear!();
    this.props.closeMenu!();
  }

  render() {
    return null;
  }
}

export default connector(LogoutPage);
