const config = {
  isLocal: /localhost/gi.test(window.location.hostname),
  baseURL: process.env.REACT_APP_API_URL,
  socketURL: process.env.REACT_APP_DEPLOYMENT_BASE_URL,
};

export default config;

/*

  REACT_APP_API_URL
    - DEV:   "http://localhost:8080/api",
    - PROD:  "https://zukunft-konkret.strategiereport.ch/api", right branch!


  REACT_APP_DEPLOYMENT_BASE_URL
    - DEV:  "http://localhost:8080",
    - PROD: "https://zukunft-konkret.strategiereport.ch",
*/

/*
  old settings

    `${process.env.REACT_APP_API_URL}/api` ||  'http://localhost:8080/api'
    `${process.env.REACT_APP_API_URL}` ||
    baseURL: 'https://baar-playground.byerley.ch/api',
    socketURL: 'https://baar-playground.byerley.ch
    'http://localhost:8080',
*/



