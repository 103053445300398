import {
  CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION,
  CLEAR_CURRENT_DIVISION,
  CLEAR_SELECTED_DIVISIONS, DIVISION_CREATE_FAILURE, DIVISION_CREATE_REQUEST, DIVISION_CREATE_SUCCESS,
  DIVISION_GET_FAILURE,
  DIVISION_GET_SUCCESS, DIVISION_UPDATE_FAILURE, DIVISION_UPDATE_REQUEST, DIVISION_UPDATE_SUCCESS,
  DIVISIONS_GETALL_FAILURE,
  DIVISIONS_GETALL_SUCCESS,
  REMOVE_DIVISION_FROM_STORE,
  SELECT_DIVISIONS
} from './types';
import { Division } from '../../shared/interfaces/divisions.interface';

export function divisions(state = { assigned: { selectedDivisions: [] }, current: {}, rows: [], isUpdateOrCreatePending: false }, action) {
  switch (action.type) {
    case DIVISIONS_GETALL_SUCCESS: {
      return {
        ...state,
        ...action.divisions,
      };
    }
    case DIVISIONS_GETALL_FAILURE: {
      return {
        error: action.error,
      };
    }
    case DIVISION_GET_SUCCESS: {
      return {
        ...state,
        current: action.division,
      };
    }
    case DIVISION_GET_FAILURE: {
      return {
        error: action.error,
      };
    }
    case REMOVE_DIVISION_FROM_STORE: {
      return {
        ...state,
        current: {},
      };
    }
    case SELECT_DIVISIONS: {
      return {
        ...state,
        assigned: action.data,
      };
    }
    case CLEAR_SELECTED_DIVISIONS: {
      return {
        ...state,
        assigned: [],
      };
    }
    case DIVISION_CREATE_REQUEST:
    case DIVISION_UPDATE_REQUEST: {
      return {
        ...state,
        isUpdateOrCreatePending: true
      };
    }
    case DIVISION_UPDATE_FAILURE:
    case DIVISION_CREATE_FAILURE:
      return {
        ...state,
        isUpdateOrCreatePending: false
      };

    case DIVISION_UPDATE_SUCCESS:
    case DIVISION_CREATE_SUCCESS:
      return {
        ...state,
        current: action.division,
        isUpdateOrCreatePending: false,
      }
    case CLEAR_CURRENT_DIVISION:
      return {
        ...state,
        current: {}
      }
    case CLEAR_AGENCY_ID_FROM_SELECTED_DIVISION: {
      const { id } = action.payload;

      return {
        ...state,
        rows: state.rows.map((division: Division) => {
          if (id === division.id) {
            return {
              ...division,
              AgencyId: null,
              toUnclaim: true
            }
          }

          return division;
        }),
        assigned: {
          ...state.assigned,
          selectedDivisions: state.assigned.selectedDivisions.filter((assignedId: number) => id !== assignedId)
        }
      }
    }
    default:
      return state;
  }
}
