import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { AppState } from '../index';

import * as at from './constants';
import { UpdateYearPayload, Year, UpdateFrozenYearGoalsPayload } from './types';
import YearsService from '../../services/years.service';

const yearsService = new YearsService();

const createYearSuccess = (payload: { newYear: Year }) => ({ type: at.CREATE_YEAR_SUCCESS, payload });
const createYearFailure = () => ({ type: at.CREATE_YEAR_FAILURE });
const createYearRequest = () => ({ type: at.CREATE_YEAR_REQUEST });

export const createYear = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();

  if (state.years.isCreateYearPending) return;

  dispatch(createYearRequest());

  try {
    const result = await yearsService.createYear();
    dispatch(createYearSuccess(result));
  } catch (error) {
    dispatch(createYearFailure());
  }
};

const getYearsRequest = () => ({ type: at.GET_YEARS_REQUEST });
const getYearsSuccess = (payload: { rows: Year[], count: number }) => ({ type: at.GET_YEARS_SUCCESS, payload });
const getYearsFailure = () => ({ type: at.GET_YEARS_FAILURE });

export const getYears = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch) => {
  dispatch(getYearsRequest());

  try {
    const result = await yearsService.getYears();
    dispatch(getYearsSuccess(result));
  } catch (error) {
    dispatch(getYearsFailure());
  }
};

const updateYearRequest = (payload: UpdateYearPayload | UpdateFrozenYearGoalsPayload) => ({ type: at.UPDATE_YEAR_REQUEST, payload });
const updateYearSuccess = (payload: Year) => ({ type: at.UPDATE_YEAR_SUCCESS, payload });
const updateYearFailure = () => ({ type: at.UPDATE_YEAR_FAILURE });

export const updateYear = (id: number, payload: UpdateYearPayload | UpdateFrozenYearGoalsPayload): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();

  if (state.years.isUpdateYearsPending) return;

  dispatch(updateYearRequest(payload));

  try {
    const result = await yearsService.updateYear(id, payload);
    dispatch(updateYearSuccess(result));
  } catch (e) {
    dispatch(updateYearFailure());
  }
};

const removeYearRequest = (payload: { id: number }) => ({ type: at.REMOVE_YEAR_REQUEST, payload });
const removeYearSuccess = (payload: { id: number }) => ({ type: at.REMOVE_YEAR_SUCCESS, payload });
const removeYearFailure = () => ({ type: at.REMOVE_YEAR_FAILURE });

export const removeYear = (payload: { id: number }): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  const state = getState();

  if (state.years.isRemovePending) return;

  dispatch(removeYearRequest(payload));

  try {
    await yearsService.removeYear(payload.id);
    dispatch(removeYearSuccess(payload));
  } catch (error) {
    dispatch(removeYearFailure());
  }
}

export const setCurrentYear = (id: number, name: number, isFrozen: boolean, isYearGoalFrozen: boolean) => ({ type: at.SET_CURRENT_YEAR_ID, payload: { id, name, isFrozen, isYearGoalFrozen } });
export const clearCurrentYear = () => ({ type: at.CLEAR_CURRENT_YEAR_DATA });
