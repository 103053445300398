import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AgenciesList } from '../../lists/agencies-list/agencies-list';
import { FormHeader } from '../../template/form-header/FormHeader';
import { Button } from '../../buttons/button/button';
import { ASSIGN_AGENCY_MODAL, MODAL_AGENCY } from '../../../shared/constants/modals';
import { GrayPanel } from '../../panels/gray-panel/gray-panel';
import { modalService } from '../../../services/modals.service';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../store/modals/actions';
import { AppState } from '../../../store';
import { AssignAgencyFormProps, AssignAgencyFormState } from './assign-agency-form.interface';
import { getAgencies, setSelectedAgencies, updateAgency } from '../../../store/agencies/actions';
import { FormBody } from '../../template/form-body/form-body';
import { vocabulary } from "../../../vocabulary/german";
import { PermissionsContext } from 'contexts';

const mapStateToProps = (state: AppState) => {
  const modal = modalService.getModalDataFromState(state, ASSIGN_AGENCY_MODAL);
  return {
    currentYearFrozen: state.years.currentYearFrozen,
    agencies: state.agencies,
    ...modal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAgencies,
      setSelectedAgencies,
      closeModal,
      openModal,
      updateAgency
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

class AssignAgencyForm extends Component<PropsFromRedux & AssignAgencyFormProps, AssignAgencyFormState> {

  static contextType = PermissionsContext;
  state: AssignAgencyFormState = { selectedAgencies: [], permissions: this.context.permissions };

  componentDidMount(): void {
    this.props.getAgencies!();
  }

  handleSubmit = () => {
    const { modal, assignAgencies } = this.props;
    const assignedIds = assignAgencies ? [...assignAgencies.map((agency) => agency.id)] : [];
    const selectedAgencies = [...assignedIds, ...this.state.selectedAgencies];
    const selectData = { assignedTo: modal.data, selectedAgencies };

    this.props.setSelectedAgencies!(selectData);
    this.props.closeModal!(ASSIGN_AGENCY_MODAL, {});
  };

  handleCreateClick = () => {
    this.props.openModal!(MODAL_AGENCY);
  };

  selectAgency = (agency) => {
    this.setState((prevState) => {
      if (!prevState.selectedAgencies.includes(agency.id)) {
        return { selectedAgencies: [...prevState.selectedAgencies, agency.id], };
      } else {
        return { ...prevState };
      }
    });
  };

  renderSaveButton = (): JSX.Element | undefined => {
    if (this.state.permissions.edit === true && !this.props.currentYearFrozen) {
      return (
        <Button
          disabled={this.state.selectedAgencies.length === 0}
          onClick={this.handleSubmit}
          text={vocabulary.buttons.save}
        />
      )
    }
  }

  render() {
    const { agencies, assignAgencies } = this.props;

    return (
      <>
        <FormHeader renderSave={this.renderSaveButton()} />
        <FormBody noMargin>
          <GrayPanel text={vocabulary.labels.assignAgencyToMidtermGoal} />
          <div className="container">
            {agencies && (
              <AgenciesList
                selectable
                agencies={agencies}
                assignAgencies={assignAgencies}
                onCreate={this.handleCreateClick}
                onSelect={(agency) => this.selectAgency(agency)}
                updateAgency={updateAgency}
              />
            )}
          </div>
        </FormBody>
      </>
    );
  }
}

export default connector(AssignAgencyForm)

