import config from "../config";
import jwt from "jsonwebtoken";
import { JWT } from "../shared/interfaces/jwt.interface";
import * as utils from "../shared/utils";

export class ApiService {
  protected apiFetch = async (
    url: string,
    options: any = null,
    afterRefresh: boolean = false
  ) => {
    const requestOptions = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getToken()}`,
      },
      cache: "default",
      ...options,
    };


    const response = await fetch(`${config.baseURL}${url}`, requestOptions);
    const contentSizeHeaderValue = response.headers.get("content-length") as string;

    if (response.ok && parseInt(contentSizeHeaderValue) !== 0 ) {
      if (url !== "/auth/logout") {
        return await response
          .json()
          .then((data) => data)
          .catch((error) => !config.isLocal || console.log(error));
      }
    } else {
      if (response.status >= 301 && afterRefresh) {
        this.unsetCredentials();
        utils.history.push("/logout");
        return null;
      }

      if (response.status === 403) {
        this.unsetCredentials();
        utils.history.push("/logout");
        return null;
      }

      if (response.status === 401 && !afterRefresh && url !== "/auth/refresh") {
        const refreshTokenValue = localStorage.getItem("refreshToken");

        if (refreshTokenValue) {
          return this.refreshToken(refreshTokenValue, () => {
            return this.apiFetch(url, options, true).then((res) => {
              return res;
            });
          });
        }
      }

      return this.handleApiResult(await response.json());
    }
  };

  protected refreshToken(refreshToken: string, callback: any) {
    return this.post("/auth/refresh", { refreshToken })
      .then((res) => {
        if (res.accessToken && res.refreshToken) {
          this.setCredentials(res.accessToken, res.refreshToken);
        }
      })
      .then(() => {
        return callback();
      });
  }

  protected getToken = () => {
    return localStorage.getItem("token");
  };

  protected get = async (url: string) => {
    const extendedUrl = utils.appendYearIdParam(url);

    return this.apiFetch(extendedUrl, { method: "GET" });
  };

  protected post = async (url: string, body: any) => {
    const extendedUrl = utils.appendYearIdParam(url);
    const extendedBody = utils.extendFetchBody(body);

    return this.apiFetch(extendedUrl, {
      method: "POST",
      body: JSON.stringify(extendedBody),
    });
  };

  protected put = async (url: string, body: any) => {
    const extendedBody = utils.extendFetchBody(body);
    const extendedUrl = utils.appendYearIdParam(url);

    return this.apiFetch(extendedUrl, {
      method: "PUT",
      body: JSON.stringify(extendedBody),
    });
  };

  protected remove = async (url: string) => {
    const extendedUrl = utils.appendYearIdParam(url);

    return this.apiFetch(extendedUrl, {
      method: "DELETE",
    });
  };

  protected handleApiResult = (result) => {
    return result.errors ? result.errors : result;
  };

  protected setCredentials = (accessToken: string, refreshToken: string) => {
    const decodedToken: JWT = jwt.decode(accessToken);
    const role: string = decodedToken.roles[0];
    const userId: number = decodedToken.id;

    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("role", role);
    localStorage.setItem("userId", userId.toString());
  };

  protected unsetCredentials = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    localStorage.removeItem("currentYearId");
    localStorage.removeItem("currentYear");
    localStorage.removeItem("currentYearFrozen");
  };
}
